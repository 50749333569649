define("soofa-client-shared/components/image-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Q28Pz7ae",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cloudinary-image\",[[23,[\"cloudinaryId\"]]],[[\"height\",\"width\",\"crop\"],[[23,[\"height\"]],[23,[\"width\"]],[23,[\"crop\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"not-eq\",[[23,[\"blockRemove\"]],true],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[3,\"action\",[[22,0,[]],[23,[\"removeAction\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"usedInAdsClient\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"mdi-icon\",[\"close-box\"],[[\"class\"],[\"click-fix mdi-24px close-box-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"i-mdi\",null,[[\"icon\",\"class\"],[\"close-box\",\"click-fix mdi-24px\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/image-preview/template.hbs"
    }
  });
});