define("soofa-client-shared/components/image-preview/component", ["exports", "soofa-client-shared/components/image-preview/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ImagePreviewComponent = Ember.Component.extend({
    // attributes
    layout: _template.default,
    tagName: 'span',
    classNames: ['image-preview'],
    // properties
    crop: 'fit',
    removeAction: null,
    blockRemove: null,
    usedInAdsClient: null
  });
  ImagePreviewComponent.reopenClass({
    positionalParams: ['cloudinaryId']
  });
  var _default = _exports.default = ImagePreviewComponent;
});