define("@soofa/soofa-shared-client-data/models/renderer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    applet: attr('string'),
    contentRenderer: attr('string'),
    contentSubRenderer: attr('string'),
    class: attr('string'),
    dataLength: attr('number'),
    height: attr('number'),
    horizontalPosition: attr('number'),
    mode: attr('string'),
    verticalPosition: attr('number'),
    width: attr('number'),
    visible: attr('boolean'),
    // associations
    location: belongsTo('location')
  });
});