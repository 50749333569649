define("soofa-client-shared/components/upload-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c1MGotYN",
    "block": "{\"symbols\":[\"toast\"],\"statements\":[[4,\"file-upload\",null,[[\"name\",\"accept\",\"noSizeLimit\",\"onStateChange\",\"multiple\",\"onfileadd\"],[[23,[\"name\"]],[23,[\"type\"]],[23,[\"noSizeLimit\"]],[27,\"if\",[[23,[\"onStateChange\"]],[23,[\"onStateChange\"]],[27,\"action\",[[22,0,[]],\"newState\"],null]],null],false,[27,\"action\",[[22,0,[]],\"uploadFile\"],null]]],{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"md-default-theme md-raised button-blue md-button button-small\"],[9],[1,[27,\"get\",[[22,0,[]],\"buttonText\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showAllowedFileTypes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[0,\"Accepts .png or .jpg 4Mb and under.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"fileSizeError\"]]],null,{\"statements\":[[4,\"paper-toast\",null,[[\"duration\",\"position\",\"class\",\"onClose\"],[false,\"bottom left\",\"red\",[27,\"action\",[[22,0,[]],[27,\"toggle\",[\"fileSizeError\",[22,0,[]]],null]],null]]],{\"statements\":[[0,\"    \"],[4,\"component\",[[22,1,[\"text\"]]],null,{\"statements\":[[1,[27,\"paper-icon\",[\"feedback\"],[[\"size\"],[20]]],false],[0,\" File is too big to upload. Max file size: \"],[1,[21,\"maxFileSize\"],false],[0,\" MB.\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/upload-widget/template.hbs"
    }
  });
});