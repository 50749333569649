define("@soofa/soofa-shared-client-data/models/layout", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    active: attr('boolean'),
    backgroundColor: attr('string', {
      defaultValue: 'white'
    }),
    bodyStyle: attr('string'),
    bodyTextStyle: attr('string'),
    brandNameTextStyle: attr('string'),
    callToActionStyle: attr('string'),
    customStyle1: attr('string'),
    customStyle2: attr('string'),
    eventDateTimeTextStyle: attr('string'),
    eventDateTimeMomentFormat: attr('string'),
    eventEndDateTimeTextStyle: attr('string'),
    eventEndDateTimeMomentFormat: attr('string'),
    eventLocationNameTextStyle: attr('string'),
    eventNameTextStyle: attr('string'),
    footerTextStyle: attr('string'),
    hasBody: attr('boolean'),
    hasBusinessLogoOrBrandName: attr('boolean'),
    hasCallToAction: attr('boolean'),
    hasEventDateTime: attr('boolean'),
    hasEventEndDateTime: attr('boolean'),
    hasEventLocationName: attr('boolean'),
    hasEventName: attr('boolean'),
    hasFooter: attr('boolean'),
    hasHeader: attr('boolean'),
    hasImage: attr('boolean'),
    hasImageLogo: attr('boolean'),
    hasSignature: attr('boolean'),
    hasSocialMedia: attr('boolean'),
    hasSubText: attr('boolean'),
    headerStyle: attr('string'),
    headerTextStyle: attr('string'),
    imageStyle: attr('string'),
    imageLogoStyle: attr('string'),
    imageWidth: attr('number'),
    imageLogoWidth: attr('number'),
    imageHeight: attr('number'),
    imageLogoHeight: attr('number'),
    layoutType: attr('string'),
    locked: attr('boolean'),
    previewImage: attr('string'),
    signatureTextStyle: attr('string'),
    socialMediaFacebookStyle: attr('string'),
    socialMediaInstagramStyle: attr('string'),
    socialMediaTwitterStyle: attr('string'),
    subTextStyle: attr('string'),
    textColor: attr('string'),
    // relationships
    format: belongsTo('format')
  });
});