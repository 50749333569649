define("soofa-client-shared/components/md-time/component", ["exports", "soofa-client-shared/components/md-time/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MdDateInput = Ember.Component.extend({
    // attributes
    layout: _template.default,
    // properties
    min: '',
    max: '',
    // events
    didInsertElement() {
      this._super(...arguments);
      this._setupPicker();
    },
    // helpers
    willDestroyElement() {
      this._super(...arguments);
      this._teardownPicker();
    },
    _setupPicker() {
      let timePickerOptions = this.getProperties('min', 'max', 'clear');
      timePickerOptions.onSet = Ember.run.bind(this, this.onTimeSet);
      this.$('.timepicker').pickatime(timePickerOptions);
    },
    _teardownPicker() {
      let $pickatime = this.$('.timepicker').data('pickatime');
      if ($pickatime) {
        $pickatime.stop();
      }
    },
    // override this function to alter date selection behavior
    onTimeSet(evt) {
      if (evt.select || evt.select === 0) {
        let changeset = this.get('changeset');
        changeset.set(this.get('property'), evt.select);
      } else if (evt.clear === null) {
        let changeset = this.get('changeset');
        changeset.set(this.get('property'), null);
      }
    },
    setPickerValues(select, max, min) {
      let $pickatime = this.$('.timepicker').data('pickatime');
      if ($pickatime) {
        $pickatime.set('max', max);
        $pickatime.set('select', select);
        $pickatime.set('min', min);
      }
    }
  });
  MdDateInput.reopenClass({
    positionalParams: ['changeset', 'property']
  });
  var _default = _exports.default = MdDateInput;
});