define("@soofa/soofa-shared-client-data/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    addressString: attr('string'),
    custom: attr('string'),
    eventId: attr('string'),
    flag: attr('boolean', {
      defaultValue: false
    }),
    geohash: attr('string'),
    image: attr('string'),
    // cloudinary image
    lat: attr('number'),
    lng: attr('number'),
    locationName: attr('string'),
    // do not use anymore, delete next time this file is edited, leaving for the moment to avoid a breaking change while switching over to 'location'
    location: attr('string'),
    name: attr('string'),
    // new event attributes
    date: attr('string'),
    description: attr('string'),
    timeEnd: attr('number'),
    timeStart: attr('number'),
    // legacy event attributes
    dateTime: attr('number'),
    endDateTime: attr('number'),
    // associations
    contents: hasMany('content'),
    customer: belongsTo('customer'),
    user: belongsTo('user')
  });
});