define("soofa-client-shared/components/campaign-featured/component", ["exports", "soofa-client-shared/components/campaign-featured/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Displays campaign featured check
    Consumed by:
      soofa-client-shared/addon/components/campaign-card/template.hbs
      admin-client/app/components/ad-review-card/template.hbs
  */

  const CampaignFeaturedComponent = Ember.Component.extend({
    // attributes
    layout: _template.default,
    classNames: ['campaign-featured'],
    // actions
    actions: {
      updateFeatured(model) {
        let featured = model.get('featured') ? false : true;
        model.set('featured', featured);
        model.save();
      }
    }
  });
  CampaignFeaturedComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = _exports.default = CampaignFeaturedComponent;
});