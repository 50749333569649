define("ember-paper/templates/components/paper-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9rhz4oLW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[23,[\"destinationEl\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[4,\"paper-toast-inner\",null,[[\"swipe\",\"swipeToClose\",\"onClose\",\"top\",\"left\",\"capsule\",\"class\"],[[23,[\"swipeAction\"]],[23,[\"swipeToClose\"]],[23,[\"onClose\"]],[23,[\"top\"]],[23,[\"left\"]],[23,[\"capsule\"]],[23,[\"class\"]]]],{\"statements\":[[0,\"    \"],[14,1,[[27,\"hash\",null,[[\"text\"],[[27,\"component\",[\"paper-toast-text\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-toast.hbs"
    }
  });
});