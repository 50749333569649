define("@soofa/soofa-shared-client-data/models/slot", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    changeHour: attr('number'),
    lastInviteDate: attr('number'),
    name: attr('string'),
    scheduled: attr('boolean'),
    textContent: attr('string'),
    // belongsTo
    instance: belongsTo('instance'),
    // hasMany
    displayBusinesses: hasMany('business'),
    activeBusinesses: hasMany('business')
  });
});