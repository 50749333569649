define("soofa-client-shared/components/i-mdi/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IMdiComponent = Ember.Component.extend({
    // attributes
    tagName: 'i',
    classNames: ['mdi'],
    classNameBindings: ['computedIcon'],
    // properties
    icon: null,
    // computed
    computedIcon: Ember.computed('icon', function () {
      return `mdi-${this.get('icon')}`;
    })
  });
  IMdiComponent.reopenClass({
    positionalParams: ['icon']
  });
  var _default = _exports.default = IMdiComponent;
});