define("@soofa/soofa-shared-client-data/models/battery", ["exports", "ember-data", "@soofa/soofa-shared-client-data/helpers/date"], function (_exports, _emberData, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    apId: attr('string'),
    brand: attr('string'),
    chargedDate: attr('number'),
    purchasedDate: attr('number'),
    searchIndex: attr('string'),
    status: attr('string'),
    // associations
    product: belongsTo('product'),
    // computed
    age: Ember.computed('purchasedDate', function () {
      return (0, _date.ageInYearsDecimal)(this.get('purchasedDate'));
    })
  });
});