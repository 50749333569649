define("soofa-client-shared/components/creative-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0mmWXoqE",
    "block": "{\"symbols\":[\"queue\",\"dropzone\",\"queue\"],\"statements\":[[4,\"let\",[[27,\"file-queue\",null,[[\"name\",\"onFileAdded\"],[\"creatives\",[23,[\"uploadImage\"]]]]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex \"],[9],[0,\"\\n\"],[4,\"file-dropzone\",null,[[\"name\"],[\"images\"]],{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[28,[\"file-dropzone \",[27,\"if\",[[22,2,[\"active\"]],\"active\"],null]]]],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"if\",[[22,2,[\"supported\"]]],null,{\"statements\":[[0,\"            \"],[7,\"small\"],[9],[0,\"Drag and drop images or\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"file-upload\",null,[[\"name\",\"accept\",\"multiple\",\"onfileadd\"],[\"images\",\"image/*\",true,[27,\"action\",[[22,0,[]],\"uploadImage\"],null]]],{\"statements\":[[0,\"            \"],[7,\"a\"],[11,\"id\",\"upload-image\"],[11,\"class\",\"md-button md-raised\"],[11,\"tabindex\",\"0\"],[9],[0,\"Upload\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/creative-uploader/template.hbs"
    }
  });
});