define("soofa-client-shared/components/keen-query-yield/component", ["exports", "soofa-client-shared/mixins/query-options", "soofa-client-shared/mixins/handles-query", "soofa-client-shared/components/keen-query-yield/template"], function (_exports, _queryOptions, _handlesQuery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_queryOptions.default, _handlesQuery.default, {
    result: {
      loading: true
    },
    // properties
    layout: _template.default,
    // events
    async didReceiveAttrs() {
      this._super(...arguments);
      this._prepareChart();
    },
    async _prepareChart(queryParams) {
      if (queryParams) {
        this.setProperties(queryParams);
      }
      let queries = [this.generateQuery()];
      await this._getResult(queries);
      await this._transformResult();
    },
    _renderChart() {}
  });
});