define("soofa-client-shared/services/log-reloader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    // attributes
    reloadGlobalNewsfeed: false,
    reloadLogList: false,
    components: [],
    updateComponents(keenEvent) {
      this.trigger('reloadData', keenEvent);
    }
  });
});