define("@soofa/soofa-shared-client-data/models/neighborhood", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    active: attr("boolean", {
      defaultValue: true
    }),
    cloudinaryId: attr("string"),
    comingSoon: attr("boolean"),
    customHeader: attr("string"),
    description: attr("string"),
    defaultMode: attr("string"),
    defaultZoom: attr("number"),
    dspVoice: attr("number"),
    facts: attr("string"),
    featured1: attr("string"),
    featured2: attr("string"),
    featured3: attr("string"),
    hasCustomerPoll: attr("boolean"),
    headerImage: attr("string"),
    hideNNAndGradient: attr("boolean"),
    image1: attr("string"),
    image2: attr("string"),
    image3: attr("string"),
    image4: attr("string"),
    inactive: attr("boolean", {
      defaultValue: false
    }),
    keywords: attr("string"),
    lat: attr("string"),
    lng: attr("string"),
    geohash: attr("string"),
    geohashAccuracy: attr("number"),
    high: attr("number"),
    low: attr("number"),
    condition: attr("string"),
    mapFilter: attr("boolean"),
    name: attr("string"),
    newsFeed: attr("string"),
    newsKeywords: attr("string"),
    roundSunriseFairly: attr("boolean", {
      defaultValue: false
    }),
    splashImage: attr("string"),
    // image
    style: attr("string"),
    vinylPhoto: attr("string"),
    // image
    rotatingImage: attr("string"),
    // image
    searchIndex: attr("string"),
    snowMode: attr("boolean", {
      defaultValue: false
    }),
    // sign template attribute settings
    theme: attr("string"),
    // default, dark
    wayfinderPayload: attr("string"),
    // associations
    appletSchedules: hasMany("appletSchedule"),
    priorityLists: hasMany("priorityList"),
    businessInstance: belongsTo("instance"),
    campaigns: hasMany("campaign", {
      inverse: null
    }),
    city: belongsTo("city"),
    communityCampaigns: hasMany("campaign", {
      inverse: null
    }),
    communityInstance: belongsTo("instance"),
    locations: hasMany("location"),
    landowner: belongsTo("customer", {
      inverse: null
    }),
    newsInstance: belongsTo("instance"),
    newsfeedInstance: belongsTo("instance"),
    poll: belongsTo("poll"),
    rotatingImagesInstance: belongsTo("instance"),
    signs: hasMany("sign"),
    tags: hasMany("tag"),
    tideInstance: belongsTo("instance"),
    geometry: belongsTo("geometry"),
    waterInstance: belongsTo("instance"),
    users: hasMany("user"),
    voices: hasMany("voice"),
    weatherInstance: belongsTo("instance"),
    widgetPriorities: attr("string"),
    wayfinderPayload: attr("string"),
    // computed
    displayName: Ember.computed("city.name", function () {
      // get neighborhood name
      let name = this.get("name");

      // return a promise with neighborhood name and city if exists
      return this.get("city").then(function (city) {
        return city ? `(${city.get("name")}) ${name}` : name;
      });
    }),
    instances: Ember.computed("", function () {
      return this.get("locations").then(function (locations) {
        let instances = Ember.RSVP.all(locations.filterBy("hasAdsInstance").getEach("adsInstance").compact().toArray());
        return instances;
      });
    })
  });
});