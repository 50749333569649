define("@soofa/soofa-shared-client-data/helpers/date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function date(date /*, hash*/) {
    let now = new _moment.default();
    let momentDate = new _moment.default(date);
    return now.diff(momentDate, 'years', true).toFixed(1);
  });
});