define("soofa-client-shared/components/image-editor/component", ["exports", "soofa-client-shared/components/image-editor/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ImageEditorComponent = Ember.Component.extend({
    // properties
    crop: null,
    eager: null,
    buttonText: 'Add Image',
    model: null,
    imageAttrName: 'cloudinaryId',
    uploadPreset: null,
    width: 100,
    save: false,
    showAllowedFileTypes: true,
    // services
    scriptr: Ember.inject.service(),
    // attributes
    layout: _template.default,
    classNames: ['image-editor'],
    // actions
    actions: {
      // Updates uploading state
      newState(state) {
        this.set('loadingState', state);
      },
      // Sets the newly created ID in the model and updates loading state
      async newImage(staticMode, user, location, cloudinaryId) {
        if (staticMode) {
          let scriptr = this.get('scriptr');
          let scriptrStr = `staticImageChange?&userId=${user.id}&locationId=${location.id}&upload=true`;
          await scriptr.request(scriptrStr);
        }
        this.send('newState', false);
        let model = this.get('model');
        let imageAttrName = this.get('imageAttrName');
        model.set(imageAttrName, cloudinaryId);
        let parent = this.get('parent');
        if (parent) {
          parent.set('cloudinaryId', cloudinaryId);
        }
        if (this.get('save')) {
          model.save();
        }
      },
      // Removes image id from model
      async removeImage(staticMode, user, location) {
        if (staticMode) {
          let scriptr = this.get('scriptr');
          let scriptrStr = `staticImageChange?&userId=${user.id}&locationId=${location.id}&upload=false`;
          await scriptr.request(scriptrStr);
        }
        let model = this.get('model');
        let imageAttrName = this.get('imageAttrName');
        model.set(imageAttrName, null);
        if (this.get('save')) {
          model.save();
        }
      }
    }
  });
  ImageEditorComponent.reopenClass({
    positionalParams: ['model', 'imageAttrName']
  });
  var _default = _exports.default = ImageEditorComponent;
});