define("soofa-client-shared/services/log-creator", ["exports", "soofa-client-shared/mixins/has-me"], function (_exports, _hasMe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(_hasMe.default, {
    // services
    store: Ember.inject.service(),
    keen: Ember.inject.service(),
    logReloader: Ember.inject.service(),
    createLog(message, models) {
      let me = this.get('me');
      // prepare message
      message = `${me.get('displayName')}: ${message}`;
      models.forEach(model => {
        let modelId = model.get('id');
        let {
          modelName
        } = model.constructor;
        this.recordKeenEvent(modelName, modelId, message);
      });
    },
    recordKeenEvent(modelName, modelId, message) {
      let keenEvent = {
        modelName,
        modelId,
        message
      };

      // record keen event
      this.get('keen').recordEvent('internalLogs', keenEvent, true, false);
      // reload log
      this.get('logReloader').updateComponents(keenEvent);
    }
  });
});