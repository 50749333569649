define("ember-paper/templates/components/paper-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xTYe3Xbe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"renderTooltip\"]]],null,{\"statements\":[[4,\"in-element\",[[23,[\"destinationEl\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"md-panel-outer-wrapper md-panel-is-showing\"],[12,\"style\",[21,\"containerStyle\"]],[9],[0,\"\\n\"],[4,\"paper-tooltip-inner\",null,[[\"class\",\"position\",\"anchorElement\",\"hide\"],[[23,[\"class\"]],[23,[\"position\"]],[23,[\"anchorElement\"]],[23,[\"hideTooltip\"]]]],{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-tooltip.hbs"
    }
  });
});