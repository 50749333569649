define("@soofa/soofa-shared-client-data/models/city", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    active: attr('boolean', {
      defaultValue: false
    }),
    billable: attr('boolean', {
      defaultValue: false
    }),
    calendars: attr('string'),
    description: attr('string'),
    defaultAreaCode: attr('string'),
    defaultZoom: attr('number'),
    hubspotNeighborhoodsUniqueIdentifier: attr('string'),
    inactive: attr('boolean', {
      defaultValue: true
    }),
    isPrivate: attr('boolean', {
      defaultValue: false
    }),
    isPublic: attr('boolean', {
      defaultValue: true
    }),
    name: attr('string', {
      defaultValue: 'New City'
    }),
    newsKeywords: attr('string'),
    lat: attr('number'),
    lng: attr('number'),
    maintenance: attr('boolean', {
      defaultValue: true
    }),
    geohash: attr('string'),
    geohashAccuracy: attr('number'),
    high: attr('number'),
    low: attr('number'),
    condition: attr('string'),
    sunrise: attr('number'),
    sunset: attr('number'),
    twitterHandle: attr('string'),
    plans: attr('json'),
    slug: attr('string'),
    splashImage: attr('string'),
    // cloudinary / image
    timeZone: attr('string', {
      defaultValue: 'America/New_York'
    }),
    // associations
    customer: belongsTo('customer'),
    eventInstance: belongsTo('instance'),
    landownerInstance: belongsTo('instance'),
    neighborhoods: hasMany('neighborhood'),
    newsInstance: belongsTo('instance'),
    twitterInstance: belongsTo('instance'),
    weatherInstance: belongsTo('instance')
  });
});