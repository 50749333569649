define("@soofa/soofa-shared-client-data/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    apId: attr('string'),
    chassisId: attr('string'),
    chassisVersion: attr('string'),
    dataPlanExpiration: attr('number'),
    extraInfo: attr('string'),
    hasCustomer: attr('boolean'),
    hasLocation: attr('boolean'),
    heading: attr('number'),
    installDate: attr('number'),
    installationStatus: attr('string'),
    lockUi: attr('boolean', {
      defaultValue: false
    }),
    modelNumber: attr('string'),
    productType: attr('string', {
      defaultValue: 'Sign'
    }),
    searchIndex: attr('string'),
    shipDate: attr('number'),
    textCode: attr('string'),
    // sign attrs
    battery: belongsTo('battery'),
    driver: belongsTo('device', {
      inverse: null
    }),
    enclosure: belongsTo('enclosure'),
    lightOperatedLastCycle: attr('boolean'),
    minimumVoltage: attr('number'),
    minimumRSSI: attr('number'),
    sensor: belongsTo('device', {
      inverse: null
    }),
    sumSolarEnergy: attr('number'),
    totalImageUpdates: attr('number'),
    lightbarVersion: attr('string'),
    solarPanelVersion: attr('string'),
    screenEnclosureVersion: attr('string'),
    antennaVersion: attr('string'),
    frontPanelVersion: attr('string'),
    // relationships
    location: belongsTo('location'),
    // computed
    name: Ember.computed('location', function () {
      return this.get('chassisId') ? this.get('chassisId') : this.get('id');
    }),
    displayName: Ember.computed('location', function () {
      return this.get('location').then(location => {
        return location ? location.get('locationName') : this.get('chassisId') ? this.get('chassisId') : this.get('id');
      });
    })
  });
});