define("@soofa/soofa-location/components/location-permission/component", ["exports", "soofa-client-shared/mixins/has-me", "@soofa/soofa-location/components/location-permission/template"], function (_exports, _hasMe, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // properties
    permissionQuestion: 'Soofa needs to have access to your location. <strong>Agree?</strong>',
    permissionShareLocationYes: 'Yes',
    permissionShareLocationNo: 'No',
    permissionShareLocationDeny: 'Soofa needs your location to continue.',
    // attributes
    classNames: ['location-messages'],
    layout: _template.default,
    // actions
    actions: {
      shareAccept() {
        this.choiceShareAccept();
      },
      shareDeny() {
        this.choiceShareDeny();
      }
    }
  });
});