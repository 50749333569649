define("@soofa/soofa-shared-client-data/models/line-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    active: attr('boolean'),
    activeGoal: attr('boolean'),
    coinvested: attr('boolean'),
    countUnspecified: attr('number'),
    currentHourGoal: attr('number'),
    currentImpressionGoal: attr('number'),
    currentMonthlyValue: attr('number'),
    currentVoiceGoal: attr('number'),
    displayRate: attr('number'),
    displayRateYesterday: attr('number'),
    displayRateLastWeek: attr('number'),
    endsAt: attr('number'),
    flag: attr('string'),
    firstRunDate: attr('number'),
    hoursPerDay: attr('number'),
    hoursAchievedYesterday: attr('number'),
    lastSevenDaysHoursAchieved: attr('number'),
    lastThirtyDaysHoursAchieved: attr('number'),
    lastRunDate: attr('number'),
    locationSpecific: attr('boolean'),
    mode: attr('string'),
    monthExpectedTargetHours: attr('number'),
    notes: attr('string'),
    slots: attr('number'),
    spreadsheetRowIndex: attr('number'),
    startsAt: attr('number'),
    impressionsThisMonth: attr('number'),
    hoursThisMonth: attr('number'),
    runningSigns: attr('number'),
    score: attr('number'),
    status: attr('string'),
    timeLimit: attr('boolean'),
    totalImpressionGoal: attr('number'),
    totalHours: attr('number'),
    totalHoursAchieved: attr('number'),
    totalHoursAssigned: attr('number'),
    totalHoursExpected: attr('number'),
    totalHoursUnachieved: attr('number'),
    totalValue: attr('number'),
    lineItemType: attr('string'),
    lowestDisplayRate: attr('string'),
    // relationships
    campaigns: hasMany('campaign'),
    city: belongsTo('city'),
    contract: belongsTo('contract'),
    months: hasMany('month'),
    neighborhoods: hasMany('neighborhood'),
    locations: hasMany('location'),
    poll: belongsTo('poll'),
    polls: hasMany('poll'),
    signTemplateOverride: belongsTo('sign'),
    voices: hasMany('voice')
  });
});