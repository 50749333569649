define("soofa-client-shared/components/child-date/component", ["exports", "soofa-client-shared/components/md-date/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    ?
    Consumed by:
      soofa-client-shared/addon/components/campaign-dates/template.hbs
  */
  var _default = _exports.default = _component.default.extend({
    // properties
    parent: null,
    didInsertElement() {
      this._super();
      this.get('parent').set(this.get('childName'), this);
    }
  });
});