define("soofa-client-shared/components/sign-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "79rMc6Km",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card-panel\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col s12\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"product\",\"isFulfilled\"]]],null,{\"statements\":[[4,\"with\",[[23,[\"model\",\"product\"]]],null,{\"statements\":[[4,\"link-to\",[\"products.product.sign\",[22,1,[]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"locationName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"div\"],[11,\"class\",\"corner\"],[9],[0,\"        \\n\"],[4,\"if\",[[23,[\"model\",\"isSleeping\"]]],null,{\"statements\":[[0,\"              \"],[1,[27,\"i-mdi\",[\"sleep\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"link-to\",[\"signs.sign\",[23,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/sign-card/template.hbs"
    }
  });
});