define("ember-mapbox-gl/templates/components/mapbox-gl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r+RWCgq2",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"_loader\",\"isLoaded\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"_glSupported\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"_map\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"_isMapLoaded\"]]],null,{\"statements\":[[0,\"        \"],[14,2,[[27,\"hash\",null,[[\"call\",\"control\",\"image\",\"layer\",\"marker\",\"on\",\"popup\",\"source\",\"instance\"],[[27,\"component\",[\"mapbox-gl-call\"],[[\"obj\"],[[23,[\"_map\"]]]]],[27,\"component\",[\"mapbox-gl-control\"],[[\"map\"],[[23,[\"_map\"]]]]],[27,\"component\",[\"mapbox-gl-image\"],[[\"map\"],[[23,[\"_map\"]]]]],[27,\"component\",[\"mapbox-gl-layer\"],[[\"map\"],[[23,[\"_map\"]]]]],[27,\"component\",[\"mapbox-gl-marker\"],[[\"map\"],[[23,[\"_map\"]]]]],[27,\"component\",[\"mapbox-gl-on\"],[[\"eventSource\"],[[23,[\"_map\"]]]]],[27,\"component\",[\"mapbox-gl-popup\"],[[\"map\"],[[23,[\"_map\"]]]]],[27,\"component\",[\"mapbox-gl-source\"],[[\"map\"],[[23,[\"_map\"]]]]],[23,[\"_map\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl.hbs"
    }
  });
});