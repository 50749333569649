define("soofa-client-shared/services/soofa", ["exports", "soofa-client-shared/mixins/has-me"], function (_exports, _hasMe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise
  } = Ember.RSVP;
  var _default = _exports.default = Ember.Service.extend(_hasMe.default, {
    apiKey: Ember.computed.alias('meCustomer.apiKey'),
    client: Ember.computed('', function () {
      let client = new Keen({
        host: 'api.soofa.io/data/v1/',
        protocol: 'https'
      });
      client.config.resources = {
        version: '{protocol}://{host}',
        events: '{protocol}://{host}',
        projectId: '{protocol}://{host}',
        projects: '{protocol}://{host}',
        queries: '{protocol}://{host}'
      };
      client.run = Ember.run.bind(this, this.run);
      return client;
    }),
    run(queries) {
      let client = this.get('client');
      return new Promise((resolve, error) => {
        let queryPromises = [];
        queries.forEach(query => {
          query.params.api_key = this.get('apiKey'); // eslint-disable-line camelcase
          query.params.atlas = true;
          let filteredParams = query.params;
          for (let propName in filteredParams) {
            if (filteredParams[propName] === null || filteredParams[propName] === undefined) {
              delete filteredParams[propName];
            }
          }
          query.params = filteredParams;
          let req = client.get(client.url(query.analysis));
          queryPromises.pushObject(req.send(query.params));
        });
        Promise.all(queryPromises).then(function (res) {
          let newRes = {
            result: res[0],
            query: queries[0]
          };
          resolve(newRes);
        }).catch(function (er) {
          error(er);
        });
      });
    }
  });
});