define("soofa-client-shared/components/password-change/component", ["exports", "soofa-client-shared/validations/change", "ember-changeset", "ember-changeset-validations", "soofa-client-shared/components/password-change/template"], function (_exports, _change, _emberChangeset, _emberChangesetValidations, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // services
    toaster: Ember.inject.service(),
    // attributes
    layout: _template.default,
    changeset: null,
    step: 1,
    classNames: ['password-change'],
    // events
    didReceiveAttrs() {
      // validate form
      let model = new Ember.Object({
        password: null
      });
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_change.default), _change.default);
      changeset.validate();
      this.set('changeset', changeset);
    },
    // services
    firebaseApp: Ember.inject.service(),
    actions: {
      // a) validating current password
      async validateCurrentPassword() {
        // Check if changeset is valid
        let changeset = this.get('changeset');
        if (changeset.get('isValid')) {
          let auth = this.get('firebaseApp').auth();

          // get current logged user
          let user = auth.currentUser;
          if (user) {
            // user is signed in.
            let {
              email
            } = user;
            let password = changeset.get('password');
            let passwordValid = true;
            // sign in user with given password
            await auth.signInWithEmailAndPassword(email, password).catch(error => {
              // error in login
              passwordValid = false;
              if (error.code === 'auth/wrong-password') {
                let message = 'You did not verify your password correctly. Please try again.';
                this.get('toaster').launchToaster('error', message, 'red', 'down left', 5000);
              }
            });

            // if password is valid, go to step b)
            if (passwordValid) {
              let changeset = this.get('changeset');
              changeset.set('password', ''); // reset field
              this.set('step', 2);
            }
          } else {
            // No user is signed in.
          }
        }
      },
      async changeCurrentPassword() {
        // Check if changeset is valid
        let changeset = this.get('changeset');
        if (changeset.get('isValid')) {
          let auth = this.get('firebaseApp').auth();

          // get current logged user
          let user = auth.currentUser;
          if (user) {
            // user is signed in.
            let password = changeset.get('password');

            // update user password
            await user.updatePassword(password).then(() => {
              // Update successful.
              this.set('step', 3);
            });
          }
        }
      }
    }
  });
});