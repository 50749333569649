define("soofa-client-shared/components/campaign-reveal/component", ["exports", "soofa-client-shared/components/campaign-reveal/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Handles the reveal content of a campaign-card
    Showing it on modal when mobile and wrapped in a div when desktop
  */
  var _default = _exports.default = Ember.Component.extend({
    // services
    screen: Ember.inject.service(),
    // attributes
    layout: _template.default,
    show: false,
    // actions
    actions: {}
  });
});