define("@sentry/ember/index", ["exports", "@sentry/browser", "@sentry/core", "@sentry/utils"], function (_exports, _browser, _core, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    init: true,
    getActiveTransaction: true,
    instrumentRoutePerformance: true,
    InitSentryForEmber: true
  };
  _exports.getActiveTransaction = _exports.InitSentryForEmber = void 0;
  _exports.init = init;
  _exports.instrumentRoutePerformance = void 0;
  var Sentry = _browser;
  Object.keys(_browser).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _browser[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _browser[key];
      }
    });
  });
  function _getSentryInitConfig() {
    const _global = _utils.GLOBAL_OBJ;
    _global.__sentryEmberConfig = _global.__sentryEmberConfig ?? {};
    return _global.__sentryEmberConfig;
  }

  /**
   * Initialize the Sentry SDK for Ember.
   */
  function init(_runtimeConfig) {
    const environmentConfig = {
      "sentry": {
        "dsn": "https://e73c7a3f8ca885358fa3c742511555b2@o83205.ingest.us.sentry.io/4507022939258880",
        "tracesSampleRate": 0.2
      }
    };
    (false && !(environmentConfig) && Ember.assert('Missing configuration.', environmentConfig));
    (false && !(environmentConfig.sentry || _runtimeConfig) && Ember.assert('Missing configuration for Sentry.', environmentConfig.sentry || _runtimeConfig));
    if (!environmentConfig.sentry) {
      // If environment config is not specified but the above assertion passes, use runtime config.
      environmentConfig.sentry = {
        ..._runtimeConfig
      };
    }

    // Merge runtime config into environment config, preferring runtime.
    Object.assign(environmentConfig.sentry, _runtimeConfig || {});
    const initConfig = Object.assign({}, environmentConfig.sentry);
    (0, _core.applySdkMetadata)(initConfig, 'ember');

    // Persist Sentry init options so they are identical when performance initializers call init again.
    const sentryInitConfig = _getSentryInitConfig();
    Object.assign(sentryInitConfig, initConfig);
    Sentry.init(initConfig);
  }

  /**
   * Grabs active transaction off scope.
   *
   * @deprecated You should not rely on the transaction, but just use `startSpan()` APIs instead.
   */
  const getActiveTransaction = () => {
    // eslint-disable-next-line deprecation/deprecation
    return Sentry.getCurrentScope().getTransaction();
  };
  _exports.getActiveTransaction = getActiveTransaction;
  const instrumentRoutePerformance = BaseRoute => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const instrumentFunction = async (op, description, fn, args) => {
      return (0, _browser.startSpan)({
        attributes: {
          [_core.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'ember'
        },
        op,
        name: description
      }, () => {
        return fn(...args);
      });
    };
    const routeName = BaseRoute.name;
    return {
      // @ts-expect-error TS2545 We do not need to redefine a constructor here
      [routeName]: class extends BaseRoute {
        beforeModel(...args) {
          return instrumentFunction('ui.ember.route.before_model', this.fullRouteName, super.beforeModel.bind(this), args);
        }
        async model(...args) {
          return instrumentFunction('ui.ember.route.model', this.fullRouteName, super.model.bind(this), args);
        }
        afterModel(...args) {
          return instrumentFunction('ui.ember.route.after_model', this.fullRouteName, super.afterModel.bind(this), args);
        }
        setupController(...args) {
          return instrumentFunction('ui.ember.route.setup_controller', this.fullRouteName, super.setupController.bind(this), args);
        }
      }
    }[routeName];
  };
  _exports.instrumentRoutePerformance = instrumentRoutePerformance;
  /**
   * @deprecated Use `Sentry.init()` instead.
   */
  const InitSentryForEmber = _exports.InitSentryForEmber = init;
});