define("@soofa/soofa-location/components/location-permission/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gt+7L04V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-center box-white\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-100\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[0,\"\\n      \"],[1,[27,\"html-safe\",[[23,[\"permissionQuestion\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-100\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"raised\",\"onClick\"],[\"button-blue\",true,[27,\"action\",[[22,0,[]],\"shareAccept\"],null]]],{\"statements\":[[0,\"        \"],[1,[21,\"permissionShareLocationYes\"],false],[0,\"\\n      \"]],\"parameters\":[]},null],[0,\" \\n\"],[4,\"paper-button\",null,[[\"raised\",\"onClick\"],[true,[27,\"action\",[[22,0,[]],\"shareDeny\"],null]]],{\"statements\":[[0,\"        \"],[1,[21,\"permissionShareLocationNo\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@soofa/soofa-location/components/location-permission/template.hbs"
    }
  });
});