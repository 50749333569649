define("ember-paper/templates/components/paper-card-header-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ws+Y/s8+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"title\",\"subhead\"],[[27,\"component\",[\"paper-card-header-title\"],null],[27,\"component\",[\"paper-card-header-subhead\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card-header-text.hbs"
    }
  });
});