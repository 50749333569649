define("soofa-client-shared/components/soofa-map/component", ["exports", "mapbox-gl", "soofa-client-shared/components/soofa-map/template"], function (_exports, _mapboxGl, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SoofaMapComponent = Ember.Component.extend({
    // properties
    attributionControl: false,
    classNames: ['soofa-map', 'flex-100', 'mapboxmap'],
    defaultInitialZoom: 12,
    defaultMaxZoom: 18,
    defaultMinZoom: 10,
    defaultInteractive: true,
    defaultStyle: 'mapbox://styles/soofa/cjxc1t63315jo1clalg4nheh1',
    // Buzz map style default
    dragRotate: false,
    imageName: 'heart',
    image: '/assets/images/map-icon-inverted-location.svg',
    mapLoaded: null,
    navigationControl: new _mapboxGl.default.NavigationControl(),
    navigationPosition: 'top-right',
    pitchWithRotate: false,
    showNavigationControl: true,
    // attributes
    layout: _template.default,
    // actions
    actions: {
      emptyMapLoaded() {}
    }
  });
  var _default = _exports.default = SoofaMapComponent;
});