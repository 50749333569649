define("soofa-client-shared/components/soofa-md-input/component", ["exports", "soofa-client-shared/components/soofa-md-input/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Wrapper for Paper Input
    This component's job is to wrap the boilerplate for invoking inputs of any type
    that edit a changeset's properties (it takes a changeset and a property name
    as params). It is designed to also handle displaying any validation errors after
    the input is touched the first time if the changeset was invoked upstream with
    validations.
  
    Consumed by:
      admin-client/app/components/ad-review-card/template.hbs
      admin-client/app/components/block-editor/template.hbs
      admin-client/app/components/list-filter/template.hbs
      admin-client/app/components/relation-adder/template.hbs
      admin-client/app/customers/customer/users/template.hbs
      admin-client/app/templates/customer-editor.hbs
      admin-client/app/templates/device-editor.hbs
      admin-client/app/templates/format-editor.hbs
      admin-client/app/templates/layout-editor.hbs
      admin-client/app/templates/product-editor.hbs
  */

  const MdInputComponent = Ember.Component.extend({
    // attributes
    layout: _template.default,
    textarea: false,
    passThru: null,
    classNames: ['soofa-md-input'],
    // properties
    class: '',
    label: null,
    prompt: null,
    type: 'text',
    update: null,
    optionLabelPath: '',
    onFocus: null,
    onBlur: null,
    onKeyDown: null,
    value: null,
    group: null,
    placeholder: null,
    renderInPlace: false,
    cols: null,
    rows: null,
    form: null,
    name: null,
    maxlength: null,
    readonly: false,
    disabled: false,
    selectionDirection: null,
    selectionStart: null,
    selectionEnd: null,
    spellcheck: null,
    autocomplete: null,
    autofocus: null,
    step: null,
    min: null,
    max: null,
    isTouched: false,
    hideErrors: false,
    // computed

    // handles the paper control type for the input
    control: Ember.computed('type', function () {
      let type = this.get('type');
      if (type === 'text' || type === 'number') {
        return 'input';
      }
      if (type === 'textarea') {
        let passThru = this.getProperties('cols', 'rows', 'form', 'maxlength', 'name', 'readonly', 'required', 'selectionDirection', 'selectionStart', 'selectionEnd', 'spellcheck', 'tabindex', 'wrap');
        this.set('textarea', true);
        this.set('passThru', passThru);
        return 'input';
      } else if (type === 'select') {
        return 'select';
      } else if (type === 'checkbox') {
        return 'checkbox';
      } else if (type === 'radio-group') {
        return 'radio-group';
      } else {
        return 'input';
      }
    }),
    // actions
    actions: {
      validateProperty(changeset, property) {
        if (Ember.isPresent(changeset.validate)) {
          return changeset.validate(property);
        }
      }
    }
  });
  MdInputComponent.reopenClass({
    positionalParams: ['type', 'changeset', 'property']
  });
  var _default = _exports.default = MdInputComponent;
});