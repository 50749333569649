define("@soofa/soofa-location/components/location-error/component", ["exports", "soofa-client-shared/mixins/has-me", "@soofa/soofa-location/components/location-error/template"], function (_exports, _hasMe, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // properties
    errorButtonOk: 'Ok',
    errorMsg: null,
    handleError: null,
    // attributes
    classNames: ['location-messages'],
    layout: _template.default
  });
});