define("@soofa/soofa-shared-client-data/models/card", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    description: attr('string'),
    token: attr('string'),
    createdAt: attr('number', {
      defaultValue: Date.now()
    }),
    // associations
    user: belongsTo('user')
  });
});