define("ember-paper/templates/components/paper-autocomplete-highlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "R+aYHBGd",
    "block": "{\"symbols\":[\"token\"],\"statements\":[[4,\"each\",[[23,[\"tokens\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"isMatch\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"highlight\"],[9],[1,[22,1,[\"text\"]],false],[10]],\"parameters\":[]},{\"statements\":[[1,[22,1,[\"text\"]],false]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-autocomplete-highlight.hbs"
    }
  });
});