define("soofa-client-shared/components/login-page/component", ["exports", "soofa-client-shared/validations/login", "ember-changeset", "ember-changeset-validations", "soofa-client-shared/components/login-page/template"], function (_exports, _login, _emberChangeset, _emberChangesetValidations, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // services
    authenticator: Ember.inject.service(),
    checker: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    session: Ember.inject.service(),
    toaster: Ember.inject.service(),
    // properties
    changeset: null,
    showErrors: false,
    showFormLogin: true,
    transitionTo: 'dashboard',
    welcomeMessage: 'Welcome back to your neighborhood news feed',
    // attributes
    classNames: ['login-page'],
    disableLogin: false,
    layout: _template.default,
    // events
    didReceiveAttrs() {
      // validate form
      let model = new Ember.Object({
        email: null,
        password: null
      });
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_login.default), _login.default);
      changeset.validate();
      this.set('changeset', changeset);
    },
    // computed
    touched: Ember.computed('', function () {
      return Ember.Object.create({
        email: false,
        password: false
      });
    }),
    // actions
    actions: {
      async login() {
        this.set('disableLogin', true);

        // Check if changeset is valid
        let changeset = this.get('changeset');
        let touched = this.get('touched');
        touched.setProperties({
          'email': true,
          'password': true
        });
        if (changeset.get('isValid')) {
          // Getting user info
          let email = changeset.get('email').toLowerCase();
          let password = changeset.get('password');
          let transitionTo = this.get('transitionTo');
          let shouldTransition = true;
          let message = '';
          let user = await this.get('checker').checkForModel('user', 'email', email);
          if (user) {
            let authenticator = this.get('authenticator');
            await authenticator.login({
              provider: 'password',
              email,
              password
            }).then(async () => {
              await authenticator.setupMeService(user);
            }).catch(e => {
              this.set('disableLogin', false);
              shouldTransition = false;
              if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found') {
                message = 'Email or password are incorrect.';
              } else {
                message = e.message;
              }
            });
          } else {
            this.set('disableLogin', false);
            message = 'Email or password are incorrect.';
            shouldTransition = false;
          }

          // Only show toaster when a message is present
          if (message !== '') {
            this.get('toaster').launchToaster('error', message, 'red', 'down left', 5000);
          }

          // If transition is mandatory
          if (shouldTransition) {
            this.get('routing').transitionTo(transitionTo, []);
          }
        } else {
          this.set('disableLogin', false); // enable button
        }
      }
    }
  });
});