define("soofa-client-shared/components/model-creator/component", ["exports", "soofa-client-shared/components/model-creator/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // services
    logCreator: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    scriptr: Ember.inject.service(),
    store: Ember.inject.service(),
    // attributes
    layout: _template.default,
    // properties
    endpoint: '/modelManager',
    containerClass: null,
    relationship: null,
    errorArr: [],
    // actions
    actions: {
      async save(modal) {
        let arrayOfErrors = [];
        if (!this.get('validate')) {
          this.createModel();
          modal.toggleProperty('modal');
        } else {
          arrayOfErrors = await this.validate(this);
          if (arrayOfErrors.length > 0) {
            this.set('errorArr', arrayOfErrors);
          } else {
            this.createModel();
            modal.toggleProperty('modal');
          }
        }
      }
    },
    createModel() {
      let attributeNames = this.get('attributeNames');
      let attributes = attributeNames ? this.getProperties(attributeNames) : {};
      let endpoint = this.get('endpoint');
      let relationship = this.get('relationship') ? this.get('relationship') : null;
      if (Object.values(attributes).some(x => x != null && x != '')) {
        let modelName = this.get('modelName');
        this.get('scriptr').post(endpoint, {
          data: {
            modelName,
            attributes,
            relationship
          }
        }).then(({
          response
        }) => {
          if (response.result != null) {
            let {
              id
            } = response.result;
            if (this.get('log')) {
              // TO DO: Use model object from Scriptr; Requires log-creator.js update
              this.get('store').findRecord(modelName, id).then(model => {
                let message = `created ${modelName} ${id}`;
                this.get('logCreator').createLog(message, [model]);
              });
            }
            if (this.get('redirect')) {
              this.get('routing').transitionTo(this.get('redirect'), [id]);
            }

            // let newAttributes = Object.keys(attributes).reduce(
            //   (accumulator, current) => {
            //     accumulator[current] = '';
            //     return accumulator;
            //   },
            //   {}
            // );

            // this.setProperties(newAttributes);
          } else {
            this.set('creationError', `Unable to create ${modelName}`);
          }
        });
      }
    }
  });
});