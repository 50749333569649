define("@soofa/soofa-shared-client-data/models/geometry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    content: attr('string'),
    // computed
    bounds: Ember.computed('', function () {
      let mapGeometry = JSON.parse(this.get('content'));
      return mapGeometry && mapGeometry.geometry ? mapGeometry.geometry.coordinates : null;
    })
  });
});