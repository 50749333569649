define("soofa-client-shared/components/neighborhood-selector/component", ["exports", "soofa-client-shared/components/neighborhood-selector/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NeighborhoodSelectorComponent = Ember.Component.extend({
    // services
    routing: Ember.inject.service('-routing'),
    scriptr: Ember.inject.service(),
    store: Ember.inject.service(),
    // attributes
    city: null,
    layout: _template.default,
    // events
    async didReceiveAttrs() {
      let cities = await this.get('store').query('city', {
        orderBy: 'active',
        equalTo: true
      });
      this.set('cities', cities);
      let model = this.get('model');
      let city = await model.get('city');
      this.set('city', city);
      if (city) {
        this.send('changeCity', city);
      }
    },
    // actions
    actions: {
      async changeCity(city) {
        let neighborhoods = await city.get('neighborhoods');
        neighborhoods = neighborhoods.filter(neighborhood => {
          if (neighborhood.get('active')) {
            return neighborhood;
          }
        });
        this.set('neighborhoods', neighborhoods);
        this.set('city', city);
        let selectedNeighborhoods = [];
        let model = this.get('model');
        let modelNeighborhoods = await model.get('neighborhoods');
        modelNeighborhoods.forEach(neighborhood => {
          selectedNeighborhoods.pushObject(neighborhood);
        });
        this.set('selectedNeighborhoods', selectedNeighborhoods);
      },
      async saveNeighborhoods(neighborhoodList) {
        let model = this.get('model');
        let modelNeighborhoods = await model.get('neighborhoods');
        let hasMany = this.get('hasMany');

        // get models relationship
        let modelName = `${model.constructor.modelName}s`;
        neighborhoodList.forEach(async function (neighborhood) {
          if (!modelNeighborhoods.includes(neighborhood)) {
            modelNeighborhoods.addObject(neighborhood);
            if (hasMany) {
              let relations = await neighborhood.get(modelName);
              relations.addObject(model);
              neighborhood.save();
            }
          }
        });
        modelNeighborhoods.forEach(async function (neighborhood) {
          if (!neighborhoodList.includes(neighborhood)) {
            modelNeighborhoods.removeObject(neighborhood);
            if (hasMany) {
              let relations = await neighborhood.get(modelName);
              relations.removeObject(model);
              neighborhood.save();
            }
          }
        });
        let city = this.get('city');
        model.set('city', city);
        model.save();
      }
    }
  });
  var _default = _exports.default = NeighborhoodSelectorComponent;
});