define("soofa-client-shared/components/logout-button/component", ["exports", "soofa-client-shared/components/logout-button/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // properties
    label: 'Logout',
    renderAsText: false,
    renderAsContextBtn: false,
    transitionTo: 'index',
    // attributes
    layout: _template.default,
    // services
    authenticator: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    // actions
    actions: {
      async logOut(transitionTo) {
        await this.get('authenticator').signOut();
        await this.get('routing').transitionTo(transitionTo, []);
      }
    }
  });
});