define("soofa-client-shared/helpers/event-multi-days", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.eventMultiDays = eventMultiDays;
  function eventMultiDays(params) {
    if (params.length >= 2) {
      // init
      let [dateTime, endDateTime] = params;

      // check if events are multi days
      let multiDay = false;
      if (endDateTime) {
        let momentDateTime = new _moment.default(dateTime);
        let momentEndDateTime = new _moment.default(endDateTime);

        // check if end date is after start day, granularity: days
        multiDay = momentEndDateTime.isAfter(momentDateTime, 'day');
      }

      // set values
      return multiDay;
    } else {
      return false;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(eventMultiDays);
});