define("soofa-client-shared/components/model-creator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MmMYpfrj",
    "block": "{\"symbols\":[\"modal\",\"creationError\",\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"md-modal-btn\",null,[[\"containerClass\",\"displayAsChip\",\"disableSave\",\"label\",\"primary\",\"closeAction\",\"icon\",\"iconClass\"],[[23,[\"containerClass\"]],[23,[\"displayAsChip\"]],[23,[\"disableSave\"]],[27,\"if\",[[23,[\"overrideName\"]],[23,[\"overrideName\"]],[27,\"concat\",[\"New \",[23,[\"modelName\"]]],null]],null],[23,[\"primary\"]],[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"concat\",[[23,[\"icon\"]]],null],[27,\"concat\",[[23,[\"iconClass\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"creationLoading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"center-align\"],[9],[0,\"\\n      \"],[1,[27,\"loading-spinner\",null,[[\"color\"],[\"blue\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h5\"],[9],[4,\"if\",[[23,[\"overrideName\"]]],null,{\"statements\":[[1,[21,\"overrideName\"],false]],\"parameters\":[]},{\"statements\":[[0,\"New \"],[1,[21,\"modelName\"],false]],\"parameters\":[]}],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout-row layout-wrap\"],[9],[0,\"\\n      \"],[14,3,[[22,0,[]],[22,1,[]]]],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"errorArr\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"errorArr\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"red-text\"],[9],[1,[27,\"paper-icon\",[\"error\"],[[\"class\"],[\"red-icon\"]]],false],[0,\" \"],[1,[22,2,[]],false],[10],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/model-creator/template.hbs"
    }
  });
});