define("soofa-client-shared/components/keen-viz/component", ["exports", "soofa-client-shared/components/keen-viz/template", "moment", "soofa-client-shared/mixins/handles-query", "ember-composability-tools"], function (_exports, _template, _moment, _handlesQuery, _emberComposabilityTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_emberComposabilityTools.ParentMixin, _handlesQuery.default, {
    // properties
    chartType: null,
    title: null,
    width: null,
    height: null,
    colors: ['#43A2CA', '#FEC44F', '#FC8D59', '#7FCDBB', '#2CA25F', '#DD1C77', '#8855A7', '#1C9099', '#FFEDA0', '#636363', '#9EBCDA'],
    color: null,
    localtime: true,
    xAxisDateFormat: null,
    tooltipDateFormat: null,
    yMax: null,
    yMin: null,
    // attributes
    layout: _template.default,
    chart: null,
    error: null,
    result: null,
    classNames: ['keen-viz'],
    // events
    didInsertElement() {
      this.visualize();
    },
    // helpers
    _prepareChart(queryParams) {
      let chartOptions = this._chartOptions();
      let colors = null;
      let color = this.get('color');
      if (color !== null && color !== 'null') {
        let important = '';
        if (chartOptions.chartType === 'metric') {
          important = ' !important';
        }
        color = `${color}${important}`;
        colors = color;
      } else {
        if (chartOptions.colors) {
          colors = chartOptions.colors;
        } else {
          colors = this.get('colors');
        }
      }
      let chart = new Dataviz({
        title: chartOptions.title,
        stacked: chartOptions.stacked,
        stacking: chartOptions.stacking,
        type: chartOptions.chartType,
        container: this.element,
        colors
      }).prepare(); // start spinner

      let config = {};

      // handle axis config
      config.axis = {
        x: {
          localtime: this.get('localtime')
        },
        y: {
          max: this.get('yMax'),
          min: this.get('yMin')
        }
      };

      // handle tooltip as datetime in moment format
      let tooltipDateFormat = queryParams ? queryParams.tooltipDateFormat : this.get('tooltipDateFormat');
      if (tooltipDateFormat) {
        config.tooltip = {
          format: {
            title(d) {
              return (0, _moment.default)(d).format(tooltipDateFormat);
            }
          }
        };
      }

      // handle x axis format, ex '%Y-%m'
      let xAxisDateFormat = queryParams ? queryParams.xAxisDateFormat : this.get('xAxisDateFormat');
      if (xAxisDateFormat && xAxisDateFormat !== 'autoformat') {
        chart.dateFormat(xAxisDateFormat);
      }

      // applying config
      chart.chartOptions(config);
      this.set('chart', chart);
    },
    _renderChart() {
      let error = this.get('error');
      let chart = this.get('chart');
      if (error) {
        chart.message(error.message);
      } else {
        let result = this.get('result');
        chart.data(result).render();
      }
    },
    _chartOptions() {
      return this.getProperties('chartType', 'title', 'width', 'height', 'stacked', 'stacking', 'colors');
    }
  });
});