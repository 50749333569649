define("soofa-client-shared/utils/cloudinary-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildUrl = buildUrl;
  function buildUrl(cloudinaryId, cloudName, width, height, crop, grayscale, imageFormat, dprAuto, qAuto, flLossy, fAuto, background) {
    // init

    // allows to set custom image extension, png is default for null
    imageFormat = imageFormat ? imageFormat : 'png';
    grayscale = grayscale || false;
    let url = `https://res.cloudinary.com/${cloudName}/image/upload/`;
    let dprResult = '';
    let imageSizeResult = '';
    let cropResult = '';
    let grayscaleResult = '';
    let qAutoResult = '';
    let fAutoResult = '';
    let flLossyResult = '';
    let backgroundResult = '';
    if (qAuto) {
      qAutoResult = 'q_auto,';
    }
    if (fAuto) {
      fAutoResult = 'f_auto,';
    }
    if (flLossy) {
      flLossyResult = 'fl_lossy,';
    }

    // ----------------------------------------------------------------------------------------------
    if (dprAuto) {
      dprResult = 'dpr_auto,';
    }

    // ----------------------------------------------------------------------------------------------
    if (width && height) {
      imageSizeResult = `w_${width},h_${height},`;
    } else if (width) {
      imageSizeResult = `w_${width},`;
    } else if (height) {
      imageSizeResult = `h_${height},`;
    }

    // ----------------------------------------------------------------------------------------------
    // scale, fit, mfit, fill, lfill, limit, pad, lpad, mpad, crop, thumb, imagga_crop and imagga_scale
    if (crop) {
      cropResult = `c_${crop},`;
    }

    // ----------------------------------------------------------------------------------------------
    if (grayscale) {
      grayscaleResult = 'e_grayscale,';
    }

    // ----------------------------------------------------------------------------------------------
    if (background) {
      backgroundResult = `b_${background},`;
    }
    if (dprResult.length >= 1 || imageSizeResult.length >= 1 || cropResult.length >= 1 || grayscaleResult.length >= 1 || backgroundResult.length >= 1) {
      // if there are any args at all, then do this, if not then no action needed
      let argsGrouped = qAutoResult + fAutoResult + flLossyResult + dprResult + imageSizeResult + cropResult + grayscaleResult + backgroundResult;
      // remove the last char, because they all have trailing commas
      let argsStr = argsGrouped.slice(0, -1);
      url += argsStr;
      url += '/';
    }
    url += `${cloudinaryId}.${imageFormat}`;
    return url;
  }
});