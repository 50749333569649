define("soofa-client-shared/components/soofa-campaign-times/component", ["exports", "soofa-client-shared/components/soofa-campaign-times/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Displays campaign times
    Consumed by:
      - soofa-client-shared/addon/components/soofa-campaign-card/template.hbs
      - ads-client/app/components/campaign-review/template.hbs
      - admin-client/app/components/ad-review-card/template.hbs
  */

  const SoofaCampaignTimesComponent = Ember.Component.extend({
    // properties
    textOnly: false,
    // attributes
    layout: _template.default,
    classNames: ['soofa-campaign-times']
  });
  SoofaCampaignTimesComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = _exports.default = SoofaCampaignTimesComponent;
});