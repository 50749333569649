define("soofa-client-shared/components/md-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "k6XXtHwB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[12,\"class\",[28,[[21,\"icon\"],\" prefix\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"input\"],[12,\"id\",[28,[[21,\"id\"]]]],[12,\"class\",[28,[[27,\"if\",[[23,[\"validate\"]],\"validate\"],null],\" \",[27,\"if\",[[23,[\"errors\"]],\"invalid\",\"valid\"],null],\" datepicker\"]]],[12,\"data-value\",[28,[[27,\"get\",[[23,[\"changeset\"]],[23,[\"property\"]]],null]]]],[12,\"required\",[21,\"required\"]],[12,\"readonly\",[21,\"readonly\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"placeholder\",[21,\"placeholder\"]],[11,\"type\",\"date\"],[9],[10],[0,\"\\n\\n\"],[7,\"label\"],[12,\"for\",[21,\"id\"]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\\n\"],[7,\"small\"],[11,\"class\",\"red-text\"],[9],[0,\"\\n  \"],[4,\"if\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\" \"],[1,[23,[\"errors\",\"firstObject\"]],false],[0,\" \"]],\"parameters\":[]},{\"statements\":[[0,\"   \"]],\"parameters\":[]}],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/md-date/template.hbs"
    }
  });
});