define("soofa-client-shared/helpers/do-atob", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.doAtob = doAtob;
  function doAtob(string) {
    return atob(string);
  }
  var _default = _exports.default = Ember.Helper.helper(doAtob);
});