define("soofa-client-shared/components/radio-group/component", ["exports", "soofa-client-shared/components/radio-group/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Renders an array of radio buttons
    Consumed by:
      ads-client/app/components/layout-group-chooser/template.hbs
      admin-client/app/components/block-editor/template.hbs
  */

  const RadioGroupComponent = Ember.Component.extend({
    // attributes
    layout: _template.default,
    classNames: ['radio-group'],
    // properties
    update: null
  });
  RadioGroupComponent.reopenClass({
    positionalParams: ['changeset', 'property']
  });
  var _default = _exports.default = RadioGroupComponent;
});