define('ember-modal-dialog/components/basic-dialog', ['exports', 'ember-modal-dialog/templates/components/basic-dialog'], function (exports, _basicDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _basicDialog.default,

    containerClassNames: null,
    overlayClassNames: null,
    wrapperClassNames: null,

    modalService: Ember.inject.service('modal-dialog'),
    destinationElementId: Ember.computed.oneWay('modalService.destinationElementId'),

    variantWrapperClass: 'emd-static',
    containerClassNamesString: Ember.computed('containerClassNames.[]', 'targetAttachmentClass', 'attachmentClass', 'containerClass', function () {
      return [this.get('containerClassNames').join(' '), this.get('targetAttachmentClass'), this.get('attachmentClass'), this.get('containerClass')].filter(className => !Ember.isEmpty(className)).join(' ');
    }),
    overlayClassNamesString: Ember.computed('overlayClassNames.[]', 'overlayClass', 'translucentOverlay', function () {
      return [this.get('overlayClassNames').join(' '), this.get('translucentOverlay') ? 'translucent' : null, this.get('overlayClass')].filter(className => !Ember.isEmpty(className)).join(' ');
    }),
    wrapperClassNamesString: Ember.computed('wrapperClassNames.[]', 'targetAttachmentClass', 'variantWrapperClass', 'wrapperClass', function () {
      return [this.get('wrapperClassNames').join(' '), this.get('targetAttachmentClass').replace('emd-', 'emd-wrapper-'), this.get('variantWrapperClass'), this.get('wrapperClass')].filter(className => !Ember.isEmpty(className)).join(' ');
    }),

    concatenatedProperties: ['containerClassNames', 'overlayClassNames', 'wrapperClassNames'],

    translucentOverlay: false,
    clickOutsideToClose: false,
    hasOverlay: true,
    isCentered: true,
    overlayPosition: null,
    isOverlaySibling: Ember.computed('overlayPosition', function () {
      return this.get('overlayPosition') === 'sibling';
    }),

    isIOS: Ember.computed(function () {
      return (/iPad|iPhone|iPod/.test(navigator.userAgent)
      );
    }),

    makeOverlayClickableOnIOS: Ember.on('didInsertElement', function () {
      if (this.get('isIOS')) {
        Ember.$('div[data-ember-modal-dialog-overlay]').css('cursor', 'pointer');
      }
    }),

    didInsertElement() {
      if (!this.get('clickOutsideToClose')) {
        return;
      }

      const handleClick = event => {
        let $eventTarget = Ember.$(event.target);

        // if the click has already resulted in the target
        // being removed or hidden, do nothing
        if (!$eventTarget.is(':visible')) {
          return;
        }

        // if the click is within the dialog, do nothing
        if ($eventTarget.closest('.ember-modal-dialog').length) {
          return;
        }

        this.sendAction('onClose');
      };
      const registerClick = () => Ember.$(window.document).on(`click.ember-modal-dialog-${Ember.guidFor(this)}`, handleClick);

      // setTimeout needed or else the click handler will catch the click that spawned this modal dialog
      setTimeout(registerClick);

      if (this.get('isIOS')) {
        const registerTouch = () => Ember.$(window.document).on(`touchend.ember-modal-dialog-${Ember.guidFor(this)}`, handleClick);
        setTimeout(registerTouch);
      }
      this._super(...arguments);
    },

    willDestroyElement() {
      Ember.$(window.document).off(`click.ember-modal-dialog-${Ember.guidFor(this)}`);
      if (this.get('isIOS')) {
        Ember.$(window.document).off(`touchend.ember-modal-dialog-${Ember.guidFor(this)}`);
      }
      this._super(...arguments);
    }
  });
});