define("soofa-client-shared/validations/event", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Event name should not be left blank'
    })],
    dateTime: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Date should not be left blank'
    })],
    addressString: [(0, _validators.validatePresence)({
      presence: true,
      message: 'The event needs to have a location'
    })],
    locationName: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Location should not be left blank'
    }), (0, _validators.validateLength)({
      max: 50,
      message: 'Location name is too long'
    })]
  };
});