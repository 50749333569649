define("@soofa/soofa-shared-client-data/models/ticket", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    createdAt: attr('number'),
    diagnosis: attr('string'),
    device: attr('string'),
    deviceType: attr('string'),
    lastObserved: attr('number'),
    numOccurrences: attr('number'),
    product: attr('string'),
    // associations
    location: belongsTo('location')
  });
});