define("soofa-client-shared/services/authenticator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    // services
    meService: Ember.inject.service('me'),
    session: Ember.inject.service(),
    scriptr: Ember.inject.service(),
    keen: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    checker: Ember.inject.service(),
    store: Ember.inject.service(),
    config: Ember.inject.service(),
    // actions
    async signOut() {
      await this._removeMe();
      await this.get('session').close();
    },
    async login(authObject) {
      return this.get('session').open('firebase', authObject);
    },
    async createNewUser(userObject) {
      let auth = this.get('firebaseApp').auth();
      // if the user object has a password, we'll need to create the firebase user first
      if (userObject.password) {
        let authResponse = await auth.createUserWithEmailAndPassword(userObject.email, userObject.password);
        userObject.uid = authResponse.uid;
      }

      // set referral info from visits and marketing
      userObject.referralSource = this.get('meService.referralSource');
      userObject.referralId = this.get('meService.referralId');

      // set app source info
      let {
        config
      } = this.get('config');
      userObject.appSource = config.modulePrefix;
      let user = this.get('store').createRecord('user', userObject);
      await user.save();
      this._createNewUserEvent(user);

      // check email for customer domain
      // eslint-disable-next-line ember-suave/prefer-destructuring
      let domain = userObject.email.split('@')[1];
      let customer = await this.get('checker').checkForModel('customer', 'domain', domain);
      if (customer) {
        await this._addUserToCustomer(customer, user);
      }

      // complete referrals
      let referrals = [];
      let referral = this.get('meService.referral');
      if (referral) {
        referrals.pushObject(referral);
      }
      referral = await this.get('checker').checkForModel('referral', 'sentTo', userObject.email);
      if (referral) {
        referrals.pushObject(referral);
      }
      referrals.forEach(referral => {
        this._completeReferral(referral, user);
      });
      return user;
    },
    async setupMeService(user) {
      let customerModel = await user.get('customer');
      this.get('meService').setProperties({
        model: user,
        customerModel
      });
    },
    // helpers
    async _completeReferral(referral, user) {
      let scriptr = this.get('scriptr');

      // attach new user to referral
      referral.setProperties({
        receivedBy: user,
        success: true
      });
      referral.save();

      // complete campaign transfer
      let campaign = await referral.get('campaign');
      if (campaign) {
        await this._transferCampaign(campaign, user);
      }

      // complete customer setup
      let customer = await referral.get('customer');
      if (customer) {
        await this._addUserToCustomer(customer, user);
        // finish provision
        await scriptr.request(`enterpriseUserProvisioned?provisionee=${user.get('id')}`);
      }
      user.setProperties({
        referralSource: referral.get('source'),
        referralId: referral.get('id')
      });
      user.save();
    },
    async _addUserToCustomer(customer, user) {
      let users = await customer.get('users');
      users.addObject(user);
      await customer.save();
      user.setProperties({
        customer,
        accountType: 'Enterprise'
      });
      await user.save();
    },
    async _transferCampaign(campaign, user) {
      // remove the campaign from the existing user's campaigns
      let oldUser = await campaign.get('user');
      let oldUserCampaigns = await oldUser.get('campaigns');
      oldUserCampaigns.removeObject(campaign);
      oldUser.save();

      // set the new user on the campaign
      campaign.set('user', user);
      campaign.save();

      // add the campaign to the new user's campaigns
      let newUserCampaigns = await user.get('campaigns');
      newUserCampaigns.pushObject(campaign);
    },
    async _removeMe() {
      let meService = this.get('meService');
      let me = meService.get('model');
      me.unloadRecord();
      // Need to properly manage unloading all records here
      // https://github.com/firebase/emberfire/issues/400
      meService.set('model', null);
    },
    async _createNewUserEvent(user) {
      let {
        config
      } = this.get('config');
      this.get('scriptr').request(`newUser?userId=${user.id}`);
      Ember.run(this, () => {
        this.get('keen').recordEvent('signup', {
          application: config.modulePrefix,
          me: user.getProperties('displayName', 'id', 'email', 'referralSource', 'referralId')
        });
      });
    }
  });
});