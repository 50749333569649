define("soofa-client-shared/utils/campaign-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    // Should eventually be rewritten using async like:
    // export function async destroyCampaign ....
    destroyCampaign(campaign) {
      campaign.get('ad').then(function (resolvedAd) {
        if (resolvedAd) {
          resolvedAd.get('campaigns').then(function (resolvedCampaigns) {
            resolvedCampaigns.removeObject(campaign);
            resolvedAd.save();
          });
        }
      }).then(function () {
        campaign.get('times').then(function (resolvedTimes) {
          resolvedTimes.forEach(function (time) {
            time.get('campaigns').then(function (resolvedCampaigns) {
              resolvedCampaigns.removeObject(campaign);
            });
            time.save();
          });
          campaign.destroyRecord();
        });
      });
    }
  };
});