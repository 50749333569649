define("@soofa/soofa-shared-client-data/models/applet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    adModeDataLength: attr('number'),
    contentType: attr('string'),
    displayName: attr('string', {
      defaultValue: 'New Applet'
    }),
    description: attr('string'),
    newsfeedModeDataLength: attr('number'),
    transitModeDataLength: attr('number'),
    workerName: attr('string'),
    // associations
    instances: hasMany('instance'),
    blocks: hasMany('block')
  });
});