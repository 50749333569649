define("soofa-client-shared/components/sign-client-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // services
    store: Ember.inject.service(),
    // properties
    device: null,
    ENV: null,
    sign: null,
    // attributes
    attributeBindings: ['frameborder', 'scrolling', 'src', 'style'],
    frameborder: 0,
    scrolling: 'no',
    tagName: 'iframe',
    // alias
    height: Ember.computed.alias('sign.height'),
    width: Ember.computed.alias('sign.width'),
    // computed
    style: Ember.computed('width', 'height', function () {
      return `border:1px solid #555555;height:${this.get('height')}px;width:${this.get('width')}px`;
    }),
    // events
    async didReceiveAttrs() {
      // init
      let url = '';
      let ENV = this.get('ENV');
      let device = this.get('device');
      if (device) {
        url = `${ENV.signClientUrl}/device/${device.get('id')}`;
      } else {
        this.get('store').query('location', {
          orderBy: 'sign',
          equalTo: this.get('sign').get('id')
        }).then(async function (locations) {
          let location = locations.get('firstObject');
          let product = await location.get('product');
          let deviceId = await product.get('driver').get('id');
          url = `${ENV.signClientUrl}/device/${deviceId}`;
        });
      }

      // set the url
      this.set('src', url);
    }
  });
});