define("@soofa/soofa-shared-client-data/models/instance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    adImageUrl: attr('string'),
    adRenderSize: attr('number'),
    allowPPSAds: attr('boolean'),
    alternativeName: attr('string'),
    appletName: attr('string'),
    contentPayload: attr('string'),
    billupsAdUnitId: attr('string'),
    displayName: attr('string', {
      defaultValue: 'New Instance'
    }),
    estimatedMonthlySessions: attr('number'),
    forceImpressionEstimation: attr('boolean'),
    fresh: attr('boolean'),
    minutes: attr('number'),
    mostRecentPostUrl: attr('string'),
    parameters: attr('string'),
    searchIndex: attr('string'),
    updatedAt: attr('number'),
    // computed
    contentType: Ember.computed(async function () {
      let applet = await this.get('applet');
      return applet.get('contentType');
    }),
    descriptiveName: Ember.computed(function () {
      return `${this.get('displayName')} (${this.get('appletName')})`;
    }),
    // applet
    applet: belongsTo('applet'),
    blocks: hasMany('block'),
    // used for cleanup on instance deletion
    contents: hasMany('content', {
      inverse: null
    }),
    device: belongsTo('device'),
    // ads
    slots: hasMany('slot'),
    ad: belongsTo('ad'),
    posts: hasMany('post'),
    // ads
    accumulatedVoice: attr('number'),
    activeAdInstance: attr('boolean'),
    adsInstance: attr('boolean'),
    adsInstanceInspectionMode: attr('boolean'),
    averageDailyImpressions: attr('number'),
    buzzedCampaigns: hasMany('campaign', {
      inverse: null
    }),
    formats: hasMany('format'),
    showProfile: attr('boolean'),
    timeLimit: attr('boolean'),
    // polls
    poll: belongsTo('poll'),
    tags: attr('string'),
    // comma delimited no spaces

    // product
    hasLocation: attr('boolean'),
    location: belongsTo('location', {
      inverse: null
    })
  });
});