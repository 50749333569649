define("@soofa/soofa-shared-client-data/models/business", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    active: attr('boolean', {
      defaultValue: false
    }),
    address: attr('string'),
    content: attr('string'),
    hours: attr('string'),
    name: attr('string'),
    number: attr('string'),
    stagingContent: attr('string'),
    lastMessageDateTime: attr('number'),
    // days of the week
    monday: attr('boolean', {
      defaultValue: false
    }),
    tuesday: attr('boolean', {
      defaultValue: false
    }),
    wednesday: attr('boolean', {
      defaultValue: false
    }),
    thursday: attr('boolean', {
      defaultValue: false
    }),
    friday: attr('boolean', {
      defaultValue: false
    }),
    saturday: attr('boolean', {
      defaultValue: false
    }),
    sunday: attr('boolean', {
      defaultValue: false
    }),
    // belongsTo
    slot: belongsTo('slot', {
      inverse: 'displayBusinesses'
    })
  });
});