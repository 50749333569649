define("soofa-client-shared/components/button-confirm/component", ["exports", "soofa-client-shared/components/button-confirm/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ButtonConfirmComponent = Ember.Component.extend({
    // attributes
    classNames: ['button-confirm'],
    deleteTriggered: false,
    layout: _template.default,
    // properties
    disabled: false,
    icon: '',
    iconCancel: 'cancel',
    iconConfirm: 'check',
    model: null,
    text: '',
    textCancel: 'No',
    textConfirm: 'Yes',
    textConfirmOrCancelDescription: 'Are you sure?',
    // actions
    actions: {
      cancel() {
        this.set('triggered', false);
      },
      confirm() {
        // this function needs to be implemented on each case
        // as each model has its own relationships to be cleared
        this.execute(this.model, this);
      },
      execute() {
        this.set('triggered', true);
      }
    }
  });
  var _default = _exports.default = ButtonConfirmComponent;
});