define("ember-mdi/components/mdi-icon", ["exports", "ember-mdi/templates/components/mdi-icon"], function (_exports, _mdiIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const mdiIcon = Ember.Component.extend({
    layout: _mdiIcon.default,
    tagName: 'svg',
    classNames: ['mdi-icon'],
    classNameBindings: ['iconClass', 'spin:mdi-icon-spin'],
    attributeBindings: ['role', 'size:height', 'size:width', 'viewbox', 'transform'],
    size: 24,
    role: 'img',
    icon: null,
    spin: false,
    rotate: null,
    flipH: false,
    flipV: false,
    fill: null,
    stroke: null,
    strokeWidth: null,
    strokeLinecap: null,
    strokeLinejoin: null,
    strokeDasharray: null,
    strokeDashoffset: null,
    strokeOpacity: null,
    init() {
      this._super(...arguments);

      // Require that users pass an icon
      (false && !(Ember.isPresent(this.get('icon'))) && Ember.assert('{{mdi-icon}} requires an `icon` to be passed as the value.', Ember.isPresent(this.get('icon'))));
    },
    iconClass: Ember.computed('icon', function () {
      return `mdi-icon-${this.get('icon')}`;
    }),
    viewbox: Ember.computed('size', function () {
      const size = this.get('size');
      return `0 0 ${size} ${size}`;
    }),
    transform: Ember.computed('rotate', 'flipH', 'flipV', function () {
      const rotate = this.get('rotate');
      const flipH = this.get('flipH');
      const flipV = this.get('flipV');
      let transform = '';
      if (rotate) {
        transform += `rotate(${rotate})`;
      }
      if (flipH && flipV) {
        transform += 'scale(-1,-1)';
      } else if (flipH) {
        transform += 'scale(-1,1)';
      } else if (flipV) {
        transform += 'scale(1,-1)';
      }
      return transform;
    })
  });
  mdiIcon.reopenClass({
    positionalParams: ['icon']
  });
  var _default = _exports.default = mdiIcon;
});