define("soofa-client-shared/validators/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateEmptyString = void 0;
  let validateEmptyString = function () {
    return (key, newValue) => {
      let invalid = !newValue || /^\s*$/.test(newValue);
      if (invalid) {
        return 'This field cannot be left blank.';
      } else {
        return true;
      }
    };
  };
  _exports.validateEmptyString = validateEmptyString;
});