define("soofa-client-shared/components/ember-table-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wLHihUdf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"linkTo\"]]],null,{\"statements\":[[4,\"link-to\",[[23,[\"linkTo\"]],[23,[\"linkToModel\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/ember-table-cell/template.hbs"
    }
  });
});