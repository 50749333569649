define("@soofa/soofa-shared-client-data/models/screen-state", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    contentData: attr('string'),
    createdAt: attr('number', {
      defaultValue: Date.now()
    })
  });
});