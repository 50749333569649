define("soofa-client-shared/components/accordion-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NN4aZgJ+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"centered-with-text click-fix heading\"],[11,\"role\",\"button\"],[3,\"action\",[[22,0,[]],[23,[\"toggleProperty\"]],\"open\"]],[9],[0,\"\\n    \"],[1,[27,\"mdi-icon\",[[27,\"if\",[[23,[\"open\"]],\"chevron-down\",\"chevron-right\"],null]],null],false],[0,\"\\n    \"],[1,[21,\"heading\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"       \"],[1,[27,\"mdi-icon\",null,[[\"icon\",\"size\",\"fill\",\"class\",\"rotate\"],[[23,[\"icon\"]],[27,\"if\",[[23,[\"iconSize\"]],[23,[\"iconSize\"]],12],null],[27,\"if\",[[23,[\"iconFill\"]],[23,[\"iconFill\"]],\"#7d7d7d\"],null],[23,[\"iconClass\"]],[27,\"if\",[[23,[\"iconRotate\"]],[23,[\"iconRotate\"]],0],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"tooltipText\"]]],null,{\"statements\":[[4,\"paper-tooltip\",null,[[\"position\"],[\"top\"]],{\"statements\":[[0,\"        \"],[1,[21,\"tooltipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"innerClass\"],\" content\"]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"open\"]]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/accordion-item/template.hbs"
    }
  });
});