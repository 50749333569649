define('ember-string-helpers/helpers/substr', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.substr = substr;


	// eslint-disable-next-line no-unused-vars
	function substr([value, ...rest], hash) {
		if (typeof value === 'string') {
			let start = hash.start || 0;
			let length = hash.length;
			return value.substr(start, length);
		} else {
			return value;
		}
	}

	exports.default = Ember.Helper.helper(substr);
});