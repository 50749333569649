define("soofa-client-shared/components/neighborhood-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eylaqrd0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex-100\"],[9],[0,\"\\n  \"],[1,[27,\"soofa-md-input\",[\"select\"],[[\"options\",\"label\",\"update\",\"optionLabelPath\",\"prompt\"],[[23,[\"cities\"]],\"City\",[27,\"action\",[[22,0,[]],\"changeCity\"],null],\"name\",\"Select a city\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex-100\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"strong\"],[9],[0,\"Select neighborhoods\"],[10],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-100\"],[9],[0,\"\\n    \\n    \"],[1,[27,\"checkbox-list\",null,[[\"renderAsTable\",\"itemNameAttr\",\"tableItems\",\"tableItemsSelected\",\"tableColumns\",\"customSave\"],[[23,[\"renderAsTable\"]],\"name\",[27,\"await\",[[23,[\"neighborhoods\"]]],null],[23,[\"selectedNeighborhoods\"]],[27,\"array\",[[27,\"hash\",null,[[\"label\",\"property\"],[\"Neighborhood\",\"name\"]]]],null],[27,\"action\",[[22,0,[]],\"saveNeighborhoods\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/neighborhood-selector/template.hbs"
    }
  });
});