define("@soofa/soofa-shared-client-data/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    activeAdInstance: attr('boolean', {
      defaultValue: false
    }),
    advertising: attr('boolean'),
    apId: attr('string'),
    benchActiveDiagnostics: attr('boolean', {
      defaultValue: false
    }),
    comingSoon: attr('boolean', {
      defaultValue: true
    }),
    crcSuccessTimestamp: attr('number'),
    displayCRC: attr('string'),
    expiredCRC: attr('string'),
    freshdeskId: attr('number'),
    geoCity: attr('string'),
    geohash: attr('string'),
    geoState: attr('string'),
    hasAdsInstance: attr('boolean'),
    hasCity: attr('boolean', {
      defaultValue: false
    }),
    hasNeighborhood: attr('boolean', {
      defaultValue: false
    }),
    hasSign: attr('boolean'),
    hasTicket: attr('boolean'),
    heading: attr('string'),
    impressionsCalibrationFactor: attr('number'),
    impressionsDailyEstimate: attr('number'),
    // geoImpressionsCount
    impGeopathDigitalDaily: attr('number'),
    impGeopathDigitalDaily18: attr('number'),
    impGeopathDigitalDaily21: attr('number'),
    impGeopathStaticDaily: attr('number'),
    impGeopathStaticDaily18: attr('number'),
    impGeopathStaticDaily21: attr('number'),
    impGeopathLastUpdate: attr('number'),
    insufficientSignal: attr('boolean', {
      defaultValue: false
    }),
    inspectionMode: attr('boolean'),
    minimumNewsfeedRequired: attr('number'),
    lat: attr('number'),
    lastMaintenanceInspection: attr('number'),
    lastCrcConfirmedCampaign: attr('string'),
    lng: attr('number'),
    locationName: attr('string'),
    locationNotes: attr('string'),
    lockUi: attr('boolean', {
      defaultValue: false
    }),
    marketingPhoto: attr('string'),
    mode: attr('string'),
    // Types ['Advertisement', 'NewsFeed', 'Emergency']
    opthaUpdatedAt: attr('number'),
    payingLandowner: attr('boolean', {
      defaultValue: false
    }),
    phoneNumber: attr('string'),
    preview: attr('boolean'),
    remnantSlots: attr('number'),
    searchIndex: attr('string'),
    signPhoto: attr('string'),
    staticImageURL: attr('string'),
    streetViewPhoto: attr('string'),
    sunrise: attr('number'),
    sunset: attr('number'),
    wideTransit: attr('boolean'),
    ticketInfo: attr('string'),
    ticketPriority: attr('number'),
    timeZone: attr('string'),
    sixMinuteLandowner: attr('boolean'),
    sok: attr('string'),
    twoMinuteSlots: attr('boolean'),
    updatedAt: attr('number'),
    uptimeStatus: attr('number'),
    uptimeStatusStartAndEnd: attr('string'),
    vinylFullResLink: attr('string'),
    vinylPhoto: attr('string'),
    voiceAvailable: attr('number'),
    voiceAvailableExternal: attr('number'),
    wayfinderCloudinaryRevId: attr('string'),
    // associations
    adsInstance: belongsTo('instance', {
      inverse: null
    }),
    campaigns: hasMany('campaign', {
      inverse: null
    }),
    city: belongsTo('city'),
    contents: hasMany('content'),
    facebookInstance: belongsTo('instance', {
      inverse: null
    }),
    landOwner: belongsTo('customer'),
    landownerInstance: belongsTo('instance', {
      inverse: null
    }),
    landownerCampaigns: hasMany('campaign', {
      inverse: null
    }),
    neighborhood: belongsTo('neighborhood'),
    poll: belongsTo('poll'),
    power: belongsTo('power'),
    product: belongsTo('product'),
    renderers: hasMany('renderer'),
    resolution: belongsTo('resolution'),
    screenState: belongsTo('screenState'),
    sign: belongsTo('sign', {
      inverse: null
    }),
    // SIGN  == MODE
    signTemplateOverride: belongsTo('sign', {
      inverse: null
    }),
    tier: belongsTo('tier'),
    tickets: hasMany('ticket'),
    transitInstance: belongsTo('instance', {
      inverse: null
    }),
    voices: hasMany('voice'),
    workOrders: hasMany('workOrder'),
    weatherForecast: belongsTo('weather')
  });
});