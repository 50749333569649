define("soofa-client-shared/components/upload-widget/component", ["exports", "soofa-client-shared/components/upload-widget/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // attributes
    layout: _template.default,
    // properties
    eager: null,
    name: 'photos',
    uploadPresetPath: null,
    buttonText: 'Upload',
    loadingState: '',
    maxFileSizeBytes: 4194304,
    // 4194304 = 4 mb
    fileSizeError: false,
    type: 'image/*',
    classNames: ['upload-widget'],
    showAllowedFileTypes: true,
    // Services
    cloudinary: Ember.inject.service(),
    config: Ember.inject.service(),
    scriptr: Ember.inject.service(),
    // computed
    maxFileSize: Ember.computed('maxFileSizeBytes', function () {
      return this.get('maxFileSizeBytes') / 1024 / 1024; // bytes to mega bytes
    }),
    cloudinaryApiKey: Ember.computed.alias('config.config.cloudinary.apiKey'),
    // This function should be overwritten in the consuming template
    onSuccess(cloudinaryId) {
      // console.log('success', cloudinaryId);
      return cloudinaryId;
    },
    // Handles upload state changes
    onStateChange(state) {
      return state;
    },
    // uploadFile: sends the chosen image to cloudinary
    uploadFile: (0, _emberConcurrency.task)(function* (file, parameters) {
      // eslint-disable-line require-yield
      let {
        onSuccess,
        onStateChange
      } = this;
      onStateChange(true);

      // Getting cloudinary service
      let cloudinary = this.get('cloudinary');
      try {
        // Sending image to cloudinary with upload_preset
        // let response = yield file.upload('https://api.cloudinary.com/v1_1/' + cloudName + '/image/upload', {data:{upload_preset: upload_preset}});
        let fd = new FormData();
        fd.append('file', file.blob);
        fd.append('upload_preset', parameters.uploadPreset);
        if (parameters.eager) {
          fd.append('api_key', this.get('cloudinaryApiKey'));
          fd.append('eager', parameters.eager);
          fd.append('signature', parameters.signature);
          fd.append('timestamp', parameters.timestamp);
        }

        // Calling cloudinary service
        let type = this.get('type');
        if (type === 'video/*') {
          cloudinary.uploadVideo(fd).then(onSuccess).catch(error => {
            return error;
          });
        } else {
          // try to upload as image as default
          cloudinary.uploadImage(fd).then(onSuccess).catch(error => {
            return error;
          });
        }
      } catch (e) {
        return e;
      }
    }).maxConcurrency(3).enqueue(),
    // actions
    actions: {
      // ember-file-upload: triggers file upload
      uploadFile(file) {
        if (file.blob.size > this.get('maxFileSizeBytes') && this.get('noSizeLimit') !== true) {
          this.set('fileSizeError', true);
        } else {
          let parameters = null;

          // Path in config that contains the uploadPreset
          let uploadPresetPath = this.get('uploadPresetPath');

          // Retrieving upload_preset from config
          let {
            config
          } = this.get('config');
          let uploadPreset = config.cloudinary[uploadPresetPath];

          // request signature if eager transformation was sent
          let eager = this.get('eager');
          if (eager) {
            this.get('scriptr').request(`cloudinary/generateSignature?eager=${encodeURI(eager)}&uploadPreset=${uploadPreset}`).then(response => {
              parameters = {
                eager,
                signature: response.response.result.signature,
                timestamp: response.response.result.timestamp,
                uploadPreset
              };
              this.get('uploadFile').perform(file, parameters);
            });
          } else {
            parameters = {
              uploadPreset
            };
            this.get('uploadFile').perform(file, parameters);
          }
        }
      },
      // Updates uploading state
      newState(state) {
        this.set('loadingState', state);
      }
    }
  });
});