define("soofa-client-shared/services/image-render-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { getOwner } from '@ember/application';
  var _default = _exports.default = Ember.Service.extend({
    name: 'imageRenderTracker',
    expectedImages: 0,
    renderedImages: 0,
    setup(expectedImages) {
      this.set('expectedImages', expectedImages);
      if (expectedImages == 0) {
        this.indicateAllImagesRendered();
      } else {
        document.allImagesRendered = false;
      }
    },
    imageRendered() {
      let imagesRendered = this.get('renderedImages');
      let newImagesRendered = imagesRendered + 1;
      this.set('renderedImages', newImagesRendered);
      let expectedImages = this.get('expectedImages');
      if (newImagesRendered >= expectedImages) {
        this.indicateAllImagesRendered();
      }
    },
    indicateAllImagesRendered() {
      document.allImagesRendered = true;
    }
  });
});