define("soofa-client-shared/components/cloudinary-image/component", ["exports", "soofa-client-shared/utils/cloudinary-utils"], function (_exports, _cloudinaryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CloudinaryImageComponent = Ember.Component.extend({
    // service
    imageRenderTracker: Ember.inject.service(),
    // properties
    imageFormat: 'png',
    // attributes
    tagName: 'img',
    attributeBindings: ['src', 'style', 'alt'],
    cloudinaryId: null,
    grayscale: false,
    crop: 'lpad',
    src: null,
    // setup
    didReceiveAttrs() {
      this._super(...arguments);
      this.get('url');
    },
    style: Ember.computed('cssStyle', function () {
      return this.get('cssStyle');
    }),
    // computed
    url: Ember.computed('cloudinaryId', 'width', 'height', 'crop', function () {
      let cloudinaryId = this.get('cloudinaryId');
      if (cloudinaryId) {
        let config = Ember.getOwner(this).resolveRegistration('config:environment');
        let {
          cloudinary: {
            cloudName
          }
        } = config;
        let cloudinaryId = this.get('cloudinaryId');
        let imageWidth = this.get('width');
        let imageHeight = this.get('height');
        let crop = this.get('crop');
        let grayscale = this.get('grayscale');
        let qAuto = this.get('q_auto');
        let flLossy = this.get('fl_lossy');
        let imageFormat = this.get('imageFormat');
        let dprAuto = this.get('dpr_auto');
        let fAuto = this.get('f_auto');
        let background = this.get('background');
        let url = (0, _cloudinaryUtils.buildUrl)(cloudinaryId, cloudName, imageWidth, imageHeight, crop, grayscale, imageFormat, dprAuto, qAuto, flLossy, fAuto, background);
        this._setSrc(url);
        return url;
      }
    }),
    // helpers
    _setSrc(src) {
      let image = new Image();
      let image2 = new Image();
      // TODO: Do this on the component rather than two Image classes

      let onload = Ember.run.bind(this, () => {
        image2.src = src;
        Ember.trySet(this, 'src', src);
      });
      image2.addEventListener('load', () => {
        if (image2.complete) {
          let imageRenderTracker = this.get('imageRenderTracker');
          imageRenderTracker.imageRendered();
        }
      });
      image.onload = onload;
      image.src = src;
    }
  });
  CloudinaryImageComponent.reopenClass({
    positionalParams: ['cloudinaryId']
  });
  var _default = _exports.default = CloudinaryImageComponent;
});