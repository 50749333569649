define("soofa-client-shared/components/cloudinary-image-svg/component", ["exports", "soofa-client-shared/utils/cloudinary-utils"], function (_exports, _cloudinaryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CloudinaryImageSvgComponent = Ember.Component.extend({
    // service
    imageRenderTracker: Ember.inject.service(),
    // attributes
    tagName: 'img',
    attributeBindings: ['src', 'style', 'width', 'height', 'alt'],
    cloudinaryId: null,
    grayscale: false,
    crop: 'lpad',
    height: '1000',
    width: '1000',
    // setup
    didReceiveAttrs() {
      this.get('url');
    },
    style: Ember.computed('cssStyle', function () {
      return this.get('cssStyle');
    }),
    alt: Ember.computed('imgAlt', function () {
      return this.get('imgAlt');
    }),
    // computed
    url: Ember.computed('cloudinaryId', 'imageWidth', 'imageHeight', 'dpr_auto', 'crop', 'grayscale', 'imageFormat', 'q_auto', 'fl_lossy', 'f_auto', function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      let {
        cloudinary: {
          cloudName
        }
      } = config;
      let cloudinaryId = this.get('cloudinaryId');
      let imageWidth = this.get('width');
      let imageHeight = this.get('height');
      let crop = this.get('crop');
      let grayscale = this.get('grayscale');
      let imageSize = this.get('imageSize');
      let qAuto = this.get('q_auto');
      let flLossy = this.get('fl_lossy');
      let imageFormat = this.get('imageFormat');
      let dprAuto = this.get('dpr_auto');
      let fAuto = this.get('f_auto');
      let url = (0, _cloudinaryUtils.buildUrl)(cloudinaryId, cloudName, imageWidth, imageHeight, crop, grayscale, imageSize, imageFormat, dprAuto, qAuto, flLossy, fAuto);
      this._setHref(url);
      return url;
    }),
    // helpers
    _setHref(url) {
      let image = new Image();
      Ember.trySet(this, 'src', url);
      image.addEventListener('load', () => {
        if (image.complete) {
          let imageRenderTracker = this.get('imageRenderTracker');
          imageRenderTracker.imageRendered();
        }
      });
      image.src = url;
    }
  });
  CloudinaryImageSvgComponent.reopenClass({
    positionalParams: ['cloudinaryId']
  });
  var _default = _exports.default = CloudinaryImageSvgComponent;
});