define("soofa-client-shared/helpers/ember-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberObject = emberObject;
  function emberObject(params, hash) {
    return new Ember.Object(hash);
  }
  var _default = _exports.default = Ember.Helper.helper(emberObject);
});