define("soofa-client-shared/helpers/transition-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    // computed
    router: Ember.computed('', function () {
      return Ember.getOwner(this).lookup('router:main');
    }),
    compute([routeName, ...params]) {
      let router = this.get('router');
      (false && !(router) && Ember.assert('[ember-transition-helper] Unable to lookup router', router));
      return function (...invocationArgs) {
        let args = params.concat(invocationArgs);
        let transitionArgs = params.length ? [routeName, ...params] : [routeName];
        router.transitionTo(...transitionArgs);
        return args;
      };
    }
  });
});