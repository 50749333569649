define("@soofa/soofa-shared-client-data/models/device", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    activeDiagnostics: attr('boolean'),
    actionTaken: attr('string'),
    apId: attr('string'),
    asanaLink: attr('string'),
    asanaLinkDate: attr('number'),
    deviceType: attr('string', {
      defaultValue: 'Databoard'
    }),
    displayVersion: attr('string'),
    firmwareVersion: attr('string'),
    healthMessageProcessingTime: attr('number'),
    isStale: attr('boolean'),
    installDate: attr('number'),
    lastWorkerRan: attr('number'),
    lastwifiMessageSeen: attr('number'),
    lasthealthMessageSeen: attr('number'),
    lastErrorMessageSeen: attr('number'),
    lastErrorMessageCode: attr('string'),
    lastErrorMessageReason: attr('string'),
    manualDiagnosis: attr('string'),
    noEnclosure: attr('boolean', {
      defaultValue: true
    }),
    noProduct: attr('boolean', {
      defaultValue: true
    }),
    notes: attr('string'),
    port1ChargingDuration: attr('number'),
    port2ChargingDuration: attr('number'),
    priority: attr('number'),
    processingTime: attr('number'),
    searchIndex: attr('string'),
    simActivated: attr('boolean', {
      defaultValue: false
    }),
    simCarrier: attr('string'),
    simId: attr('string'),
    solution: attr('string'),
    staleDate: attr('number'),
    staleDiagnosis: attr('string'),
    wifiProcessingActive: attr('boolean'),
    wifiProcessingIdle: attr('boolean'),
    wifiScanningActivated: attr('boolean', {
      defaultValue: true
    }),
    // calibration settings
    batteryVoltageCalibration: attr('number'),
    lightingCurrentCalibration: attr('number'),
    solarVoltageCalibration: attr('number'),
    solarCurrentCalibration: attr('number'),
    // wifi processing setup attrs
    retainActivitySessions: attr('boolean'),
    testing: attr('boolean', {
      defaultValue: true
    }),
    // realtime health attrs
    batteryVoltage: attr('number'),
    batteryCurrent: attr('number'),
    lightingCurrent: attr('number'),
    solarVoltage: attr('number'),
    solarCurrent: attr('number'),
    // visionect attrs
    batteryModifier: attr('number'),
    solarModifier: attr('number'),
    visionectPolling: attr('boolean'),
    visionectState: attr('string'),
    // associations
    enclosure: belongsTo('enclosure'),
    instance: belongsTo('instance'),
    workOrder: belongsTo('workOrder'),
    product: belongsTo('product', {
      inverse: null
    }),
    sim: belongsTo('sim')
  });
});