define("soofa-client-shared/components/campaign-featured/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TRSTE2uY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-wrap\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-20 layout-row layout-align-center-center\"],[9],[0,\"\\n    \"],[1,[27,\"paper-icon\",[\"star\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-80\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"Featured\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"paper-checkbox\",null,[[\"value\",\"onChange\"],[[23,[\"model\",\"featured\"]],[27,\"action\",[[22,0,[]],\"updateFeatured\",[23,[\"model\"]]],null]]],{\"statements\":[[4,\"if\",[[23,[\"model\",\"featured\"]]],null,{\"statements\":[[0,\"          Viewable on \\\"Explore\\\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Not included on \\\"Explore\\\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/campaign-featured/template.hbs"
    }
  });
});