define("soofa-client-shared/components/sign-card/component", ["exports", "soofa-client-shared/components/sign-card/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // attributes
    layout: _template.default,
    classNames: ['sign-card'],
    // properties
    model: null
  });
});