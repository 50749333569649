define("ember-libphonenumber-js/helpers/format-phonenumber", ["exports", "libphonenumber-js"], function (_exports, _libphonenumberJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPhoneNumber = formatPhoneNumber;
  const NATIONAL = 'National';
  const INTERNATIONAL = 'International';
  function formatPhoneNumber(params, hash) {
    let [number, countryCode] = params;
    let forceIntl = hash.forceIntl || false;
    if (!countryCode) {
      return number;
    }
    try {
      let parsedNumber = (0, _libphonenumberJs.parse)(number, countryCode);
      if (!Ember.isEmpty(parsedNumber.phone)) {
        let formatType = parsedNumber.country === countryCode ? NATIONAL : INTERNATIONAL;
        return (0, _libphonenumberJs.format)(parsedNumber, forceIntl ? INTERNATIONAL : formatType);
      }
      return number;
    } catch (e) {
      return number;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(formatPhoneNumber);
});