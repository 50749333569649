define("@soofa/soofa-shared-client-data/models/submission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    active: attr('boolean'),
    content: attr('string'),
    phoneNumber: attr('string'),
    referralId: attr('string'),
    slackTimestamp: attr('number'),
    slackChannel: attr('string'),
    timestamp: attr('number'),
    // associations
    instance: belongsTo('instance'),
    location: belongsTo('location'),
    poll: belongsTo('poll'),
    user: belongsTo('user')
  });
});