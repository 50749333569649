define("soofa-client-shared/components/child-scaler/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // properties
    childModel: null,
    // computed
    childActualWidth: Ember.computed.alias('childModel.width'),
    childActualHeight: Ember.computed.alias('childModel.height'),
    // events
    didRender() {
      let containerWidth = this.$().parent().width();
      let containerHeight = this.$().parent().height();
      let childActualWidth = this.get('childActualWidth');
      let childActualHeight = this.get('childActualHeight');
      let ratio, childScaledHeight, childScaledWidth;
      if (containerHeight < containerWidth) {
        ratio = containerHeight / childActualHeight;
        childScaledWidth = childActualWidth * ratio;
        if (childScaledWidth > containerWidth) {
          ratio = containerWidth / childActualWidth;
          childScaledWidth = childActualWidth * ratio;
        }
        childScaledHeight = childActualHeight * ratio;
      } else {
        ratio = containerWidth / childActualWidth;
        childScaledHeight = childActualHeight * ratio;
        if (childScaledHeight > containerHeight) {
          ratio = containerHeight / childActualHeight;
          childScaledHeight = childActualHeight * ratio;
        }
        childScaledWidth = childActualWidth * ratio;
      }

      // set dimensions on child
      let child = this.$().children().first();
      child.css('-webkit-transform', `scale(${ratio},${ratio})`);
      child.css('-webkit-transform-origin', '0 0');
      this.$().css('height', childScaledHeight);
      this.$().css('width', childScaledWidth);
    }
  });
});