define("soofa-client-shared/components/polling-list/component", ["exports", "soofa-client-shared/components/polling-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // services
    scriptr: Ember.inject.service(),
    // attributes
    layout: _template.default,
    // properties
    model: null,
    pollIndexRoute: 'polling.poll.index',
    // computed
    columns: Ember.computed(function () {
      return [{
        name: 'Questions',
        valuePath: 'poll.question',
        width: 100,
        isSortable: true
      }, {
        name: 'Start date',
        valuePath: 'poll.startDate',
        width: 100,
        isSortable: true
      }, {
        name: 'Neighborhoods',
        valuePath: 'neighborhoods',
        width: 100,
        isSortable: false
      }, {
        name: 'Actions',
        width: 20,
        isSortable: false
      }];
    }),
    // events
    async didReceiveAttrs() {
      let newSorts = [{
        valuePath: 'startDate',
        isAscending: false
      }];
      this.set('sorts', newSorts);
      let model = this.get('model');
      if (model) {
        let formattedModel = await this.formatData(model);
        this.set('formattedModel', formattedModel);
      }
    },
    async formatData(polls) {
      return polls.map(poll => {
        return poll.get('neighborhoods').then(neighborhoods => {
          let neighborhoodsList = [];
          neighborhoods.forEach(neighborhood => {
            neighborhoodsList.push(neighborhood.get('name'));
          });
          return {
            poll,
            neighborhoods: neighborhoodsList.length > 3 ? this.generateNeighborhoodsDisplay(neighborhoodsList) : neighborhoodsList.join(', ')
          };
        });
      });
    },
    generateNeighborhoodsDisplay(neighborhoodsList) {
      let count = neighborhoodsList.length;
      let maxSize = 3;
      let neighborhoodDisplay = neighborhoodsList.slice(0, maxSize).join(', ');
      return `${neighborhoodDisplay} + ${count - maxSize}  more`;
    }
  });
});