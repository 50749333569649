define("@soofa/soofa-shared-client-data/models/enclosure", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    apId: attr('string'),
    customer: attr('string'),
    electricalEnclosureVersion: attr('string'),
    enclosureId: attr('string'),
    noCustomer: attr('boolean', {
      defaultValue: true
    }),
    noProduct: attr('boolean', {
      defaultValue: true
    }),
    powerboardVersion: attr('string'),
    product: belongsTo('product'),
    searchIndex: attr('string'),
    sensor: belongsTo('device'),
    solarChargeControllerVersion: attr('string'),
    solarConnectorVersion: attr('string'),
    wifiDaughterBoardVersion: attr('string')
  });
});