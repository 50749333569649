define("soofa-client-shared/mixins/handles-session", ["exports", "soofa-client-shared/mixins/has-me"], function (_exports, _hasMe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_hasMe.default, {
    // services
    keen: Ember.inject.service(),
    config: Ember.inject.service(),
    // events
    handleSession() {
      let meService = this.get('meService');
      let user = this.get('meService.model');
      if (user) {
        let customer = user.get('customer');
        this._recordKeenSession(customer);
      } else {
        // create 'visit' keen event
        let referralId = (0 | Math.random() * 9e6).toString(36);
        let referralSource = 'visit';
        let {
          config
        } = this.get('config');
        meService.setProperties({
          referralId,
          referralSource
        });
        this.get('keen').recordEvent('visit', {
          id: referralId,
          application: config.modulePrefix
        });
      }
    },
    // helpers
    _recordKeenSession(customer) {
      let {
        config
      } = this.get('config');
      this.get('keen').recordEvent('session', {
        application: config.modulePrefix,
        customer
      });
    }
  });
});