define("@soofa/soofa-shared-client-data/models/schedule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    startHour: attr('number'),
    endHour: attr('number'),
    // days of the week
    monday: attr('boolean', {
      defaultValue: false
    }),
    tuesday: attr('boolean', {
      defaultValue: false
    }),
    wednesday: attr('boolean', {
      defaultValue: false
    }),
    thursday: attr('boolean', {
      defaultValue: false
    }),
    friday: attr('boolean', {
      defaultValue: false
    }),
    saturday: attr('boolean', {
      defaultValue: false
    }),
    sunday: attr('boolean', {
      defaultValue: false
    }),
    // associations
    appletSchedule: belongsTo('appletSchedule')
  });
});