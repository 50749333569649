define("ember-paper/components/paper-virtual-repeat-scroller", ["exports", "ember-paper/templates/components/paper-virtual-repeat-scroller"], function (_exports, _paperVirtualRepeatScroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _paperVirtualRepeatScroller.default,
    classNames: ['md-virtual-repeat-scroller'],
    didInsertElement() {
      this._super(...arguments);
      this._onScroll = e => {
        this.get('onScroll')(e);
      };
      this.element.addEventListener('scroll', this._onScroll);
    },
    willDestroyElement() {
      this._super(...arguments);
      this.element.removeEventListener('scroll', this._onScroll);
      this._onScroll = null;
    }
  });
});