define("@soofa/soofa-shared-client-data/models/format", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    width: attr('number'),
    height: attr('number'),
    grayscale: attr('boolean'),
    layoutType: attr('string'),
    // computed
    displayName: Ember.computed('width', 'height', 'grayscale', 'layoutType', function () {
      let width = this.get('width');
      let height = this.get('height');
      let grayscale = this.get('grayscale') ? ' Grayscale ' : ' ';
      let layoutType = this.get('layoutType');
      return `${width} x ${height}${grayscale}${layoutType}`;
    }),
    // relationships
    layouts: hasMany('layout')
  });
});