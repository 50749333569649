define("ember-libphonenumber-js/helpers/is-valid-phonenumber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isValidPhoneNumber = isValidPhoneNumber;
  // import { isValidNumber } from 'libphonenumber-js'

  function isValidPhoneNumber(params /*, hash*/) {
    return params;
  }
  var _default = _exports.default = Ember.Helper.helper(isValidPhoneNumber);
});