define("soofa-client-shared/components/soofa-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "o6Zb0s0b",
    "block": "{\"symbols\":[\"mapBoxMap\",\"&default\"],\"statements\":[[4,\"mapbox-gl\",null,[[\"mapLoaded\",\"initOptions\"],[[27,\"if\",[[23,[\"mapLoaded\"]],[23,[\"mapLoaded\"]],[27,\"action\",[[22,0,[]],\"emptyMapLoaded\"],null]],null],[27,\"hash\",null,[[\"style\",\"center\",\"zoom\",\"maxZoom\",\"minZoom\",\"pitchWithRotate\",\"dragRotate\",\"interactive\",\"attributionControl\"],[[27,\"if\",[[23,[\"style\"]],[23,[\"style\"]],[23,[\"defaultStyle\"]]],null],[23,[\"initialCenter\"]],[27,\"if\",[[23,[\"initialZoom\"]],[23,[\"initialZoom\"]],[23,[\"defaultInitialZoom\"]]],null],[27,\"if\",[[23,[\"maxZoom\"]],[23,[\"maxZoom\"]],[23,[\"defaultMaxZoom\"]]],null],[27,\"if\",[[23,[\"minZoom\"]],[23,[\"minZoom\"]],[23,[\"defaultMinZoom\"]]],null],[23,[\"pitchWithRotate\"]],[23,[\"dragRotate\"]],[27,\"if\",[[23,[\"interactive\"]],[23,[\"interactive\"]],[23,[\"defaultInteractive\"]]],null],[23,[\"attributionControl\"]]]]]]],{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[22,1,[\"image\"]],[23,[\"imageName\"]],[23,[\"image\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"showNavigationControl\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[22,1,[\"control\"]],[23,[\"navigationControl\"]],[23,[\"navigationPosition\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/soofa-map/template.hbs"
    }
  });
});