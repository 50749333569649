define("soofa-client-shared/utils/object-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toPojo = _exports.keys = void 0;
  const {
    keys
  } = Object;
  _exports.keys = keys;
  let toPojo = function (emberObject) {
    return emberObject.getProperties(keys(emberObject));
  };
  _exports.toPojo = toPojo;
});