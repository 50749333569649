define("soofa-client-shared/components/soofa-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1MlL2cuV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"showConfirmModal\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"containerClass\",\"hasOverlay\",\"translucentOverlay\",\"onClose\"],[[27,\"if\",[[23,[\"containerClass\"]],[23,[\"containerClass\"]],\"modalContent\"],null],true,70,[27,\"action\",[[22,0,[]],[23,[\"actionModal\"]]],null]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/soofa-modal/template.hbs"
    }
  });
});