define("@soofa/soofa-shared-client-data/models/voice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    sharePercentage: attr('number'),
    // relationships
    lineItem: belongsTo('lineItem'),
    location: belongsTo('location'),
    neighborhood: belongsTo('neighborhood')
  });
});