define("ember-modal-dialog/templates/components/deprecated-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TzeVBAbi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[23,[\"destinationElementId\"]],[23,[\"renderInPlace\"]]]],{\"statements\":[[4,\"if\",[[23,[\"hasOverlay\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[21,\"overlayClassNamesString\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]],null]],[11,\"tabindex\",\"-1\"],[11,\"data-emd-overlay\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[23,[\"renderInPlace\"]]],null,{\"statements\":[[4,\"ember-modal-dialog-positioned-container\",null,[[\"class\",\"targetAttachment\",\"target\",\"renderInPlace\"],[[23,[\"containerClassNamesString\"]],[23,[\"targetAttachment\"]],[23,[\"target\"]],[23,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"ember-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\"],[[23,[\"containerClassNamesString\"]],[23,[\"target\"]],[23,[\"attachment\"]],[23,[\"targetAttachment\"]],[23,[\"targetModifier\"]],[23,[\"tetherClassPrefix\"]],[23,[\"offset\"]],[23,[\"targetOffset\"]],[23,[\"constraints\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/deprecated-tether-dialog.hbs"
    }
  });
});