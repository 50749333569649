define('ember-changeset-validations/utils/validation-errors', ['exports', 'ember-get-config', 'ember-changeset-validations/utils/get-messages'], function (exports, _emberGetConfig, _getMessages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = buildMessage;
  function buildMessage(key, result) {
    let returnsRaw = Ember.getWithDefault(_emberGetConfig.default, 'changeset-validations.rawOutput', false);
    let messages = (0, _getMessages.default)();

    let description = messages.getDescriptionFor(key);

    if (result.message) {
      return result.message;
    }

    let { type, value, context = {} } = result;

    if (context.message) {
      let message = context.message;

      if (Ember.typeOf(message) === 'function') {
        let builtMessage = message(key, type, value, context);
        (false && !(Ember.typeOf(builtMessage) === 'string') && Ember.assert('Custom message function must return a string', Ember.typeOf(builtMessage) === 'string'));


        return builtMessage;
      }

      return messages.formatMessage(message, Ember.assign({ description }, context));
    }

    let message = Ember.get(messages, type);
    if (returnsRaw) {
      context = Ember.assign({}, context, { description });
      return { value, type, message, context };
    } else {
      return messages.formatMessage(message, Ember.assign({ description }, context));
    }
  }
});