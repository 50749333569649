define("soofa-client-shared/components/button-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7sbwIUnY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"triggered\"]]],null]],null,{\"statements\":[[4,\"paper-button\",null,[[\"disabled\",\"primary\",\"raised\",\"onClick\"],[[23,[\"disabled\"]],[23,[\"primary\"]],[23,[\"raised\"]],[27,\"action\",[[22,0,[]],\"execute\"],null]]],{\"statements\":[[0,\"    \"],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[1,[27,\"paper-icon\",[[23,[\"icon\"]]],null],false]],\"parameters\":[]},null],[0,\" \"],[1,[21,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"textConfirmOrCancelDescription\"],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-row layout-wrap\"],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"onClick\",\"class\",\"raised\",\"primary\"],[[27,\"action\",[[22,0,[]],\"cancel\"],null],\"button-small\",false,false]],{\"statements\":[[0,\"      \"],[1,[27,\"paper-icon\",[[23,[\"iconCancel\"]]],null],false],[0,\" \"],[1,[21,\"textCancel\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-button\",null,[[\"onClick\",\"class\",\"raised\",\"primary\"],[[27,\"action\",[[22,0,[]],\"confirm\"],null],\"button-small button-confirm\",true,true]],{\"statements\":[[0,\"      \"],[1,[27,\"paper-icon\",[[23,[\"iconConfirm\"]]],null],false],[0,\" \"],[1,[21,\"textConfirm\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/button-confirm/template.hbs"
    }
  });
});