define("@soofa/soofa-shared-client-data/models/ad", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    active: attr('boolean'),
    archived: attr('boolean'),
    adType: attr('string'),
    assetUrl: attr('string'),
    bodyText: attr('string'),
    brandName: attr('string'),
    callToAction: attr('string'),
    cloudinaryId: attr('string'),
    cloudinaryLogoId: attr('string'),
    contentQualityRating: attr('number'),
    createdAt: attr('number', {
      defaultValue: Date.now()
    }),
    cropMode: attr('string'),
    eventEndTimeStamp: attr('number'),
    eventName: attr('string'),
    eventTimeStamp: attr('number'),
    eventLocationName: attr('string'),
    footerText: attr('string'),
    headerText: attr('string'),
    lastPlay: attr('number'),
    lifetimeCost: attr('number'),
    lifetimeDuration: attr('number'),
    locationRelevanceRating: attr('number'),
    needsReview: attr('boolean'),
    ppsRuntimeMins: attr('number'),
    ppsScans: attr('number'),
    ppsScore: attr('number'),
    preview: attr('string'),
    priority: attr('number'),
    reviewed: attr('boolean'),
    rejected: attr('boolean'),
    signature: attr('string'),
    socialMediaFacebook: attr('string'),
    socialMediaInstagram: attr('string'),
    socialMediaTwitter: attr('string'),
    subText: attr('string'),
    qrRedirectUrl: attr('string'),
    qrHeight: attr('string'),
    qrWidth: attr('string'),
    qrXPos: attr('string'),
    qrYPos: attr('string'),
    qrLightColor: attr('string'),
    qrDarkColor: attr('string'),
    // associations
    campaign: belongsTo('campaign', {
      inverse: null
    }),
    customer: belongsTo('customer'),
    layout: belongsTo('layout'),
    reviewCampaign: belongsTo('campaign', {
      inverse: null
    }),
    user: belongsTo('user', {
      inverse: null
    })
  });
});