define("soofa-client-shared/components/event-card/component", ["exports", "soofa-client-shared/components/event-card/template", "soofa-client-shared/mixins/has-me", "ember-changeset", "soofa-client-shared/validations/event", "ember-changeset-validations"], function (_exports, _template, _hasMe, _emberChangeset, _event, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EventCardComponent = Ember.Component.extend(_hasMe.default, {
    // properties
    layout: _template.default,
    showOwner: false,
    changeset: null,
    // attributes
    classNames: ['event-card'],
    // services
    geohash: Ember.inject.service(),
    // events
    didInsertElement() {
      let model = this.get('model');
      let locationName = model.get('locationName');
      this.set('addressString', model.get('addressString'));
      this.set('locationName', locationName);
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_event.default), _event.default);
      changeset.validate();
      this.set('changeset', changeset);
    },
    // actions
    actions: {
      async editEvent() {
        let model = this.get('model');
        let changeset = this.get('changeset');
        if (changeset.get('isValid')) {
          // set date only if exists
          if (changeset.get('date')) {
            // gets the start date in gmt time
            let eventDate = changeset.get('date');

            // adding timezone offset
            let dateTimezone = new Date(eventDate);
            eventDate += dateTimezone.getTimezoneOffset();

            // calculate the miliseconds from time selection
            let eventTime = changeset.get('time') * 60 * 1000; // minutes to seconds to miliseconds
            let eventDateTime = eventDate + eventTime;
            model.set('dateTime', eventDateTime);
          }

          // set date only if exists
          if (changeset.get('endDate')) {
            // get end date in gmt time
            let eventEndDate = changeset.get('endDate');

            // adding timezone offset
            let endDateTimezone = new Date(eventEndDate);
            eventEndDate += endDateTimezone.getTimezoneOffset();

            // calculate the miliseconds from time selection
            let eventEndTime = changeset.get('endTime') * 60 * 1000; // minutes to seconds to miliseconds
            let eventEndDateTime = eventEndDate + eventEndTime;
            model.set('endDateTime', eventEndDateTime);
          }

          // setting event properties and saving
          model.setProperties({
            'name': changeset.get('name'),
            'locationName': changeset.get('locationName'),
            'geohash': changeset.get('geohash'),
            'lat': changeset.get('lat'),
            'lng': changeset.get('lng'),
            'flag': changeset.get('name').length > 51 || changeset.get('locationName').length > 55 ? true : false
          });
          await model.save();
        }
      },
      async deleteEvent() {
        // get user or customer events and delete current event from there
        let event = this.get('model');
        let customer = await event.get('customer');
        if (customer) {
          let customerEvents = await customer.get('events');
          customerEvents.removeObject(event);
          customer.save();
        } else {
          let user = await event.get('user');
          let userEvents = await user.get('events');
          userEvents.removeObject(event);
          user.save();
        }

        // finally delete event
        event.destroyRecord();
      },
      async removeImage(event) {
        event.set('image', null);
        event.save();
      },
      placeChanged(locationName) {
        let changeset = this.get('changeset');
        changeset.set('locationName', locationName);
        changeset.validate();
        if (!locationName) {
          this.set('locationError', 'There can not be an event without a location name!');
        } else if (locationName.length > 50) {
          this.set('locationError', 'Location name cannot be more than 50 characters!');
        } else {
          this.set('locationError', '');
        }
      }
    }
  });
  EventCardComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = _exports.default = EventCardComponent;
});