define("@soofa/soofa-location/services/geolocation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise
  } = Ember.RSVP;
  var _default = _exports.default = Ember.Service.extend({
    // attributes
    promise: null,
    resolvePromise: null,
    rejectPromise: null,
    lat: null,
    lng: null,
    accuracy: null,
    failsafeID: null,
    watchId: null,
    // events
    init() {
      this._super(...arguments);
      // this.setupLocation();
      this.set('promise', new Promise((resolve, reject) => {
        this.set('resolvePromise', resolve);
        this.set('rejectPromise', reject);
      }));
    },
    // helpers
    setupLocation() {
      let firstPositionSuccess = Ember.run.bind(this, this.firstPositionSuccess);
      let firstPositionError = Ember.run.bind(this, this.firstPositionError);
      let setPosition = Ember.run.bind(this, this.setPosition);
      this.clearWatch();
      let watchId = navigator.geolocation.watchPosition(setPosition, this.error, {
        enableHighAccuracy: true
      });
      navigator.geolocation.getCurrentPosition(firstPositionSuccess, firstPositionError, {
        timeout: 20000,
        maximumAge: 0,
        enableHighAccuracy: true
      });
      this.set('failsafeID', this.setFailsafeInterval());
      this.set('watchId', watchId);
    },
    clearWatch() {
      let watchId = this.get('watchId');
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    },
    setFailsafeInterval() {
      let failsafeFail = Ember.run.bind(this, this.failsafeFail);
      return setInterval(failsafeFail, 7000);
    },
    failsafeFail() {
      this.get('rejectPromise')('Your location request timed out. Try again.');
    },
    clearFailsafe() {
      clearInterval(this.get('failsafeID'));
    },
    setPosition(position) {
      this.set('lat', position.coords.latitude);
      this.set('lng', position.coords.longitude);
      this.set('accuracy', position.coords.accuracy);
    },
    firstPositionSuccess(position) {
      this.clearFailsafe();
      this.setPosition(position);
      this.get('resolvePromise')();
    },
    firstPositionError(error) {
      this.clearFailsafe();
      this.get('rejectPromise')(this.error(error));
    },
    error(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          return 'You denied the request for your location or your current settings have blocked geolocation access completely. You will have to change or reset your browser or system settings to be able to re-confirm. There is no way to continue without doing this.';
        case error.POSITION_UNAVAILABLE:
          return 'Your location information is unavailable. Please make sure your GPS and/or WiFi radios are enabled. If they already are, it may not be able to get your location at this time. Please try again in a different location.';
        case error.TIMEOUT:
          return 'The request for your location timed out. Please make sure your GPS and/or WiFi radios are enabled. If they already are, it may not be able to get your location at this time. Please try again in a different location and/or reset your browser or system settings to be able to re-confirm. There is no way to continue without doing this.';
        case error.UNKNOWN_ERROR:
          return 'An unknown error occurred. Please make sure your GPS and/or WiFi radios are enabled. If they already are, it may not be able to get your location at this time. Please try again in a different location or try restarting the application.';
        default:
          return 'An very unknown error occurred. Please make sure your GPS and/or WiFi radios are enabled. If they already are, it may not be able to get your location at this time. Please try again in a different location or try restarting the application.';
      }
    },
    getObject() {
      let lat = this.get('lat');
      let lng = this.get('lng');
      return {
        lat,
        lng
      };
    },
    getEmberObject() {
      return new Ember.Object(this.getObject());
    },
    getArray() {
      let lat = this.get('lat');
      let lng = this.get('lng');
      return [lat, lng];
    }
  });
});