define("soofa-client-shared/components/creative-uploader/component", ["exports", "ember-concurrency", "soofa-client-shared/components/creative-uploader/template"], function (_exports, _emberConcurrency, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    fileQueue: Ember.inject.service(),
    cloudinary: Ember.inject.service(),
    config: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['dropzone'],
    layout: _template.default,
    layouts: null,
    save: null,
    queue() {
      return this.get('fileQueue').findOrCreate('creatives');
    },
    // eslint-disable-next-line require-yield
    uploadImage: (0, _emberConcurrency.task)(function* (file) {
      let cloudinary = this.get('cloudinary');
      let {
        config
      } = this.get('config');
      let layouts = this.get('layouts');
      let uploadPreset = config.cloudinary.adUploadPreset;
      let fd = new FormData();
      fd.append('file', file.blob);
      fd.append('upload_preset', uploadPreset);
      cloudinary.uploadImage(fd).then(cloudinaryId => {
        let model = this.get('model');
        let newAd = new Ember.Object({
          adType: 'Image',
          createdAt: new Date().valueOf(),
          cropMode: 'fill',
          cloudinaryId,
          layout: layouts.filterBy('layoutType', 'Image').get('firstObject'),
          reviewed: model.get('customerType') === 'Landowner'
        });
        newAd.customer = model;
        let ad = this.get('store').createRecord('ad', newAd);
        ad.save();
        let modelAds = model.get('ads');
        modelAds.pushObject(ad);
        model.save();
        if (this.save) {
          this.save(ad);
        }
      }).catch(error => {
        alert(error);
        return error;
      });
    }).maxConcurrency(3).enqueue(),
    actions: {
      async uploadImage(file) {
        this.get('uploadImage').perform(file);
      }
    }
  });
});