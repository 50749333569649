define("@soofa/soofa-shared-client-data/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    acceptedLocation: attr('boolean'),
    accountType: attr('string', {
      defaultValue: 'Free'
    }),
    admin: attr('boolean'),
    appSource: attr('string'),
    badgeFirstGoLive: attr('boolean'),
    badgeFirstBuzz: attr('boolean'),
    badgeFirstImpressions: attr('boolean'),
    billingDay: attr('number'),
    businessName: attr('string'),
    buzzCount: attr('number', {
      defaultValue: 0
    }),
    campaignLimit: attr('number', {
      defaultValue: null
    }),
    canReview: attr('boolean', {
      defaultValue: false
    }),
    createdAt: attr('number', {
      defaultValue: new Date().valueOf()
    }),
    devopsGoogleAcctIndex: attr('number', {
      defaultValue: 1
    }),
    didAnswerQuestionnaire: attr('boolean'),
    email: attr('string'),
    eventPayload: attr('string'),
    featureFlag: attr('boolean'),
    featureFlag2: attr('boolean'),
    firstName: attr('string'),
    firstImpressions: attr('boolean'),
    firstGoLive: attr('boolean'),
    firstBuzz: attr('boolean', {
      defaultValue: false
    }),
    firstCampaignRanNotification: attr('boolean'),
    // flag used only in backend
    hideDataPortalHints: attr('boolean'),
    hideBuzz: attr('boolean', {
      defaultValue: false
    }),
    impressionsThisMonth: attr('number'),
    lastName: attr('string'),
    lastSessionAt: attr('number'),
    monthlyOptOut: attr('boolean'),
    neighborhoodLimit: attr('number'),
    operations: attr('boolean', {
      defaultValue: false
    }),
    pausedAt: attr('number'),
    pausedMonths: attr('number'),
    photoProfile: attr('string'),
    photoURL: attr('string'),
    phoneNumber: attr('string'),
    postPayload: attr('string'),
    priority: attr('number', {
      defaultValue: 2
    }),
    questionnaireAnswer: attr('string'),
    receivingEmails: attr('boolean', {
      defaultValue: true
    }),
    referralSource: attr('string'),
    referralId: attr('string'),
    super: attr('boolean'),
    uid: attr('string'),
    // associations
    ads: hasMany('ad'),
    activeCampaigns: hasMany('campaign', {
      inverse: null
    }),
    campaigns: hasMany('campaign', {
      inverse: 'user'
    }),
    card: belongsTo('card'),
    charges: hasMany('charge'),
    cityPreference: belongsTo('city', {
      inverse: null
    }),
    customer: belongsTo('customer'),
    neighborhoods: hasMany('neighborhood'),
    referrals: hasMany('referral', {
      inverse: null
    }),
    submissions: hasMany('submission'),
    // game
    game: belongsTo('game'),
    hasGame: attr('boolean'),
    // computed
    displayName: Ember.computed('firstName', 'lastName', function () {
      let firstName = this.get('firstName') ? this.get('firstName').charAt(0).toUpperCase() + this.get('firstName').slice(1) : null;
      let lastName = this.get('lastName') ? this.get('lastName').charAt(0).toUpperCase() + this.get('lastName').slice(1) : null;
      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      } else if (firstName && !lastName) {
        return `${firstName}`;
      } else if (!firstName && lastName) {
        return `${lastName}`;
      } else {
        return null;
      }
    })
  });
});