define("@soofa/soofa-shared-client-data/models/newsfeed", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    geohash: attr('string'),
    newsId: attr('string'),
    published: attr('string'),
    subtitle: attr('string'),
    title: attr('string'),
    url: attr('string'),
    urlQr: attr('string'),
    // associations
    contents: hasMany('content'),
    customer: belongsTo('customer'),
    neighborhood: belongsTo('neighborhood'),
    user: belongsTo('user')
  });
});