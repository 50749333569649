define("@soofa/soofa-shared-client-data/models/content", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // ad
    ad: belongsTo('ad'),
    campaign: belongsTo('campaign'),
    instanceId: attr('string'),
    // business
    activeSlot: belongsTo('slot'),
    businesses: hasMany('business'),
    // events
    events: hasMany('event'),
    // gnews
    newsTitle: attr('string'),
    newsDescription: attr('string'),
    newsSourceName: attr('string'),
    newsImage: attr('string'),
    newsPublishedAt: attr('number'),
    // image
    image: attr('string'),
    // instagram
    instagramId: attr('string'),
    instagramContent: attr('string'),
    instagramAuthor: attr('string'),
    instagramHandle: attr('string'),
    instagramTime: attr('number'),
    instagramImage: attr('string'),
    instagramImageWidth: attr('string'),
    instagramImageHeight: attr('string'),
    // rideshare
    rideshareName: attr('string'),
    rideshareCompany: attr('string'),
    rideshareDescription: attr('string'),
    rideshareAway: attr('number'),
    rideshareUpdate: attr('boolean'),
    rideshareUpdateTime: attr('number'),
    // transit
    tripDirection: attr('string'),
    tripHeadsign: attr('string'),
    tripModeName: attr('string'),
    tripRouteName: attr('string'),
    tripRouteNotice: attr('string'),
    tripAlert: attr('boolean'),
    tripAlertMessage: attr('string'),
    tripRefreshTime: attr('boolean'),
    tripUpdateTime: attr('number'),
    tripUpdate: attr('boolean'),
    tripTime: attr('number'),
    tripAway: attr('number'),
    tripSchedule: attr('string'),
    tripTime2: attr('number'),
    tripAway2: attr('number'),
    tripSchedule2: attr('string'),
    tripLive: attr('boolean'),
    // tweet
    tweet: belongsTo('tweet'),
    // water
    tideAmHighestDateString: attr('string'),
    tideAmHighestValue: attr('number'),
    tideAmLowestDateString: attr('string'),
    tideAmLowestValue: attr('number'),
    tideDate: attr('string'),
    tideInterval: attr('string'),
    tideNextFirstDateString: attr('string'),
    tideNextFirstValue: attr('number'),
    tideNextFirstType: attr('string'),
    tideNextSecondDateString: attr('string'),
    tideNextSecondValue: attr('number'),
    tideNextSecondType: attr('string'),
    tidePmHighestDateString: attr('string'),
    tidePmHighestValue: attr('number'),
    tidePmLowestDateString: attr('string'),
    tidePmLowestValue: attr('number'),
    tideStation: attr('number'),
    tideUnit: attr('string'),
    waterParameterCd: attr('string'),
    waterPeriod: attr('string'),
    waterResultsCurrent: attr('number'),
    waterResultsCurrentUpdatedAt: attr('number'),
    waterResultsPrevious: attr('number'),
    waterResultsPreviousUpdatedAt: attr('number'),
    waterResultsUnit: attr('string'),
    waterSites: attr('string'),
    waterType: attr('string'),
    // weather
    condition: attr('string'),
    conditionId: attr('string'),
    max: attr('number'),
    min: attr('number')
  });
});