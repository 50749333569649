define("soofa-client-shared/mixins/handles-auth", ["exports", "soofa-client-shared/mixins/has-me", "soofa-client-shared/mixins/handles-session"], function (_exports, _hasMe, _handlesSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_hasMe.default, _handlesSession.default, {
    // services
    authenticator: Ember.inject.service('authenticator'),
    checker: Ember.inject.service('checker'),
    // events
    async afterModel() {
      this._super();
      let session = this.get('session');
      if (session.get('isAuthenticated')) {
        let authUser = session.get('currentUser');
        let user = await this.get('checker').checkForModel('user', 'email', authUser.email);
        if (user) {
          await this.get('authenticator').setupMeService(user);
          this._updateUserModel(user);
          if (this.afterAuthSetup) {
            await this.afterAuthSetup();
          }
        } else {
          // log user out
        }
      }
      this.handleSession();
    },
    // actions
    actions: {
      accessDenied() {
        this.transitionTo('login');
      }
    },
    // helpers
    _updateUserModel(user) {
      user.set('lastSessionAt', Date.now());
      user.save();
    }
  });
});