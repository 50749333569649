define("soofa-client-shared/components/toaster-helper/component", ["exports", "soofa-client-shared/components/toaster-helper/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _template.default,
    // services
    toaster: Ember.inject.service(),
    // Asks service if toast should be shown
    show: Ember.computed.alias('toaster.show'),
    // Icon
    icon: Ember.computed.alias('toaster.icon'),
    // Msg
    msg: Ember.computed.alias('toaster.msg'),
    // Class
    color: Ember.computed.alias('toaster.color'),
    // Position
    position: Ember.computed.alias('toaster.position'),
    // Time
    time: Ember.computed.alias('toaster.time'),
    // actions
    actions: {
      closeToast() {
        // Updates service issue status
        this.get('toaster').set('show', false);
      }
    }
  });
});