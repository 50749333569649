define("soofa-client-shared/transforms/json", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;
  var _default = _exports.default = Transform.extend({
    serialize(value) {
      return JSON.stringify(value);
    },
    deserialize(value) {
      return JSON.parse(value);
    }
  });
});