define("soofa-client-shared/services/keen", ["exports", "soofa-client-shared/mixins/has-me"], function (_exports, _hasMe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(_hasMe.default, {
    config: Ember.inject.service(),
    // computed
    webReferrer: Ember.computed('', function () {
      return document.referrer;
    }),
    client: Ember.computed('', function () {
      let {
        config
      } = this.get('config');
      return new Keen(config.keen);
    }),
    query(queryType, queryParams) {
      let query = new Keen.Query(queryType, queryParams);
      let client = this.get('client');
      return client.run(query);
    },
    recordEvent(eventName, context, saveAsProperties, includeAddons = true) {
      let me = this.get('me');
      let meObject;
      if (me) {
        meObject = me.getProperties('displayName', 'id', 'email', 'createdAt', 'lastSessionAt', 'referralSource', 'referralId', 'accountType', 'buzzCount');
      } else {
        meObject = this.get('meService').getProperties('referralSource', 'referralId');
      }
      let eventData = {
        keen: {},
        ip_address: '${keen.ip}',
        // eslint-disable-line camelcase
        referrer: this.get('webReferrer'),
        agent: '${keen.user_agent}',
        me: meObject
      };
      if (includeAddons) {
        eventData.keen.addons = [{
          name: 'keen:ip_to_geo',
          input: {
            ip: 'ip_address'
          },
          output: 'ip_geo_info'
        }, {
          name: 'keen:ua_parser',
          input: {
            ua_string: 'agent' // eslint-disable-line camelcase
          },
          output: 'parsed_user_agent'
        }, {
          name: 'keen:date_time_parser',
          input: {
            date_time: 'keen.timestamp' // eslint-disable-line camelcase
          },
          output: 'timestamp_info'
        }];
      }

      // choose to save properties inside a context or just object properties
      if (saveAsProperties) {
        eventData = Object.assign(eventData, context);
      } else {
        eventData.context = context;
      }
      this.get('client').recordEvent(eventName, eventData);
    }
  });
});