define("soofa-client-shared/mixins/handles-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // designates which service to use, 'keen' or 'soofa'
    clientType: 'keen',
    result: null,
    // services
    keen: Ember.inject.service(),
    soofa: Ember.inject.service(),
    // API

    // required: _prepareChart(), _renderChart()
    async visualize(queryParams) {
      this._prepareChart(queryParams);
      let result = this.get('result');
      if (!result) {
        // an array of Keen.Query
        let queries = this._queries();
        if (queryParams) {
          queries = queries.map(function (query) {
            query.params.timeframe = queryParams.timeframe;
            query.params.interval = queryParams.interval;
            query.params.xAxisDateFormat = queryParams.xAxisDateFormat;
            return query;
          });
        }
        await this._getResult(queries);
      }
      await this._transformResult();
      this._renderChart();
      this.set('result', null);
    },
    // helpers
    async _getResult(queries) {
      let serviceName = this.get('clientType');
      let service = this.get(serviceName);
      let client = service.get('client');
      // make sure the Keen library has loaded
      await Keen.ready(function () {});
      // run the queries through the external api
      await client.run(queries).then(result => {
        this.set('result', result);
      }).catch(error => {
        this.set('error', error);
      });
    },
    async _transformResult() {
      let resultTransformation = this.get('resultTransformation');
      // transform the result if necessary
      if (resultTransformation) {
        let result = this.get('result');
        let newResult = await resultTransformation(result);
        this.set('result', newResult);
      }
    },
    _queries() {
      let children = this.get('childComponents');
      let queries = children.map(function (child) {
        return child.generateQuery();
      });
      return queries;
    }
  });
});