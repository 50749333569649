define("@soofa/soofa-shared-client-data/models/power", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attrs
    driverAwake: attr('boolean'),
    driverShouldBeAwake: attr('boolean'),
    extraHours: attr('number'),
    lightOn: attr('boolean'),
    lightingOutputPower: attr('number'),
    operatingDuration: attr('number'),
    sleepStartedAt: attr('number'),
    sensorAwake: attr('boolean'),
    sensorShouldBeAwake: attr('boolean'),
    minimumOperatingDuration: attr('number'),
    snowMode: attr('boolean', {
      defaultValue: false
    }),
    sovPeriodCoefficient: attr('number'),
    // relationships
    location: belongsTo('location')
  });
});