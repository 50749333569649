define("soofa-client-shared/components/checkbox-list/component", ["exports", "soofa-client-shared/components/checkbox-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CheckboxListComponent = Ember.Component.extend({
    // properties
    itemLinkPath: '',
    itemNameAttr: '',
    itemsList: null,
    renderAsTable: false,
    tableItemsSelected: null,
    tableColumns: null,
    // attributes
    classNames: ['checkbox-list'],
    layout: _template.default,
    actions: {
      toggleItemFromSelectedList(item) {
        // init
        let model = this.get('model');

        // get campaign
        let tableItemsSelected = this.get('tableItemsSelected');
        let singleSelect = this.get('singleSelect');
        if (singleSelect) {
          tableItemsSelected = [];
          tableItemsSelected.pushObject(item);
        } else {
          // push item if is not in the tableItemsSelected
          if (!tableItemsSelected.includes(item)) {
            tableItemsSelected.pushObject(item);
          } else {
            // otherwise remove it
            tableItemsSelected.removeObject(item);
          }
        }

        // if custom save is required, call it after updating the model tableItemsSelected array
        if (model) {
          model.save();
        }
        if (this.get('customSave')) {
          this.customSave(tableItemsSelected);
        }
      }
    }
  });
  var _default = _exports.default = CheckboxListComponent;
});