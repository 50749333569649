define("ember-mdi/templates/components/mdi-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UzQtH3Kg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"use\"],[12,\"xlink:href\",[28,[\"/assets/icons.svg#\",[22,0,[\"icon\"]]]],\"http://www.w3.org/1999/xlink\"],[12,\"fill\",[22,0,[\"fill\"]]],[12,\"stroke\",[22,0,[\"stroke\"]]],[12,\"stroke-width\",[22,0,[\"strokeWidth\"]]],[12,\"stroke-linecap\",[22,0,[\"strokeLinecap\"]]],[12,\"stroke-linejoin\",[22,0,[\"strokeLinejoin\"]]],[12,\"stroke-dasharray\",[22,0,[\"strokeDasharray\"]]],[12,\"stroke-dashoffset\",[22,0,[\"strokeDashoffset\"]]],[12,\"stroke-opacity\",[22,0,[\"strokeOpacity\"]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mdi/templates/components/mdi-icon.hbs"
    }
  });
});