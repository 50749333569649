define("soofa-client-shared/mixins/query-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // attributes
    queryType: null,
    eventCollection: null,
    groupBy: null,
    filterName: null,
    filterValue: null,
    filterOperator: null,
    filter2Name: null,
    filter2Value: null,
    filter2Operator: null,
    timeframe: null,
    timezone: null,
    targetProperty: null,
    interval: null,
    generateQuery() {
      return new Keen.Query(this.queryType, this._queryOptions());
    },
    _queryOptions() {
      /* eslint-disable camelcase */
      let {
        timeframe,
        timezone,
        eventCollection,
        interval,
        targetProperty,
        groupBy,
        filterOperator,
        filterName,
        filterValue,
        filter2Operator,
        filter2Name,
        filter2Value,
        steps
      } = this;
      let filters;
      if (filterName) {
        filters = [{
          operator: filterOperator,
          property_name: filterName,
          property_value: filterValue
        }];
      }
      if (filter2Name) {
        filters.push({
          operator: filter2Operator,
          property_name: filter2Name,
          property_value: filter2Value
        });
      }
      let queryOptions = {
        event_collection: eventCollection,
        timeframe,
        timezone,
        interval,
        filters,
        steps,
        target_property: targetProperty,
        group_by: groupBy
      };
      return queryOptions;
    }
  });
});