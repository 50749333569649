define("ember-composable-helpers/helpers/reduce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([callback, initialValue, array]) {
      Ember.set(this, 'callback', callback);
      Ember.set(this, 'array', array);
      Ember.set(this, 'initialValue', initialValue);
      return Ember.get(this, 'content');
    },
    callbackDidChange: Ember.observer('callback', 'initialValue', function () {
      let callback = Ember.get(this, 'callback');
      let initialValue = Ember.get(this, 'initialValue');
      if (Ember.isEmpty(callback)) {
        Ember.defineProperty(this, 'content', []);
        return;
      }
      let cp = Ember.computed('array.[]', () => {
        let array = Ember.get(this, 'array');
        return array.reduce(callback, initialValue);
      });
      Ember.defineProperty(this, 'content', cp);
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
});