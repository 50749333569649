define("soofa-client-shared/mixins/has-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // services
    meService: Ember.inject.service('me'),
    // computed
    meCustomer: Ember.computed.alias('meService.customerModel'),
    me: Ember.computed.alias('meService.model')
  });
});