define("@soofa/soofa-location/components/location-resolver/component", ["exports", "soofa-client-shared/mixins/has-me", "@soofa/soofa-location/components/location-resolver/template"], function (_exports, _hasMe, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // services
    config: Ember.inject.service(),
    keen: Ember.inject.service(),
    // properties
    anonymousUser: true,
    errorButtonOk: null,
    handleError: null,
    handleLocationSuccess: null,
    permissionQuestion: null,
    permissionShareLocationDeny: null,
    permissionShareLocationNo: null,
    permissionShareLocationYes: null,
    proximityError: null,
    proximityExplore: null,
    proximityNo: null,
    proximityQuestion: null,
    proximityYes: null,
    routeAfterError: null,
    step: 1,
    // attributes
    errorMsg: null,
    layout: _template.default,
    // events
    didInsertElement() {
      // if user already has allowed the system to read location
      let me = this.get('me');
      if (me) {
        this.set('anonymousUser', false);
      }
      if (me && me.get('acceptedLocation')) {
        // move to step 3
        this.set('step', 3);
      }
    },
    // save log on keen adding app name
    saveLog(keenEventName, keenEvent) {
      // add application to event
      let {
        config
      } = this.get('config');
      let application = config.modulePrefix;
      keenEvent.application = application;

      // record event
      this.get('keen').recordEvent(keenEventName, keenEvent);
    },
    // actions
    actions: {
      stepChoiceInFrontSign() {
        // save log 
        this.saveLog('nearSoofaSign', {
          answerToTheQuestion: true
        });

        // save log 
        this.saveLog('sawLocationQuestion', {});

        // move to next step
        this.set('step', 2);
      },
      stepChoiceInFrontSignNot(error) {
        // save log 
        this.saveLog('nearSoofaSign', {
          answerToTheQuestion: false
        });

        // set error locally and move to error step
        this.setProperties({
          errorMsg: error,
          step: 4
        });
      },
      stepChoiceShareAccept() {
        // save user choice to not ask again for permissions
        let me = this.get('me');
        if (me) {
          me.set('acceptedLocation', true);
          me.save();
        }

        // save log 
        this.saveLog('answeredLocation', {
          accept: true
        });

        // move to next step
        this.set('step', 3);
      },
      stepChoiceShareDeny(error) {
        // save log 
        this.saveLog('answeredLocation', {
          accept: false
        });

        // set error locally and move to error step
        this.setProperties({
          errorMsg: error,
          step: 4
        });
      },
      locationSuccess(location) {
        // save log 
        this.saveLog('geoLocationResponse', {
          state: 'success',
          stateDescription: location.get('locationName')
        });
        this.handleLocationSuccess(location);
      },
      locationError(error) {
        // save log 
        this.saveLog('geoLocationResponse', {
          state: 'error',
          stateDescription: error
        });

        // set error locally and move to error step
        this.setProperties({
          errorMsg: error,
          step: 4
        });
      }
    }
  });
});