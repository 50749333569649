define("soofa-client-shared/components/password-reset/component", ["exports", "soofa-client-shared/validations/recover", "ember-changeset", "ember-changeset-validations", "soofa-client-shared/components/password-reset/template"], function (_exports, _recover, _emberChangeset, _emberChangesetValidations, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // services
    firebaseApp: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    toaster: Ember.inject.service(),
    session: Ember.inject.service(),
    // attributes
    layout: _template.default,
    changeset: null,
    showErrors: false,
    classNames: ['password-reset'],
    // events
    didReceiveAttrs() {
      // validate form
      let model = new Ember.Object({
        email: null
      });
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_recover.default), _recover.default);
      changeset.validate();
      this.set('changeset', changeset);
    },
    // actions
    actions: {
      async recover() {
        // for toaster
        let icon = '';
        let message = '';
        let cssClass = '';
        let time = '';
        this.set('disableRecover', false);

        // Check if changeset is valid
        let changeset = this.get('changeset');
        if (changeset.get('isValid')) {
          let auth = this.get('firebaseApp').auth();
          let emailAddress = changeset.get('email').toLowerCase();
          await auth.sendPasswordResetEmail(emailAddress).then(() => {
            // Email sent.
            icon = 'email';
            message = 'Please check your inbox for password reset instructions.';
            cssClass = 'blue';
            time = 10000; // 10 seconds
          }).catch(error => {
            // An error happened.
            icon = 'error';
            cssClass = 'red';
            time = 10000;
            if (error.code === 'auth/user-not-found') {
              message = 'We cannot find your email address';
            } else {
              message = error.message;
            }
          });

          // Showing toaster
          this.get('toaster').launchToaster(icon, message, cssClass, 'down left', time);
        } else {
          this.set('disableRecover', true);
        }
      }
    }
  });
});