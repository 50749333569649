define("soofa-client-shared/components/creative-creator/component", ["exports", "soofa-client-shared/components/creative-creator/template", "soofa-client-shared/components/image-editor/component", "soofa-client-shared/utils/object-utils", "moment"], function (_exports, _template, _component, _objectUtils, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Extends image-editor component features to ease the ad creation save
  */

  const {
    Promise
  } = Ember.RSVP;
  var _default = _exports.default = _component.default.extend({
    // services
    cloudinary: Ember.inject.service(),
    store: Ember.inject.service(),
    // properties
    adsSelected: null,
    cloudName: '',
    customer: null,
    uploadPreset: '',
    uploadPresetValue: '',
    // attributes
    ad: null,
    adRenderer: null,
    classNames: ['creative-creator'],
    creatingPreview: false,
    layout: _template.default,
    // actions
    actions: {
      // triggered when the ad is ALREADY created and the layout renderer wants to register itself and start blob/cloudinary upload/image preview generation process
      registerChild(child) {
        this.set('adRenderer', child);
        Ember.run.later(this, this._setAdRendererBlob, 5500);
      },
      // triggered by image-editor component when cloudinary image is saved
      // sets the newly created ID in the model and updates loading state
      async newImage(cloudinaryId) {
        // init
        this.send('newState', false);
        let customer = this.get('customer');
        let layouts = this.get('layouts');

        // create the new ad
        let adAttrs = new Ember.Object({
          adType: 'Image',
          cloudinaryId,
          createdAt: new _moment.default().valueOf(),
          cropMode: 'fill',
          customer,
          brandName: 'Your Business Name',
          headerText: 'Your Headline',
          bodyText: 'Your Body Text',
          footerText: 'Your Footer Text',
          eventName: 'Your Event Name',
          eventLocationName: 'Event Location',
          signature: '@YourHandle',
          layout: layouts.filterBy('layoutType', 'Image').get('firstObject'),
          reviewed: false
        });
        let ad = this.get('store').createRecord('ad', (0, _objectUtils.toPojo)(adAttrs));
        await ad.save();
        this.setProperties({
          ad,
          creatingPreview: true
        });

        // set ad on customer
        let customerAds = await customer.get('ads');
        customerAds.pushObject(ad);
        customer.save();

        // if adsSelected exists, add current ad to that list
        let adsSelected = this.get('adsSelected');
        if (adsSelected) {
          adsSelected.pushObject(ad);
        }
      }
    },
    // helpers
    async _setAdRendererBlob() {
      // init
      let ad = this.get('ad');
      let cloudName = this.get('cloudName');
      let layout = await ad.get('layout');
      let svgAd = this.get('adRenderer');
      let dimension = svgAd.maxDimension || 400;
      let crop = ad.cropMode ? ad.cropMode : 'fill';
      let cloudinaryURL = `https://res.cloudinary.com/${cloudName}/image/upload/dpr_auto,w_${dimension},h_${dimension},c_${crop},e_grayscale`;
      let cloudinaryLogoURL = `https://res.cloudinary.com/${cloudName}/image/upload/dpr_auto,w_${layout.imageLogoWidth},c_${crop},e_grayscale`;
      let adImages = [];
      let adImagePromises = [];

      // getting image url
      let cloudinaryId = ad.get('cloudinaryId');
      let imageURL = `${cloudinaryURL}/${cloudinaryId}.jpg`;

      // downloading image and saving it into local storage
      let downloadedImg = new Image();
      downloadedImg.crossOrigin = 'Anonymous';
      downloadedImg.alt = 'Ad';
      adImages.push({
        type: 'Ad',
        url: imageURL,
        image: downloadedImg
      });

      // getting logo image url
      if (ad.cloudinaryLogoId) {
        let cloudinaryLogoId = ad.get('cloudinaryLogoId');
        let logoImageURL = `${cloudinaryLogoURL}/${cloudinaryLogoId}.jpg`;

        // downloading image and saving it into local storage
        let downloadedLogoImg = new Image();
        downloadedLogoImg.crossOrigin = 'Anonymous';
        downloadedLogoImg.alt = 'Logo';
        adImages.push({
          type: 'Logo',
          url: logoImageURL,
          image: downloadedLogoImg
        });
      }

      // getting facebook image url
      if (ad.socialMediaFacebook) {
        let facebookImageURL = `https://res.cloudinary.com/${cloudName}/image/upload/v1585320129/soofa-talk/icons/icon-facebook.svg`;

        // downloading image and saving it into local storage
        let facebookImg = new Image();
        facebookImg.crossOrigin = 'Anonymous';
        facebookImg.alt = 'Facebook';
        adImages.push({
          type: 'Facebook',
          url: facebookImageURL,
          image: facebookImg
        });
      }

      // getting instagram image url
      if (ad.socialMediaInstagram) {
        let instagramImageURL = `https://res.cloudinary.com/${cloudName}/image/upload/v1585320129/soofa-talk/icons/icon-instagram.svg`;

        // downloading image and saving it into local storage
        let instagramImg = new Image();
        instagramImg.crossOrigin = 'Anonymous';
        instagramImg.alt = 'Instagram';
        adImages.push({
          type: 'Instagram',
          url: instagramImageURL,
          image: instagramImg
        });
      }

      // getting twitter image url
      if (ad.socialMediaTwitter) {
        let twitterImageURL = `https://res.cloudinary.com/${cloudName}/image/upload/v1585320129/soofa-talk/icons/icon-twitter.svg`;

        // downloading image and saving it into local storage
        let twitterImg = new Image();
        twitterImg.crossOrigin = 'Anonymous';
        twitterImg.alt = 'Twitter';
        adImages.push({
          type: 'Twitter',
          url: twitterImageURL,
          image: twitterImg
        });
      }
      for (let i = 0; i < adImages.length; i++) {
        adImagePromises.push(new Promise(resolve => {
          adImages[i].image.setAttribute('src', adImages[i].url);
          adImages[i].image.addEventListener('load', function () {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            canvas.width = adImages[i].image.width;
            canvas.height = adImages[i].image.height;
            context.drawImage(adImages[i].image, 0, 0);
            adImages[i].canvasDataURL = canvas.toDataURL('image/png');
            resolve(adImages[i]);
          });
        }));
      }
      Promise.all(adImagePromises).then(results => {
        // run this only if component is not destroyed
        if (!this.isDestroyed) {
          try {
            let images = svgAd.element.querySelectorAll('svg img');
            if (images.length > 0) {
              images.forEach(imageTag => {
                results.forEach(resultImage => {
                  if (imageTag.alt == resultImage.type) {
                    imageTag.setAttribute('src', resultImage.canvasDataURL);
                  }
                });
              });
              this.export(svgAd, dimension);
            } else {
              this.export(svgAd, dimension);
            }
          } catch (err) {
            Ember.run.later(this, this._setAdRendererBlob, 2000);
          }
        }
      });
    },
    export(svgAd, dimension) {
      svgAd.exportToCanvas(dimension).then(canvas => {
        canvas.toBlob(blob => {
          if (!blob) {
            Ember.run.later(this, this._setAdRendererBlob, 2000);
          } else {
            this._uploadBlobToCloudinary(blob);
          }
        });
      }, false);
    },
    async _uploadBlobToCloudinary(blob) {
      let uploadPresetValue = this.get('uploadPresetValue');
      let fd = new FormData();
      fd.append('file', blob);
      fd.append('upload_preset', uploadPresetValue);
      return this.get('cloudinary').uploadImage(fd).then(response => {
        this._setAdImagePreview(response);
      });
    },
    async _setAdImagePreview(image) {
      let ad = this.get('ad');
      ad.set('preview', image);
      ad.save();

      // reset variables
      this.setProperties({
        ad: null,
        adRenderer: null,
        creatingPreview: false
      });
    }
  });
});