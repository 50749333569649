define("ember-paper/components/paper-select-search", ["exports", "ember-power-select/components/power-select/before-options", "ember-paper/templates/components/paper-select-search"], function (_exports, _beforeOptions, _paperSelectSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _beforeOptions.default.extend({
    layout: _paperSelectSearch.default
  });
});