define("ember-paper/templates/components/paper-optgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iiWAvXuZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-optgroup.hbs"
    }
  });
});