define("soofa-client-shared/services/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    config: null,
    init() {
      this._super(...arguments);
      this.set('config', Ember.getOwner(this).resolveRegistration('config:environment'));
    }
  });
});