define("soofa-client-shared/components/creative-creator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dRXoaCO6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"loadingState\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"spinner\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"ad\"]],[23,[\"creatingPreview\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"preview-box\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"create-msg\"],[9],[1,[27,\"paper-icon\",[\"rotate-right\"],[[\"spin\"],[true]]],false],[0,\" Generating Ad preview\"],[10],[0,\"\\n      \"],[1,[27,\"layout-renderer\",[[23,[\"ad\",\"layout\"]]],[[\"imageSize\",\"ad\",\"registerWithParent\"],[100,[23,[\"ad\"]],[27,\"action\",[[22,0,[]],\"registerChild\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"upload-widget\",null,[[\"name\",\"onStateChange\",\"onSuccess\",\"noSizeLimit\",\"uploadPresetPath\",\"buttonText\",\"iconButton\",\"showAllowedFileTypes\"],[[27,\"get\",[[22,0,[]],\"elementId\"],null],[27,\"action\",[[22,0,[]],\"newState\"],null],[27,\"action\",[[22,0,[]],\"newImage\"],null],[23,[\"noSizeLimit\"]],[23,[\"uploadPreset\"]],\"Upload file\",[23,[\"iconButton\"]],[23,[\"showAllowedFileTypes\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/creative-creator/template.hbs"
    }
  });
});