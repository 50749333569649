define("soofa-client-shared/mixins/handles-inbound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // services
    keen: Ember.inject.service(),
    // params
    queryParams: {
      destination: {
        refreshModel: true
      },
      identifier: {
        refreshModel: true
      },
      referrer: {
        refreshModel: true
      }
    },
    setupController(controller, model) {
      // setting model
      controller.set('model', model);

      // getting query-params from url
      let queryParams = controller.getProperties('destination', 'identifier', 'referrer');
      controller.set('params', queryParams);

      // savign event if needed
      if (queryParams.referrer) {
        // saving referrer event
        let {
          destination,
          identifier,
          referrer
        } = queryParams;
        let referralId = (0 | Math.random() * 9e6).toString(36);
        let data = {
          destination,
          modelId: identifier,
          referralId,
          referralSource: referrer
        };
        this.recordKeenInbound(queryParams.referrer, data);
        this.get('meService').setProperties(data);
      }

      // redirect logic
      if (queryParams.destination && queryParams.identifier) {
        this.transitionTo(queryParams.destination, queryParams.identifier);
      } else if (queryParams.destination) {
        this.transitionTo(queryParams.destination);
      } else {
        this.transitionTo('index');
      }
    },
    recordKeenInbound(referrer, data) {
      if (referrer && referrer.toLowerCase() == 'qrcode') {
        this.get('keen').recordEvent('marketingInbound', data);
      } else {
        this.get('keen').recordEvent('directInbound', data);
      }
    }
  });
});