define("@soofa/soofa-shared-client-data/models/poll", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    circleAccentImage: attr('string'),
    customDenial: attr('string'),
    customResponse: attr('string'),
    customSleepApprove: attr('string'),
    designElementImage: attr('string'),
    endDate: attr('number'),
    header: attr('string'),
    image: attr('string'),
    lastDisplayed: attr('number'),
    question: attr('string'),
    sideImage: attr('string'),
    startDate: attr('number'),
    state: attr('string'),
    // relationships
    city: belongsTo('city'),
    customer: belongsTo('customer'),
    submissions: hasMany('submission'),
    neighborhoods: hasMany('neighborhood')
  });
});