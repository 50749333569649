define("@soofa/soofa-shared-client-data/models/weather", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    forecastDate: attr('number'),
    forecast: attr('json'),
    icon: attr('string'),
    probabilityOfPrecipitation: attr('number'),
    shortForecast: attr('string'),
    // Partly Cloudy
    sunrise: attr('number'),
    sunset: attr('number'),
    tempHigh: attr('number'),
    tempLow: attr('number'),
    windDirection: attr('string'),
    windSpeed: attr('string')
  });
});