define("ember-paper/templates/components/paper-radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "o5yjByjH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"label\",\"radio\"],[[27,\"component\",[[23,[\"labelComponent\"]]],[[\"setAriaLabelledby\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"ariaLabelledby\"]]],null]],null]]]],[27,\"component\",[[23,[\"radioComponent\"]]],[[\"toggle\",\"disabled\",\"groupValue\",\"onChange\",\"parentComponent\",\"shouldRegister\"],[[23,[\"toggle\"]],[23,[\"disabled\"]],[23,[\"groupValue\"]],[27,\"action\",[[22,0,[]],\"onChange\"],null],[22,0,[]],true]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-radio-group.hbs"
    }
  });
});