define("soofa-client-shared/helpers/to-minutes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toMinutes = toMinutes;
  function toMinutes(seconds) {
    let roundedSeconds = Math.round(seconds);
    let minutes = roundedSeconds / 60;
    return Math.round(minutes);
  }
  var _default = _exports.default = Ember.Helper.helper(toMinutes);
});