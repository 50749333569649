define("soofa-client-shared/services/checker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    // services
    store: Ember.inject.service(),
    async checkForModel(modelName, queryKey, queryValue) {
      let store = this.get('store');
      let result = await store.query(modelName, {
        orderBy: queryKey,
        equalTo: queryValue
      });
      if (Ember.isEmpty(result)) {
        return null;
      } else {
        return result.get('firstObject');
      }
    }
  });
});