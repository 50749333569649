define("@soofa/soofa-shared-client-data/models/game", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    likeCount: attr('number', {
      defaultValue: 0
    }),
    instanceStats: attr('json', {
      defaultValue: {}
    }),
    neighborhoodStats: attr('json', {
      defaultValue: {}
    }),
    // associations
    user: belongsTo('user'),
    campaign: belongsTo('campaign')
  });
});