define("soofa-client-shared/components/creative-editor/component", ["exports", "soofa-client-shared/components/creative-editor/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Displays a form to edit the ad model
    Consumed by:
      ads-client/app/components/campaign-editor/template.hbs
      admin-client/app/ads/layout/template.hbs
  */

  const CreativeEditorComponent = Ember.Component.extend({
    // properties
    eager: null,
    // attributes
    classNames: ['creative-editor'],
    eventDate: 0,
    eventTime: 0,
    layout: _template.default,
    // computed
    _layout: Ember.computed.alias('ad.layout'),
    // actions
    actions: {
      // Clears input value
      clearInputCheck(field) {
        let ad = this.get('ad');
        let value = ad.get(field);
        let defaults = ['Your Business Name', 'Your Event Name', 'Event Location', 0, 0, '@YourHandle', 'Your Headline', 'Your Body Text', 'Your Footer Text'];
        if (defaults.includes(value)) {
          ad.set(field, '');
        }
      },
      setDate(type, evt) {
        // init
        let ad = this.get('ad');

        // getting date
        let eventDate = evt.select;

        // set date with timezone
        if (type === 'start') {
          this.set('eventDate', eventDate);
        } else {
          this.set('eventEndDate', eventDate);
        }

        // calculate and set event date
        let eventTime = type === 'start' ? this.get('eventTime') : this.get('eventEndTime');
        let eventTimestamp = eventDate + eventTime;

        // set event time
        if (eventTimestamp > 0) {
          if (type === 'start') {
            ad.set('eventTimeStamp', eventTimestamp);
          } else {
            ad.set('eventEndTimeStamp', eventTimestamp);
          }
        } else {
          if (type === 'start') {
            ad.set('eventTimeStamp', null);
          } else {
            ad.set('eventEndTimeStamp', null);
          }
        }
      },
      setTime(type, evt) {
        // init
        let ad = this.get('ad');
        let eventTime = evt.select * 60 * 1000; // minutes to seconds to miliseconds

        if (type === 'start') {
          this.set('eventTime', eventTime);
        } else {
          this.set('eventEndTime', eventTime);
        }

        // calculate and set event date
        let eventDate = type === 'start' ? this.get('eventDate') : this.get('eventEndDate');
        let eventTimestamp = eventDate + eventTime;

        // set event time
        if (eventTimestamp > 0) {
          if (type === 'start') {
            ad.set('eventTimeStamp', eventTimestamp);
          } else {
            ad.set('eventEndTimeStamp', eventTimestamp);
          }
        } else {
          if (type === 'start') {
            ad.set('eventTimeStamp', null);
          } else {
            ad.set('eventEndTimeStamp', null);
          }
        }
      },
      calcTime() {
        let ad = this.get('ad');
        let date = this.get('eventDate');
        let time = this.get('eventTime');
        if (!time) {
          time = 0;
          this.set('eventTime', '00:00');
        }
        if (!date) {
          date = 0;
          this.set('eventDate', 0);
          ad.set('eventTimeStamp', null);
        } else {
          let dateTimeStr = date.concat('T').concat(time).concat(':00Z');
          let dateTime = new Date(dateTimeStr);
          let offset = dateTime.getTimezoneOffset();
          let hourOffset = offset / 60;
          dateTime.setHours(dateTime.getHours() + hourOffset);
          if (dateTime > 0) {
            ad.set('eventTimeStamp', dateTime);
          } else {
            ad.set('eventTimeStamp', null);
          }
        }
      }
    }
  });
  CreativeEditorComponent.reopenClass({
    positionalParams: ['ad']
  });
  var _default = _exports.default = CreativeEditorComponent;
});