define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KWbyawWt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[23,[\"modalDialogComponentName\"]]],[[\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"legacyTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"stack\",\"value\",\"onClickOverlay\",\"onClose\"],[[23,[\"wrapperClass\"]],[23,[\"wrapperClassNames\"]],[23,[\"overlayClass\"]],[23,[\"overlayClassNames\"]],[23,[\"containerClass\"]],[23,[\"containerClassNames\"]],[23,[\"hasOverlay\"]],[23,[\"translucentOverlay\"]],[23,[\"clickOutsideToClose\"]],[23,[\"destinationElementId\"]],[23,[\"overlayPosition\"]],[23,[\"tetherTarget\"]],[23,[\"target\"]],[23,[\"attachment\"]],[23,[\"targetAttachment\"]],[23,[\"targetModifier\"]],[23,[\"targetOffset\"]],[23,[\"offset\"]],[23,[\"tetherClassPrefix\"]],[23,[\"constraints\"]],[23,[\"attachmentClass\"]],[23,[\"targetAttachmentClass\"]],[23,[\"stack\"]],[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null],[27,\"action\",[[22,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs"
    }
  });
});