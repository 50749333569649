define("ember-leaflet/components/polyline-layer", ["exports", "ember-leaflet/components/array-path-layer"], function (_exports, _arrayPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _arrayPathLayer.default.extend({
    leafletOptions: Object.freeze(['smoothFactor', 'noClip']),
    createLayer() {
      return this.L.polyline(...this.get('requiredOptions'), this.get('options'));
    }
  });
});