define("@soofa/soofa-shared-client-data/models/charge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    amount: attr('number'),
    createdAt: attr('number'),
    cardDescription: attr('string'),
    chargeDescription: attr('string'),
    // associations
    user: belongsTo('user')
  });
});