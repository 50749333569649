define("@sentry/ember/instance-initializers/sentry-performance", ["exports", "@sentry/browser", "@sentry/utils"], function (_exports, _browser, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._instrumentEmberRouter = _instrumentEmberRouter;
  _exports.default = void 0;
  _exports.initialize = initialize;
  _exports.instrumentForPerformance = instrumentForPerformance;
  function getSentryConfig() {
    const _global = _utils.GLOBAL_OBJ;
    _global.__sentryEmberConfig = _global.__sentryEmberConfig ?? {};
    const environmentConfig = {
      "sentry": {
        "dsn": "https://e73c7a3f8ca885358fa3c742511555b2@o83205.ingest.us.sentry.io/4507022939258880",
        "tracesSampleRate": 0.2
      }
    };
    if (!environmentConfig.sentry) {
      environmentConfig.sentry = {
        browserTracingOptions: {}
      };
    }
    Object.assign(environmentConfig.sentry, _global.__sentryEmberConfig);
    return environmentConfig;
  }
  function initialize(appInstance) {
    // Disable in fastboot - we only want to run Sentry client-side
    const fastboot = appInstance.lookup('service:fastboot');
    if (fastboot?.isFastBoot) {
      return;
    }
    const config = getSentryConfig();
    if (config['disablePerformance']) {
      return;
    }
    const performancePromise = instrumentForPerformance(appInstance);
  }
  function getBackburner() {
    if (Ember.run.backburner) {
      return Ember.run.backburner;
    }
    if (Ember.run.backburner) {
      return Ember.run.backburner;
    }
    return {
      on() {
        // noop
      },
      off() {
        // noop
      }
    };
  }
  function getTransitionInformation(transition, router) {
    const fromRoute = transition?.from?.name;
    const toRoute = transition?.to?.name || router.currentRouteName;
    return {
      fromRoute,
      toRoute
    };
  }
  function getLocationURL(location) {
    if (!location || !location.getURL || !location.formatURL) {
      return '';
    }
    const url = location.formatURL(location.getURL());
    if (location.implementation === 'hash') {
      return `${location.rootURL}${url}`;
    }
    return url;
  }
  function _instrumentEmberRouter(routerService, routerMain, config, startBrowserTracingPageLoadSpan, startBrowserTracingNavigationSpan) {
    const {
      disableRunloopPerformance
    } = config;
    const location = routerMain.location;
    let activeRootSpan;
    let transitionSpan;

    // Maintaining backwards compatibility with config.browserTracingOptions, but passing it with Sentry options is preferred.
    const browserTracingOptions = config.browserTracingOptions || config.sentry.browserTracingOptions || {};
    const url = getLocationURL(location);
    const client = (0, _browser.getClient)();
    if (!client) {
      return;
    }
    if (url && browserTracingOptions.startTransactionOnPageLoad !== false && browserTracingOptions.instrumentPageLoad !== false) {
      const routeInfo = routerService.recognize(url);
      activeRootSpan = startBrowserTracingPageLoadSpan(client, {
        name: `route:${routeInfo.name}`,
        origin: 'auto.pageload.ember',
        attributes: {
          [_browser.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route'
        },
        tags: {
          url,
          toRoute: routeInfo.name,
          'routing.instrumentation': '@sentry/ember'
        }
      });
    }
    const finishActiveTransaction = (_, nextInstance) => {
      if (nextInstance) {
        return;
      }
      activeRootSpan?.end();
      getBackburner().off('end', finishActiveTransaction);
    };
    if (browserTracingOptions.startTransactionOnLocationChange === false && browserTracingOptions.instrumentNavigation === false) {
      return;
    }
    routerService.on('routeWillChange', transition => {
      const {
        fromRoute,
        toRoute
      } = getTransitionInformation(transition, routerService);
      activeRootSpan?.end();
      activeRootSpan = startBrowserTracingNavigationSpan(client, {
        name: `route:${toRoute}`,
        origin: 'auto.navigation.ember',
        attributes: {
          [_browser.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route'
        },
        tags: {
          fromRoute,
          toRoute,
          'routing.instrumentation': '@sentry/ember'
        }
      });
      transitionSpan = (0, _browser.startInactiveSpan)({
        attributes: {
          [_browser.SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.ember'
        },
        op: 'ui.ember.transition',
        name: `route:${fromRoute} -> route:${toRoute}`,
        onlyIfParent: true
      });
    });
    routerService.on('routeDidChange', () => {
      if (!transitionSpan || !activeRootSpan) {
        return;
      }
      transitionSpan.end();
      if (disableRunloopPerformance) {
        activeRootSpan.end();
        return;
      }
      getBackburner().on('end', finishActiveTransaction);
    });
  }
  function _instrumentEmberRunloop(config) {
    const {
      disableRunloopPerformance,
      minimumRunloopQueueDuration
    } = config;
    if (disableRunloopPerformance) {
      return;
    }
    let currentQueueStart;
    let currentQueueSpan;
    const instrumentedEmberQueues = ['actions', 'routerTransitions', 'render', 'afterRender', 'destroy'];
    getBackburner().on('begin', (_, previousInstance) => {
      if (previousInstance) {
        return;
      }
      const activeSpan = (0, _browser.getActiveSpan)();
      if (!activeSpan) {
        return;
      }
      if (currentQueueSpan) {
        currentQueueSpan.end();
      }
      currentQueueStart = (0, _utils.timestampInSeconds)();
      const processQueue = queue => {
        // Process this queue using the end of the previous queue.
        if (currentQueueStart) {
          const now = (0, _utils.timestampInSeconds)();
          const minQueueDuration = minimumRunloopQueueDuration ?? 5;
          if ((now - currentQueueStart) * 1000 >= minQueueDuration) {
            (0, _browser.startInactiveSpan)({
              attributes: {
                [_browser.SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.ember'
              },
              name: 'runloop',
              op: `ui.ember.runloop.${queue}`,
              startTimestamp: currentQueueStart,
              onlyIfParent: true
            })?.end(now);
          }
          currentQueueStart = undefined;
        }

        // Setup for next queue

        const stillActiveSpan = (0, _browser.getActiveSpan)();
        if (!stillActiveSpan) {
          return;
        }
        currentQueueStart = (0, _utils.timestampInSeconds)();
      };
      instrumentedEmberQueues.forEach(queue => {
        Ember.run.scheduleOnce(queue, null, processQueue, queue);
      });
    });
    getBackburner().on('end', (_, nextInstance) => {
      if (nextInstance) {
        return;
      }
      if (currentQueueSpan) {
        currentQueueSpan.end();
        currentQueueSpan = undefined;
      }
    });
  }
  function processComponentRenderBefore(payload, beforeEntries) {
    const info = {
      payload,
      now: (0, _utils.timestampInSeconds)()
    };
    beforeEntries[payload.object] = info;
  }
  function processComponentRenderAfter(payload, beforeEntries, op, minComponentDuration) {
    const begin = beforeEntries[payload.object];
    if (!begin) {
      return;
    }
    const now = (0, _utils.timestampInSeconds)();
    const componentRenderDuration = now - begin.now;
    if (componentRenderDuration * 1000 >= minComponentDuration) {
      (0, _browser.startInactiveSpan)({
        name: payload.containerKey || payload.object,
        op,
        origin: 'auto.ui.ember',
        startTimestamp: begin.now,
        onlyIfParent: true
      })?.end(now);
    }
  }
  function _instrumentComponents(config) {
    const {
      disableInstrumentComponents,
      minimumComponentRenderDuration,
      enableComponentDefinitions
    } = config;
    if (disableInstrumentComponents) {
      return;
    }
    const minComponentDuration = minimumComponentRenderDuration ?? 2;
    const beforeEntries = {};
    const beforeComponentDefinitionEntries = {};
    function _subscribeToRenderEvents() {
      Ember.Instrumentation.subscribe('render.component', {
        before(_name, _timestamp, payload) {
          processComponentRenderBefore(payload, beforeEntries);
        },
        after(_name, _timestamp, payload, _beganIndex) {
          processComponentRenderAfter(payload, beforeEntries, 'ui.ember.component.render', minComponentDuration);
        }
      });
      if (enableComponentDefinitions) {
        Ember.Instrumentation.subscribe('render.getComponentDefinition', {
          before(_name, _timestamp, payload) {
            processComponentRenderBefore(payload, beforeComponentDefinitionEntries);
          },
          after(_name, _timestamp, payload, _beganIndex) {
            processComponentRenderAfter(payload, beforeComponentDefinitionEntries, 'ui.ember.component.definition', 0);
          }
        });
      }
    }
    _subscribeToRenderEvents();
  }
  function _instrumentInitialLoad(config) {
    const startName = '@sentry/ember:initial-load-start';
    const endName = '@sentry/ember:initial-load-end';
    const {
      HAS_PERFORMANCE,
      HAS_PERFORMANCE_TIMING
    } = _hasPerformanceSupport();
    if (!HAS_PERFORMANCE) {
      return;
    }
    const {
      performance
    } = window;
    if (config.disableInitialLoadInstrumentation) {
      performance.clearMarks(startName);
      performance.clearMarks(endName);
      return;
    }

    // Split performance check in two so clearMarks still happens even if timeOrigin isn't available.
    if (!HAS_PERFORMANCE_TIMING || _utils.browserPerformanceTimeOrigin === undefined) {
      return;
    }
    const measureName = '@sentry/ember:initial-load';
    const startMarkExists = performance.getEntriesByName(startName).length > 0;
    const endMarkExists = performance.getEntriesByName(endName).length > 0;
    if (!startMarkExists || !endMarkExists) {
      return;
    }
    performance.measure(measureName, startName, endName);
    const measures = performance.getEntriesByName(measureName);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const measure = measures[0];
    const startTimestamp = (measure.startTime + _utils.browserPerformanceTimeOrigin) / 1000;
    const endTimestamp = startTimestamp + measure.duration / 1000;
    (0, _browser.startInactiveSpan)({
      op: 'ui.ember.init',
      name: 'init',
      attributes: {
        [_browser.SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.ember'
      },
      onlyIfParent: true,
      startTimestamp
    })?.end(endTimestamp);
    performance.clearMarks(startName);
    performance.clearMarks(endName);
    performance.clearMeasures(measureName);
  }
  function _hasPerformanceSupport() {
    // TS says that all of these methods are always available, but some of them may not be supported in older browsers
    // So we "pretend" they are all optional in order to be able to check this properly without TS complaining
    const _performance = window.performance;
    const HAS_PERFORMANCE = Boolean(_performance && _performance.clearMarks && _performance.clearMeasures);
    const HAS_PERFORMANCE_TIMING = Boolean(_performance.measure && _performance.getEntriesByName && _utils.browserPerformanceTimeOrigin !== undefined);
    return {
      HAS_PERFORMANCE,
      HAS_PERFORMANCE_TIMING
    };
  }
  async function instrumentForPerformance(appInstance) {
    const config = getSentryConfig();
    // Maintaining backwards compatibility with config.browserTracingOptions, but passing it with Sentry options is preferred.
    const browserTracingOptions = config.browserTracingOptions || config.sentry.browserTracingOptions || {};
    const {
      browserTracingIntegration,
      startBrowserTracingNavigationSpan,
      startBrowserTracingPageLoadSpan
    } = await emberAutoImportDynamic("@sentry/browser");
    const idleTimeout = config.transitionTimeout || 5000;
    const browserTracing = browserTracingIntegration({
      idleTimeout,
      ...browserTracingOptions,
      instrumentNavigation: false,
      instrumentPageLoad: false
    });
    const client = (0, _browser.getClient)();
    const isAlreadyInitialized = false;
    if (client && client.addIntegration) {
      client.addIntegration(browserTracing);
    }

    // We _always_ call this, as it triggers the page load & navigation spans
    _instrumentNavigation(appInstance, config, startBrowserTracingPageLoadSpan, startBrowserTracingNavigationSpan);

    // Skip instrumenting the stuff below again in tests, as these are not reset between tests
    if (isAlreadyInitialized) {
      return;
    }
    _instrumentEmberRunloop(config);
    _instrumentComponents(config);
    _instrumentInitialLoad(config);
  }
  function _instrumentNavigation(appInstance, config, startBrowserTracingPageLoadSpan, startBrowserTracingNavigationSpan) {
    // eslint-disable-next-line ember/no-private-routing-service
    const routerMain = appInstance.lookup('router:main');
    let routerService = appInstance.lookup('service:router');
    if (routerService.externalRouter) {
      // Using ember-engines-router-service in an engine.
      routerService = routerService.externalRouter;
    }
    if (routerService._hasMountedSentryPerformanceRouting) {
      // Routing listens to route changes on the main router, and should not be initialized multiple times per page.
      return;
    }
    if (!routerService.recognize) {
      // Router is missing critical functionality to limit cardinality of the transaction names.
      return;
    }
    routerService._hasMountedSentryPerformanceRouting = true;
    _instrumentEmberRouter(routerService, routerMain, config, startBrowserTracingPageLoadSpan, startBrowserTracingNavigationSpan);
  }
  var _default = _exports.default = {
    initialize
  };
});