define("soofa-client-shared/components/editable-field/component", ["exports", "soofa-client-shared/components/editable-field/template", "ember-changeset", "moment"], function (_exports, _template, _emberChangeset, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Helper component to show an editable/readonly input
    Consumed by:
      ads-client/app/components/user-emails/template.hbs
      admin-client/app/components/block-editor/template.hbs
      admin-client/app/components/city-card/template.hbs
      admin-client/app/components/network-card/template.hbs
      admin-client/app/customers/customer/atlas/template.hbs
      admin-client/app/signs/instance/template.hbs
      admin-client/app/templates/ads-instance-editor.hbs
      admin-client/app/templates/applet-editor.hbs
      admin-client/app/templates/instance-editor.hbs
      admin-client/app/templates/sign-editor.hbs
      admin-client/app/templates/user-editor.hbs
  */

  const EditableFieldComponent = Ember.Component.extend({
    // services
    logCreator: Ember.inject.service(),
    layout: _template.default,
    // properties
    disabled: false,
    passThru: null,
    placeholder: '',
    optionLabelPath: '',
    // attributes
    type: 'text',
    isEditing: false,
    classNameBindings: ['isEditing:isEditing:isNotEditing', 'type'],
    classNames: ['editable-field'],
    save: null,
    showLabel: false,
    unit: null,
    log: false,
    originalValue: null,
    changeset: null,
    forceToLower: false,
    // events
    willInsertElement() {
      // save original value
      let model = this.get('model');
      let type = this.get('type');
      if (type === 'checkbox') {
        this.set('isEditing', true);
      }

      // handle model internally as changeset
      this.set('changeset', new _emberChangeset.default(model, null));
    },
    didReceiveAttrs() {
      // save original value
      let model = this.get('model');
      let property = this.get('property');
      this.set('originalValue', model.get(property));

      // handle model internally as changeset
      this.set('changeset', new _emberChangeset.default(model, null));
    },
    // actions
    actions: {
      save() {
        let save = this.get('save');
        let changeset = this.get('changeset');
        if (save) {
          this.save(changeset);
        } else {
          // force property to lower if needed (for user first and last name mainly)
          let forceToLower = this.get('forceToLower');
          if (forceToLower) {
            let property = this.get('property');
            let newValue = changeset.get(property);
            changeset.set(property, newValue.toLowerCase());
          }

          // save changeset only at the end
          changeset.save();
        }
      },
      startEditing() {
        this.set('isEditing', true);
        Ember.run.next(this, function () {
          Ember.$(this.get('element')).find('input').first().focus();
        });
      },
      cancel() {
        this.get('changeset').rollback();
        this.set('isEditing', false);
      },
      stopEditing() {
        let type = this.get('type');
        if (type === 'text' || type === 'number' || type === 'textarea' || type === 'date' || type === 'select') {
          if (this.get('isEditing')) {
            Ember.run.later(this, this.saveAndCloseText, 500);
          }
        }
      },
      dateSet(e) {
        // handling date set for selected date and clear
        let changeset = this.get('changeset');
        if (e.select) {
          changeset.set(this.get('property'), e.select);
        } else {
          changeset.set(this.get('property'), null);
        }
        if (e.select || e.clear === null) {
          this.send('stopEditing');
        }
      },
      setSwitch() {
        Ember.run.later(this, this.saveAndCloseText, 500);
      }
    },
    // helpers
    click() {
      if (this.get('type') === 'checkbox') {
        Ember.run.next(this, function () {
          this.send('save');
        });
      }
    },
    focusOut() {
      let type = this.get('type');
      if (type !== 'date') {
        // need to handle focus out separatedly for dates due to datepicker focus
        this.send('stopEditing');
      }
    },
    keyDown(ev) {
      if (ev.keyCode === 13) {
        // enter
        this.send('stopEditing');
      }
      if (ev.keyCode === 27) {
        // backspace
        this.send('cancel');
      }
    },
    saveAndCloseText() {
      if (this.save) {
        this.save(this.get('changeset'));
      } else {
        this.send('save');
      }
      let type = this.get('type');
      let log = this.get('log');
      if (log) {
        // log the final saved value from model
        let model = this.get('model');
        let property = this.get('property');
        let originalValue = this.get('originalValue');
        let newValue = model.get(property) ? model.get(property) : 'null';

        // converting epoch to readable date
        if (type === 'date') {
          newValue = model.get(property) ? (0, _moment.default)(newValue).format('MM-DD-YYYY') : 'null';
          originalValue = originalValue ? (0, _moment.default)(originalValue).format('MM-DD-YYYY') : null;
        } else if (type === 'select') {
          // handling selects with inline options and select with object options that uses optionLabelPath
          // to get their values
          let optionLabelPath = this.get('optionLabelPath');
          if (optionLabelPath) {
            newValue = model.get(property) ? model.get(property).get(optionLabelPath) : 'null';
            originalValue = originalValue ? typeof originalValue === 'object' ? originalValue.get(optionLabelPath) : originalValue : 'null';
          } else {
            newValue = model.get(property) ? model.get(property) : 'null';
            originalValue = originalValue ? originalValue : 'null';
          }
        }
        let message = `edited field ${property} to ${newValue}`;
        if (originalValue) {
          message += ` from ${originalValue}`;
        }

        // save new original value
        this.set('originalValue', newValue);

        // only save if new value is different from original one
        if (newValue !== originalValue) {
          this.get('logCreator').createLog(message, [model]);
        }
      }
      if (type === 'text' || type === 'number' || type === 'textarea' || type === 'date' || type === 'select') {
        // avoid set property if element is already destroyed
        if (!this.get('isDestroyed')) {
          this.set('isEditing', false);
        }
      }
    }
  });
  EditableFieldComponent.reopenClass({
    positionalParams: ['type', 'model', 'property']
  });
  var _default = _exports.default = EditableFieldComponent;
});