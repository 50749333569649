define("ember-paper/templates/components/paper-select-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UejEThBp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[23,[\"publicAPI\",\"isOpen\"]],[23,[\"publicAPI\",\"disabled\"]],[23,[\"publicAPI\",\"actions\"]],[27,\"component\",[[23,[\"triggerComponent\"]]],[[\"disabled\",\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[[23,[\"publicAPI\",\"disabled\"]],[27,\"readonly\",[[23,[\"publicAPI\"]]],null],[27,\"readonly\",[[23,[\"hPosition\"]]],null],[27,\"action\",[[22,0,[]],\"handleFocus\"],null],[27,\"readonly\",[[23,[\"renderInPlace\"]]],null],[27,\"readonly\",[[23,[\"vPosition\"]]],null]]]],[27,\"component\",[\"paper-select-content\"],[[\"dropdown\",\"destination\",\"hPosition\",\"renderInPlace\",\"vPosition\",\"top\",\"left\",\"right\",\"width\"],[[27,\"readonly\",[[23,[\"publicAPI\"]]],null],[27,\"readonly\",[[23,[\"destination\"]]],null],[27,\"readonly\",[[23,[\"hPosition\"]]],null],[27,\"readonly\",[[23,[\"renderInPlace\"]]],null],[27,\"readonly\",[[23,[\"vPosition\"]]],null],[27,\"readonly\",[[23,[\"top\"]]],null],[27,\"readonly\",[[23,[\"left\"]]],null],[27,\"readonly\",[[23,[\"right\"]]],null],[27,\"readonly\",[[23,[\"width\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-select-menu.hbs"
    }
  });
});