define("ember-paper/components/paper-select-menu-trigger", ["exports", "ember-basic-dropdown/components/basic-dropdown/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _trigger.default.extend({
    tagName: 'md-select',
    attributeBindings: ['disabledAttr:disabled', 'required'],
    disabledAttr: Ember.computed('disabled', function () {
      return this.get('disabled') ? 'disabled' : null;
    })
  });
});