define("soofa-client-shared/components/campaign-reveal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TNWAf/fZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"screen\",\"isMobile\"]]],null,{\"statements\":[[4,\"soofa-modal\",[[23,[\"show\"]],\"hideModal\"],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"card-reveal\",[27,\"if\",[[23,[\"show\"]],\" revealed\"],null]]]],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/campaign-reveal/template.hbs"
    }
  });
});