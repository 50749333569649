define("soofa-client-shared/components/splash-bg/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SplashBgComponent = Ember.Component.extend({
    // attributes
    attributeBindings: ['style'],
    bg: null,
    // computed
    style: Ember.computed('', function () {
      let {
        bg
      } = this;
      let style = '';
      if (bg) {
        style += `background-image: url(${bg})`;
      }
      return style.htmlSafe();
    })
  });
  SplashBgComponent.reopenClass({
    positionalParams: ['bg']
  });
  var _default = _exports.default = SplashBgComponent;
});