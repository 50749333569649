define("soofa-client-shared/components/login-button/component", ["exports", "soofa-client-shared/mixins/has-me", "soofa-client-shared/components/login-button/template"], function (_exports, _hasMe, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // properties
    buttonClass: 'button-red',
    label: 'Login',
    renderAsText: false,
    renderAsContextBtn: false,
    renderAsButtonForAtlas: false,
    transitionTo: 'index',
    provider: 'google',
    raised: true,
    primary: true,
    // attributes
    layout: _template.default,
    classNames: ['login-button'],
    // services
    authenticator: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    checker: Ember.inject.service(),
    scriptr: Ember.inject.service(),
    // actions
    actions: {
      async signIn(provider) {
        let authenticator = this.get('authenticator');
        let authUser = await authenticator.login({
          provider
        });
        if (authUser) {
          let user = await this.get('checker').checkForModel('user', 'email', authUser.currentUser.email);
          if (!user) {
            let fullName = authUser.currentUser.displayName;
            authUser.currentUser.firstName = '';
            authUser.currentUser.lastName = '';

            // handling possible user first and last name cases
            if (fullName) {
              let fullNameSplit = fullName.toLowerCase().split(' ');
              if (fullNameSplit.length >= 1) {
                authUser.currentUser.firstName = fullNameSplit[0];
              }
              if (fullNameSplit.length === 2) {
                authUser.currentUser.lastName = fullNameSplit[1];
              }
            }
            delete authUser.currentUser.displayName;
            user = await authenticator.createNewUser(authUser.currentUser);
          }
          await authenticator.setupMeService(user);
          if (this.get('transitionTo')) {
            this.get('routing').transitionTo(this.get('transitionTo'), []);
          }
        }
      }
    }
  });
});