define("soofa-client-shared/components/sortable-table/component", ["exports", "soofa-client-shared/components/sortable-table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // properties
    defaultSortProperty: 'id',
    defaultSortOrder: 'asc',
    tableItems: null,
    classNames: ['striped'],
    tagName: 'table',
    // attributes
    layout: _template.default,
    sortOrder: Ember.computed.oneWay('defaultSortOrder'),
    sortProperty: Ember.computed.oneWay('defaultSortProperty'),
    // using the built in sort because ember-decorators is broken
    sortedItems: Ember.computed.sort('tableItems', 'itemSorting'),
    // computed
    itemSorting: Ember.computed('sortProperty', 'sortOrder', function () {
      return [`${Ember.get(this, 'sortProperty')}:${Ember.get(this, 'sortOrder')}`];
    }),
    // actions
    actions: {
      sortByProperty(propertyName) {
        let currentSortProp = Ember.get(this, 'sortProperty');
        if (currentSortProp === propertyName) {
          if (Ember.get(this, 'sortOrder') === 'asc') {
            Ember.set(this, 'sortOrder', 'desc');
          } else {
            Ember.set(this, 'sortOrder', 'asc');
          }
        } else {
          Ember.set(this, 'sortProperty', propertyName);
          Ember.set(this, 'sortOrder', 'asc');
        }
      }
    }
  });
});