define("@soofa/soofa-shared-client-data/models/priority-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    modeType: attr('string'),
    // newsfeed , advertisement
    appletSchedules: hasMany('appletSchedule')
  });
});