define("soofa-client-shared/components/keen-viz/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5U/fIWn4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"query\"],[[27,\"component\",[\"keen-query\"],[[\"parentComponent\"],[[22,0,[]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/keen-viz/template.hbs"
    }
  });
});