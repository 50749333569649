define("ember-leaflet/components/video-layer", ["exports", "ember-leaflet/components/image-layer"], function (_exports, _imageLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _imageLayer.default.extend({
    leafletRequiredOptions: Object.freeze(['video', 'bounds']),
    leafletOptions: Object.freeze(['autoplay', 'loop']),
    leafletProperties: Object.freeze(['url', 'opacity', 'bounds']),
    createLayer() {
      return this.L.videoOverlay(...this.get('requiredOptions'), this.get('options'));
    }
  });
});