define("ember-leaflet/helpers/div-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.divIcon = _exports.default = void 0;
  const isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  const divIcon = _exports.divIcon = isFastBoot ? function () {} : function divIcon(_, hash) {
    // https://github.com/emberjs/ember.js/issues/14668
    let options = Ember.assign({}, hash);
    return L.divIcon(options);
  };
  var _default = _exports.default = Ember.Helper.helper(divIcon);
});