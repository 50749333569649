define("@soofa/soofa-shared-client-data/models/sign", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    activeAdInstance: attr('boolean'),
    appletSchedules: hasMany('appletSchedule'),
    displayCRC: attr('string'),
    height: attr('number', {
      defaultValue: 2880
    }),
    isSleeping: attr('boolean'),
    style: attr('string'),
    styleClass: attr('string'),
    sleepStartTime: attr('number'),
    sleepStartedAt: attr('number'),
    sleepDuration: attr('number'),
    type: attr('string'),
    // types ['Advertisement', 'NewsFeed', 'Emergency']
    width: attr('number', {
      defaultValue: 2160
    }),
    // light
    lightingStartTime: attr('number'),
    lightingDuration: attr('number'),
    lightingStatus: attr('number'),
    lightingOutputPower: attr('number'),
    // associations
    blocks: hasMany('block'),
    // computed
    displayName: Ember.computed('height', 'type', 'width', function () {
      let height = this.get('height');
      let type = this.get('type');
      let width = this.get('width');
      return `${type}_${width}_${height}`;
    })
  });
});