define("soofa-client-shared/components/soofa-campaign-flight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cWU3xPuK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"textOnly\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"moment-format\",[[27,\"await\",[[23,[\"model\",\"lineItem\",\"startsAt\"]]],null],\"M/D\"],[[\"class\"],[\"inline\"]]],false],[0,\" - \"],[1,[27,\"moment-format\",[[27,\"await\",[[23,[\"model\",\"lineItem\",\"endsAt\"]]],null],\"M/D\"],[[\"class\"],[\"inline\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"layout-row layout-wrap\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-20 layout-row layout-align-center-center\"],[9],[0,\"\\n      \"],[1,[27,\"paper-icon\",[\"access_time\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-80\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"Campaign Flight(s)\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n        \"],[1,[27,\"moment-format\",[[27,\"await\",[[23,[\"model\",\"lineItem\",\"startsAt\"]]],null],\"MMMM Do\"],[[\"class\"],[\"inline\"]]],false],[0,\" - \"],[1,[27,\"moment-format\",[[27,\"await\",[[23,[\"model\",\"lineItem\",\"endsAt\"]]],null],\"MMMM Do\"],[[\"class\"],[\"inline\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/soofa-campaign-flight/template.hbs"
    }
  });
});