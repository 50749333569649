define("@soofa/soofa-shared-client-data/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    active: attr('boolean'),
    allowFreeUsers: attr('boolean'),
    alert: attr('string'),
    alertEndDate: attr('number'),
    apiKey: attr('string'),
    atlasActivity: attr('boolean'),
    atlasCharging: attr('boolean'),
    atlasHealth: attr('boolean'),
    calendars: attr('string'),
    campaignStartDate: attr('number'),
    contactName: attr('string'),
    customFacebookName: attr('string'),
    customerType: attr('string'),
    dataPortalUrl: attr('string'),
    dataPortal: attr('boolean', {
      defaultValue: false
    }),
    domain: attr('string'),
    email: attr('string'),
    eventWordLimit: attr('number'),
    eventMaxNumEvents: attr('number'),
    eventMaxDays: attr('number'),
    eventsQrCodeUrl: attr('string'),
    extraInfo: attr('string'),
    facebookDisplayName: attr('string'),
    facebookToken: attr('string'),
    facebookSelectedPage: attr('string'),
    feeds: attr('string'),
    hoursThisMonth: attr('number'),
    hubspotId: attr('string'),
    impressionsThisMonth: attr('number'),
    inspectionMode: attr('boolean'),
    landownerWidgetPeriod: attr('boolean'),
    name: attr('string', {
      defaultValue: 'New Customer'
    }),
    phone: attr('string'),
    posterHeader: attr('string'),
    posterIcon: attr('string'),
    posterIsActive: attr('boolean'),
    posterImage: attr('string'),
    profileImage: attr('string'),
    revShare: attr('number'),
    searchIndex: attr('string'),
    signTemplateOverride: attr('string'),
    shippingAddress: attr('string'),
    specialPermission: attr('string'),
    takeoverEndTime: attr('string'),
    twitterHandles: attr('string'),
    twitterFresh: attr('boolean', {
      defaultValue: false
    }),
    // associations
    ads: hasMany('ad'),
    activeCampaigns: hasMany('campaign', {
      inverse: null
    }),
    campaigns: hasMany('campaign', {
      inverse: 'customer'
    }),
    cities: hasMany('city'),
    contracts: hasMany('contract'),
    enclosures: hasMany('enclosure'),
    eventInstance: belongsTo('instance'),
    facebookInstance: belongsTo('instance'),
    locations: hasMany('location'),
    months: hasMany('month'),
    neighborhoods: hasMany('neighborhood', {
      inverse: null
    }),
    polls: hasMany('poll'),
    referrals: hasMany('referral'),
    users: hasMany('user'),
    voices: hasMany('voice'),
    workOrders: hasMany('workOrder'),
    // computed
    totalActiveCampaigns: Ember.computed('', function () {
      return this.get('activeCampaigns').then(function (campaigns) {
        let totalActiveCampaigns = Ember.RSVP.all(campaigns.toArray());
        return totalActiveCampaigns;
      });
    })
  });
});