define("soofa-client-shared/components/toaster-helper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aOJ/siYw",
    "block": "{\"symbols\":[\"toast\"],\"statements\":[[4,\"if\",[[23,[\"show\"]]],null,{\"statements\":[[4,\"paper-toast\",null,[[\"duration\",\"position\",\"class\",\"escapeToClose\",\"onClose\"],[[23,[\"time\"]],[23,[\"position\"]],[23,[\"color\"]],true,[27,\"action\",[[22,0,[]],\"closeToast\"],null]]],{\"statements\":[[4,\"component\",[[22,1,[\"text\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"paper-icon\",[[23,[\"icon\"]]],[[\"size\"],[20]]],false],[0,\" \"],[1,[21,\"msg\"],false],[0,\" \"],[4,\"paper-button\",null,[[\"class\",\"iconButton\",\"onClick\"],[\"toast-button\",true,[27,\"action\",[[22,0,[]],\"closeToast\"],null]]],{\"statements\":[[1,[27,\"paper-icon\",[\"close\"],[[\"size\"],[15]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/toaster-helper/template.hbs"
    }
  });
});