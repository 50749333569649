define("soofa-client-shared/components/soofa-modal/component", ["exports", "soofa-client-shared/components/soofa-modal/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SoofaModal = Ember.Component.extend({
    // services
    router: Ember.inject.service(),
    // attributes
    layout: _template.default,
    showConfirmModal: false,
    // properties
    containerClass: null,
    // events
    didReceiveAttrs() {
      // ensure body is blurred when showConfirmModal is true
      if (this.get('showConfirmModal')) {
        Ember.$('body > div > header, body > div > main, body > div > div > md-card-content > main').addClass('soofa-modal-active');
      }
    },
    // observers

    // when showConfirmModal attribute changes, it adds a blur effect on entire page
    showConfirmModalChanged: Ember.observer('showConfirmModal', function () {
      if (this.get('showConfirmModal')) {
        Ember.$('body > div > header, body > div > main, body > div > div > md-card-content > main').addClass('soofa-modal-active');
      } else {
        Ember.$('body > div > header, body > div > main, body > div > div > md-card-content > main').removeClass('soofa-modal-active');
      }
    }),
    // events
    willDestroyElement() {
      // hide modal if redirect
      this.set('showConfirmModal', false);
    },
    // actions
    actions: {
      hideModal() {
        this.set('showConfirmModal', false);
      },
      transitionToIndex() {
        this.set('showConfirmModal', false);
        this.get('router').transitionTo('dashboard');
      }
    }
  });
  SoofaModal.reopenClass({
    positionalParams: ['showConfirmModal', 'actionModal', 'containerClass']
  });
  var _default = _exports.default = SoofaModal;
});