define("ember-composable-helpers/helpers/take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([takeAmount, array]) {
      if (!array) {
        array = [];
      }
      Ember.set(this, 'array', array);
      return array.slice(0, takeAmount);
    },
    arrayContentDidChange: Ember.observer('array.[]', function () {
      this.recompute();
    })
  });
});