define("soofa-client-shared/components/event-editor/component", ["exports", "ember-changeset", "soofa-client-shared/components/event-editor/template", "soofa-client-shared/validations/eventChangeset", "ember-changeset-validations", "soofa-client-shared/mixins/has-me"], function (_exports, _emberChangeset, _template, _eventChangeset, _emberChangesetValidations, _hasMe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // properties
    layout: _template.default,
    changeset: null,
    redirectRoute: null,
    // attributes
    classNames: ['event-editor'],
    addressString: null,
    locationError: '',
    // services
    store: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    geohash: Ember.inject.service(),
    keen: Ember.inject.service(),
    // events
    didInsertElement() {
      let model = new Ember.Object({
        name: null,
        dateTime: null,
        locationName: null,
        addressString: null
      });
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_eventChangeset.default), _eventChangeset.default);
      changeset.validate();
      this.set('changeset', changeset);
    },
    // actions
    actions: {
      async save() {
        let changeset = this.get('changeset');
        let user = this.get('me');
        let customer = await user.get('customer');
        if (changeset.get('isValid')) {
          let event = this.get('store').createRecord('event');

          // gets the start date in gmt time
          let eventDate = changeset.get('date');

          // adding timezone offset
          let dateTimezone = new Date(eventDate);
          eventDate += dateTimezone.getTimezoneOffset();

          // calculate the miliseconds from time selection
          let eventTime = changeset.get('time') * 60 * 1000; // minutes to seconds to miliseconds
          let eventDateTime = eventDate + eventTime;

          // get end date in gmt time
          let eventEndDate = changeset.get('endDate');

          // get event name
          let eventName = changeset.get('name');

          // adding timezone offset
          let endDateTimezone = new Date(eventEndDate);
          eventEndDate += endDateTimezone.getTimezoneOffset();

          // calculate the miliseconds from time selection
          let eventEndTime = changeset.get('endTime') * 60 * 1000; // minutes to seconds to miliseconds
          let eventEndDateTime = eventEndDate + eventEndTime;

          // setting event properties and saving
          event.setProperties({
            name: eventName,
            dateTime: eventDateTime,
            endDateTime: eventEndDateTime,
            locationName: changeset.get('locationName'),
            addressString: changeset.get('addressString'),
            geohash: changeset.get('geohash'),
            lat: changeset.get('lat'),
            lng: changeset.get('lng'),
            flag: changeset.get('name').length > 51 || changeset.get('locationName').length > 55 ? true : false
          });

          // save event to a customer or user
          if (customer) {
            event.set('customer', customer);
          } else {
            event.set('user', user);
          }
          await event.save();
          let userId = user.id;
          let customerId = null;
          // set saved event to a customer or user
          if (customer) {
            customer.get('events').pushObject(event);
            customerId = customer.id;
            await customer.save();
          } else {
            user.get('events').pushObject(event);
            await user.save();
          }
          // only create a keen event if the user is not a soofa admin
          if (!user.admin) {
            this.get('keen').recordEvent('eventCreated', {
              event,
              userId,
              customerId
            });
          }
          if (this.get('redirectRoute')) {
            this.get('routing').transitionTo(this.get('redirectRoute'), []);
          } else {
            this.get('routing').transitionTo('events', []);
          }
        }
      },
      placeChanged(address) {
        let changeset = this.get('changeset');

        // getting address, latitude and longitude
        let addressString = `${address.name}, ${address.vicinity}`;
        let locationName = addressString;
        let lat = address.geometry.location.lat();
        let lng = address.geometry.location.lng();

        // getting geohash
        let ghash = this.get('geohash');
        let geohash = ghash.encodeGeoHash(lat, lng);
        if (addressString.length > 50) {
          this.set('locationError', 'Address is more than 50 characters, custom location name required');
          locationName = addressString.substring(0, 50);
        } else {
          this.set('locationError', '');
        }
        changeset.setProperties({
          lat,
          lng,
          addressString,
          locationName,
          geohash
        });
        changeset.validate();
        this.set('locationName', locationName);
      },
      locationNameChanged(locationName) {
        let changeset = this.get('changeset');
        changeset.set('locationName', locationName);
        changeset.validate();
        if (!locationName) {
          this.set('locationError', 'There can not be an event without a location name!');
        } else if (locationName.length > 50) {
          this.set('locationError', 'Location name cannot be more than 50 characters!');
        } else {
          this.set('locationError', '');
        }
      }
    }
  });
});