define("soofa-client-shared/components/go-live-button/component", ["exports", "soofa-client-shared/components/go-live-button/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GoLiveButtonComponent = Ember.Component.extend({
    layout: _template.default,
    // services
    scriptr: Ember.inject.service(),
    keen: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    // properties
    btnClass: '',
    primary: true,
    transitionTo: null,
    // attributes
    tagName: '',
    goLiveLabel: 'Submit for review',
    disableGoLive: false,
    tryAgain: false,
    // actions
    actions: {
      async live() {
        this.set('statusMsg', 'Submitting your campaign...');
        this.set('disableGoLive', true);
        this.set('showConfirmModal', true);

        // on admin this button shouldn't generate a preview
        if (this.uploadBlobToCloudinary) {
          await this.uploadBlobToCloudinary();
        }
        if (this.setAdImagePreview) {
          this.setAdImagePreview();
        }

        // initiate go live process
        this.send('goLive');
      },
      async goLive() {
        let model = this.get('model');
        let transitionTo = this.get('transitionTo');

        // reset variables
        this.set('statusMsg', 'Submitting your campaign...');
        this.set('disableTransition', true);
        this.set('tryAgain', false);
        let ads = await model.get('ads');
        let templateName = ads.get('firstObject').get('adType');
        let layoutId = ads.get('firstObject').get('layout').get('id');
        this.get('keen').recordEvent('templatePosted', {
          templateName,
          layoutId
        });

        // temporarily set state to scheduled until backend sets new state
        model.set('state', 'review');

        // trying to activate campaign
        await this.get('scriptr').post('campaign/activate', {
          data: {
            campaignId: model.get('id')
          }
        }).then(() => {
          // finally transition to final route
          if (transitionTo) {
            this.get('routing').transitionTo(transitionTo, []);
          }
        }).catch(() => {
          // Handle error by showing a try again button
          this.set('statusMsg', 'Whoops! Looks like something went wrong.');
          this.set('tryAgain', true);

          // temporarily set state to scheduled until backend sets new state
          model.set('state', 'draft');
        });
      },
      noAction() {}
    }
  });
  GoLiveButtonComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = _exports.default = GoLiveButtonComponent;
});