define("soofa-client-shared/components/signup-page/component", ["exports", "soofa-client-shared/validations/signup", "ember-changeset", "ember-changeset-validations", "soofa-client-shared/components/signup-page/template"], function (_exports, _signup, _emberChangeset, _emberChangesetValidations, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // services
    authenticator: Ember.inject.service(),
    checker: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    toaster: Ember.inject.service(),
    // properties
    changeset: null,
    showErrors: false,
    showFormSignUp: true,
    tosEnabled: true,
    transitionTo: 'dashboard',
    welcomeMessage: null,
    // attributes
    classNames: ['signup-page'],
    disableCreate: false,
    layout: _template.default,
    // events
    didReceiveAttrs() {
      // validate form
      let model = new Ember.Object({
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        password2: null
      });
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_signup.default), _signup.default);
      changeset.validate();
      this.set('changeset', changeset);
    },
    // computed
    touched: Ember.computed('', function () {
      return Ember.Object.create({
        firstname: false,
        lastname: false,
        email: false,
        password: false,
        password2: false
      });
    }),
    // actions
    actions: {
      async createAccount() {
        this.set('disableCreate', true); // disable button

        // Check if changeset is valid
        let changeset = this.get('changeset');
        let touched = this.get('touched');
        touched.setProperties({
          'firstname': true,
          'lastname': true,
          'email': true,
          'password': true,
          'password2': true
        });
        if (changeset.get('isValid')) {
          let transitionTo;

          // Getting user info
          let firstName = changeset.get('firstname').toLowerCase().trim();
          let lastName = changeset.get('lastname').toLowerCase().trim();
          let email = changeset.get('email').toLowerCase();
          let password = changeset.get('password');
          let user = await this.get('checker').checkForModel('user', 'email', email);
          let referral = await this.get('checker').checkForModel('referral', 'sentTo', email);
          if (user) {
            transitionTo = 'login';
            let message = `${email} address is already in use by another account`;
            this.get('toaster').launchToaster('error', message, 'red', 'down left', false);
          } else {
            let authenticator = this.get('authenticator');

            // if user has a referral, set as enterprise
            let accountType = referral ? 'Enterprise' : 'Free';
            let didAnswerQuestionnaire = Boolean(referral);
            user = await authenticator.createNewUser({
              firstName,
              lastName,
              didAnswerQuestionnaire,
              email,
              accountType,
              password
            });
            await authenticator.login({
              provider: 'password',
              email,
              password
            });
            await authenticator.setupMeService(user);
            transitionTo = this.get('transitionTo');
          }
          this.get('routing').transitionTo(transitionTo, []);
        } else {
          this.set('disableCreate', false); // enable button
        }
      }
    }
  });
});