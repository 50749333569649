define("soofa-client-shared/services/toaster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    name: 'toaster',
    // attributes
    show: false,
    // Indicates whenever toaster should be shown
    msg: 'online',
    icon: 'announcement',
    color: '',
    // red, blue
    position: '',
    // Y X: top left, top right, down left, down right
    time: false,
    // miliseconds or false to disable timeout, then press esc or swipe to close

    // helpers
    launchToaster(icon, msg, cssClass, position = 'down left', time) {
      this.set('icon', icon);
      this.set('msg', msg);
      this.set('color', cssClass);
      this.set('position', position);
      this.set('time', time);
      this.set('show', true);
    }
  });
});