define("soofa-client-shared/components/scriptr-button/component", ["exports", "soofa-client-shared/components/scriptr-button/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // attributes
    layout: _template.default,
    // services
    scriptr: Ember.inject.service(),
    // attrs
    sendString: null,
    sendState: 'idle',
    actions: {
      async buttonPressed() {
        this.set('sendState', 'loading');
        await this.get('scriptr').request(this.get('sendString'));
        if (!this.get('isDestroyed')) {
          this.set('sendState', 'loaded');
        }
      }
    }
  });
});