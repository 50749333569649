define("soofa-client-shared/services/cloudinary", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise
  } = Ember.RSVP;
  var _default = _exports.default = _ajax.default.extend({
    config: Ember.inject.service(),
    host: 'https://api.cloudinary.com/v1_1/',
    cloudName: Ember.computed.alias('config.config.cloudinary.cloudName'),
    // uploadImage: Uploads an image to cloudinary and returns a public id
    // fd: formData
    uploadImage(fd) {
      // Upload image as raw to handle callbacks
      return new Promise((resolve, reject) => {
        this.raw(`${this.get('cloudName')}/image/upload`, {
          method: 'POST',
          data: fd,
          processData: false,
          contentType: false
        }).then(function ({
          response
        }) {
          // Checking response
          if (response) {
            resolve(response.public_id);
          } else {
            reject('Response is not present');
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    uploadImageDetailed(fd) {
      // Upload image as raw to handle callbacks
      return new Promise((resolve, reject) => {
        this.raw(`${this.get('cloudName')}/image/upload`, {
          method: 'POST',
          data: fd,
          processData: false,
          contentType: false
        }).then(function ({
          response
        }) {
          // Checking response
          if (response) {
            resolve(response);
          } else {
            reject('Response is not present');
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // uploadVideo: Uploads a video to cloudinary and returns a public id
    // fd: formData
    uploadVideo(fd) {
      // Upload image as raw to handle callbacks
      return new Promise((resolve, reject) => {
        this.raw(`${this.get('cloudName')}/video/upload`, {
          method: 'POST',
          data: fd,
          processData: false,
          contentType: false
        }).then(function ({
          response
        }) {
          // Checking response
          if (response) {
            resolve(response);
          } else {
            reject('Response is not present');
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  });
});