define("@soofa/soofa-shared-client-data/models/applet-schedule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    dataLength: attr('number'),
    priority: attr('number'),
    reserved: attr('boolean'),
    // associations
    applet: belongsTo('applet'),
    // reservedApplet: belongsTo('applet')
    neighborhood: belongsTo('neighborhood'),
    priorityList: belongsTo('priorityList'),
    schedules: hasMany('schedule')
  });
});