define("soofa-client-shared/components/campaign-dates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oh/nD/fy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"times\"],[9],[0,\"\\n  First Day:\\n  \"],[1,[27,\"child-date\",[[23,[\"changeset\"]],\"beginsAt\"],[[\"childName\",\"placeholder\",\"min\",\"onDateSet\",\"parent\"],[\"startDateComponent\",[27,\"if\",[[23,[\"min\"]],\"\",\"Immediately\"],null],[23,[\"minDate\"]],[27,\"action\",[[22,0,[]],\"onStartTimeDateSet\"],null],[22,0,[]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"times\"],[9],[0,\"\\n  Last Day:\\n  \"],[1,[27,\"child-date\",[[23,[\"changeset\"]],\"expiresAt\"],[[\"childName\",\"placeholder\",\"min\",\"max\",\"onDateSet\",\"clear\",\"parent\"],[\"endDateComponent\",\"Never\",[27,\"now\",null,null],[23,[\"maxDate\"]],[27,\"action\",[[22,0,[]],\"onEndTimeDateSet\"],null],false,[22,0,[]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/campaign-dates/template.hbs"
    }
  });
});