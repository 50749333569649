define("soofa-client-shared/services/me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    model: null,
    customerModel: null,
    // computed
    meCustomer: Ember.computed.alias('customerModel'),
    me: Ember.computed.alias('model')
  });
});