define("@soofa/soofa-location/components/location-proximity/component", ["exports", "soofa-client-shared/mixins/has-me", "@soofa/soofa-location/components/location-proximity/template"], function (_exports, _hasMe, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // properties
    proximityQuestion: 'Are you currently in front of a Soofa Sign?',
    // default text
    proximityYes: 'Yes',
    proximityNo: 'No',
    // attributes
    classNames: ['location-messages'],
    layout: _template.default,
    // actions
    actions: {
      inFrontSign() {
        this.choiceInFrontSign();
      },
      inFrontSignNot() {
        let error = this.get('proximityError');
        this.choiceInFrontSignNot(error);
      }
    }
  });
});