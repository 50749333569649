define("soofa-client-shared/validations/login", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    email: [(0, _validators.validateFormat)({
      type: 'email',
      message: 'Must be a valid email'
    })],
    password: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Password should not be left blank'
    })]
  };
});