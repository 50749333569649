define("@soofa/soofa-shared-client-data/models/campaign", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    createdAt: attr('number', {
      defaultValue: Date.now()
    }),
    featured: attr('boolean', {
      defaultValue: false
    }),
    // possible values: draft, scheduled, review, rejected, active, ended, archived
    state: attr('string', {
      defaultValue: 'draft'
    }),
    days: attr('string'),
    lifetimeCost: attr('number'),
    lifetimeDuration: attr('number'),
    expiresAt: attr('number'),
    beginsAt: attr('number'),
    times: attr('json', {
      defaultValue: []
    }),
    dailyBudget: attr('number'),
    dailyBudgetUsed: attr('number'),
    priority: attr('number'),
    lastRan: attr('number'),
    lastBuzz: attr('number'),
    likeCount: attr('number'),
    hasGame: attr('boolean'),
    free: attr('boolean', {
      defaultValue: false
    }),
    qrRedirectUrl: attr('string'),
    // associations
    ads: hasMany('ad', {
      inverse: null
    }),
    city: belongsTo('city', {
      inverse: null
    }),
    customer: belongsTo('customer'),
    game: belongsTo('game'),
    lineItem: belongsTo('lineItem'),
    neighborhoods: hasMany('neighborhood', {
      inverse: null
    }),
    locations: hasMany('location', {
      inverse: null
    }),
    user: belongsTo('user'),
    // helper
    async asObject() {
      let city = await this.get('city');
      return {
        expiresAt: this.get('expiresAt'),
        beginsAt: this.get('beginsAt'),
        dailyBudget: this.get('dailyBudget'),
        city: city ? city.get('id') : null
      };
    }
  });
});