define("soofa-client-shared/components/md-date/component", ["exports", "soofa-client-shared/components/md-date/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MdDateInput = Ember.Component.extend({
    // attributes
    layout: _template.default,
    selectMonths: true,
    selectYears: 2,
    // properties
    disabled: false,
    min: '',
    max: '',
    // events
    didInsertElement() {
      this._super(...arguments);
      this._setupPicker();
    },
    // helpers
    willDestroyElement() {
      this._super(...arguments);
      this._teardownPicker();
    },
    _setupPicker() {
      let datePickerOptions = this.getProperties('selectMonths', 'selectYears', 'min', 'max', 'clear');
      datePickerOptions.onSet = Ember.run.bind(this, this.onDateSet);
      this.$('.datepicker').pickadate(datePickerOptions);
    },
    _teardownPicker() {
      let $pickadate = this.$('.datepicker').data('pickadate');
      if ($pickadate) {
        $pickadate.stop();
      }
    },
    // override this function to alter date selection behavior
    onDateSet(evt) {
      if (evt.select) {
        let changeset = this.get('changeset');
        changeset.set(this.get('property'), evt.select);
      } else if (evt.clear === null) {
        let changeset = this.get('changeset');
        changeset.set(this.get('property'), null);
      }
    },
    setPickerValues(select, max, min) {
      let $pickadate = this.$('.datepicker').data('pickadate');
      if ($pickadate) {
        $pickadate.set('max', max);
        $pickadate.set('select', select);
        $pickadate.set('min', min);
      }
    },
    setPickerValue(key, value) {
      let $pickadate = this.$('.datepicker').data('pickadate');
      if ($pickadate && key && value) {
        $pickadate.set(key, value);
      }
    }
  });
  MdDateInput.reopenClass({
    positionalParams: ['changeset', 'property']
  });
  var _default = _exports.default = MdDateInput;
});