define("@soofa/soofa-shared-client-data/models/tweet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    tweetAuthor: attr('string'),
    tweetContent: attr('string'),
    tweetHandle: attr('string'),
    tweetId: attr('string'),
    tweetImage: attr('string'),
    tweetImageHeight: attr('number'),
    tweetImageWidth: attr('number'),
    tweetOriginal: attr('string'),
    tweetTime: attr('number'),
    // relationships
    contents: hasMany('tweet')
  });
});