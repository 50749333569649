define("soofa-client-shared/components/sortable-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BGNh9Wyt",
    "block": "{\"symbols\":[\"item\",\"idx\",\"column\",\"&default\"],\"statements\":[[7,\"thead\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"columns\"]]],null,{\"statements\":[[0,\"      \"],[7,\"th\"],[9],[0,\"\\n\"],[4,\"if\",[[22,3,[\"property\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"sortByProperty\",[22,3,[\"property\"]]]],[9],[0,\"\\n            \"],[1,[22,3,[\"label\"]],false],[0,\"\\n\"],[4,\"if\",[[22,3,[\"infoTooltip\"]]],null,{\"statements\":[[4,\"paper-tooltip\",null,[[\"position\"],[[27,\"if\",[[22,3,[\"tooltipPosition\"]],[22,3,[\"tooltipPosition\"]],\"bottom\"],null]]],{\"statements\":[[0,\"                \"],[1,[22,3,[\"infoTooltip\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"sortProperty\"]],[22,3,[\"property\"]]],null]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"sortOrder\"]],\"asc\"],null]],null,{\"statements\":[[0,\"                \"],[1,[27,\"i-mdi\",null,[[\"icon\"],[\"chevron-up\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[27,\"i-mdi\",null,[[\"icon\"],[\"chevron-down\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[22,3,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"sortedItems\"]]],null,{\"statements\":[[0,\"    \"],[14,4,[[22,1,[]],[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/sortable-table/template.hbs"
    }
  });
});