define("soofa-client-shared/components/model-editor/component", ["exports", "soofa-client-shared/utils/object-utils"], function (_exports, _objectUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ModelEditorComponent = Ember.Component.extend({
    // services
    routing: Ember.inject.service('-routing'),
    store: Ember.inject.service(),
    // actions
    actions: {
      save() {
        let changeset = this.get('changeset');
        let draftModelType = this.get('draftModelType');
        if (draftModelType) {
          let model = this._bypassExecuteAndCreateRecord(draftModelType, changeset);
          this.set('model', model);
          return model;
        } else {
          return this.get('changeset').save();
        }
      },
      saveAndTransition(route) {
        this.send('save');
        this.get('routing').transitionTo(route);
      },
      saveAndTransitionWithModel(route) {
        this.send('save');
        let model = this.get('model');
        if (model) {
          this.get('routing').transitionTo(route, [model]);
        } else {
          this.get('routing').transitionTo(route);
        }
      }
    },
    // helpers
    _bypassExecuteAndCreateRecord(draftModelType, changeset) {
      let store = this.get('store');
      let content = changeset.get('_content');
      let changes = changeset.get('_changes');
      content.setProperties(changes);
      let record = store.createRecord(draftModelType, (0, _objectUtils.toPojo)(content));
      record.save();
      return record;
    }
  });
  ModelEditorComponent.reopenClass({
    positionalParams: ['changeset']
  });
  var _default = _exports.default = ModelEditorComponent;
});