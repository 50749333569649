define("soofa-client-shared/components/radio-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6LCQwNwX",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"soofa-md-input\",[\"radio-group\",[23,[\"changeset\"]],[23,[\"property\"]]],[[\"group\",\"groupArray\",\"update\"],[[23,[\"group\"]],[27,\"is-array\",[[23,[\"group\"]]],null],[27,\"if\",[[23,[\"update\"]],[23,[\"update\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[27,\"get\",[[23,[\"changeset\"]],[23,[\"property\"]]],null]],null]],null]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/radio-group/template.hbs"
    }
  });
});