define("soofa-client-shared/validations/signup", ["exports", "ember-changeset-validations/validators", "soofa-client-shared/validations/nowhitespaces"], function (_exports, _validators, _nowhitespaces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    firstname: [(0, _nowhitespaces.default)({
      message: 'First Name should not be left blank'
    })],
    lastname: [(0, _nowhitespaces.default)({
      message: 'Last Name should not be left blank'
    })],
    email: [(0, _validators.validateFormat)({
      type: 'email',
      message: 'Must be a valid email'
    })],
    password: [(0, _validators.validateLength)({
      allowBlank: false,
      min: 8
    })],
    password2: [(0, _validators.validateConfirmation)({
      on: 'password',
      message: 'Passwords do not match. Please re-enter.'
    })]
  };
});