define("soofa-client-shared/services/scriptr", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ajax.default.extend({
    config: Ember.inject.service(),
    contentType: 'application/json; charset=utf-8',
    host: Ember.computed.alias('config.config.scriptr.host'),
    apiKey: Ember.computed.alias('config.config.scriptr.apiKey'),
    namespace: '/api',
    headers: Ember.computed('', function () {
      return {
        Authorization: `bearer ${this.get('apiKey')}`
      };
    })
  });
});