define("ember-libphonenumber-js/helpers/parse-phonenumber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.parsePhoneNumber = parsePhoneNumber;
  function parsePhoneNumber(params /*, hash*/) {
    return params;
  }
  var _default = _exports.default = Ember.Helper.helper(parsePhoneNumber);
});