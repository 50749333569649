define("@soofa/soofa-shared-client-data/models/block", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    applet: attr('string'),
    // maybe should be model
    alignment: attr('string'),
    contentType: attr('string'),
    customSignRenderer: attr('string'),
    dataLength: attr('number'),
    blockType: attr('string'),
    // 'one', 'two', 'three', 'four', 'five'
    height: attr('number'),
    horizontalPosition: attr('number'),
    image: attr('string'),
    number: attr('number'),
    sleepMode: attr('boolean'),
    style: attr('string'),
    styleClass: attr('string'),
    verticalPosition: attr('number'),
    width: attr('number'),
    // relationships
    // positions: hasMany('position'),
    sign: belongsTo('sign'),
    reservedApplet: belongsTo('applet')
  });
});