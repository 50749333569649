define("soofa-client-shared/components/accordion-item/component", ["exports", "soofa-client-shared/components/accordion-item/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    It renders an accordion to show/hide content
    Consumed by:
      ads-client/app/account/template.hbs
  */

  const AccordionItemComponent = Ember.Component.extend({
    // properties
    open: false,
    heading: null,
    innerClass: '',
    classNames: ['accordion-item'],
    // attributes
    layout: _template.default
  });
  var _default = _exports.default = AccordionItemComponent;
});