define("soofa-client-shared/helpers/date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ageInYearsDecimal = ageInYearsDecimal;
  function ageInYearsDecimal(date) {
    let now = new _moment.default();
    let momentDate = new _moment.default(date);
    return now.diff(momentDate, 'years', true).toFixed(1);
  }
});