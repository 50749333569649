define("@soofa/soofa-shared-client-data/models/referral", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    createdAt: attr('number', {
      defaultValue: new Date().valueOf()
    }),
    phoneNumber: attr('string'),
    sentTo: attr('string'),
    success: attr('boolean'),
    source: attr('string'),
    // associations
    createdBy: belongsTo('user', {
      inverse: null
    }),
    receivedBy: belongsTo('user', {
      inverse: null
    }),
    customer: belongsTo('customer'),
    // gratis
    gratis: attr('boolean'),
    campaign: belongsTo('campaign')
  });
});