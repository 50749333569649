define("soofa-client-shared/components/md-modal-btn/component", ["exports", "soofa-client-shared/components/md-modal-btn/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Wrapper for Materialize Modal
    Consumed by:
      admin-client/app/components/device-index/template.hbs
      admin-client/app/customers/customer/users/template.hbs
      admin-client/app/signs/applet/template.hbs
      admin-client/app/signs/instance/template.hbs
  */
  var _default = _exports.default = Ember.Component.extend({
    // attributes
    buttonClass: null,
    icon: null,
    iconClass: '',
    iconButton: false,
    large: false,
    modal: false,
    raised: true,
    mini: false,
    primary: true,
    closeOnly: false,
    textOnly: false,
    displayAsChip: false,
    disableSave: false,
    layout: _template.default,
    classNames: ['md-modal-btn'],
    // when modal attribute changes, it adds a blur effect on entire page
    modalChanged: Ember.observer('modal', function () {
      if (this.get('modal')) {
        Ember.$('body > div > header, body > div > main').addClass('soofa-modal-active');
      } else {
        Ember.$('body > div > header, body > div > main').removeClass('soofa-modal-active');
      }
    }),
    modalClose() {
      this.toggleProperty('modal');
    },
    // actions
    actions: {
      confirmAction() {
        let closeAction = this.get('closeAction');
        if (closeAction) {
          closeAction(this);
        } else {
          this.toggleProperty('modal');
          this.get('action')();
        }
      }
    }
  });
});