define("soofa-client-shared/components/password-reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Dh6/tG+H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[7,\"h5\"],[9],[0,\"Forgot your password?\"],[10],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"forgot-details\"],[9],[0,\"Enter your email address to reset your password. You may need to check your spam folder or unblock \"],[7,\"a\"],[11,\"href\",\"mailto:info@soofa.co\"],[9],[0,\"info@soofa.co\"],[10],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"input-container\"],[9],[0,\"\\n    \"],[1,[27,\"soofa-md-input\",[\"text\",[23,[\"changeset\"]],\"email\"],[[\"label\"],[\"Email\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n    \"],[1,[27,\"paper-button\",null,[[\"label\",\"primary\",\"raised\",\"onClick\"],[\"Submit\",true,true,[27,\"action\",[[22,0,[]],\"recover\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/password-reset/template.hbs"
    }
  });
});