define("@soofa/soofa-shared-client-data/models/work-order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    createdAt: attr('number'),
    issuedAt: attr('number'),
    serviceType: attr('string'),
    issueDescription: attr('string'),
    actionDescription: attr('string'),
    materials: attr('string'),
    trackingNumbers: attr('string'),
    closedAt: attr('number'),
    // associations
    location: belongsTo('location'),
    device: belongsTo('workOrder'),
    customer: belongsTo('customer')
  });
});