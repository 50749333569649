define("soofa-client-shared/components/layout-renderer/component", ["exports", "soofa-client-shared/components/layout-renderer/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise
  } = Ember.RSVP;
  const LayoutRendererComponent = Ember.Component.extend({
    // attributes
    layout: _template.default,
    tagName: 'svg',
    attributeBindings: ['viewBox', 'xmlns', 'xlink:xmlns:xlink', 'style', 'width', 'height'],
    classNameBindings: ['selected'],
    viewBox: '0 0 1000 1000',
    xmlns: 'http://www.w3.org/2000/svg',
    xlink: 'http://www.w3.org/1999/xlink',
    // style: 'border: 5px solid #000;background-color: black;',
    // properties
    ad: null,
    _layout: null,
    timeZone: null,
    // services
    config: Ember.inject.service(),
    buzzClientBase: Ember.computed.alias('config.config.buzzClientUrl'),
    // events
    // Here we give access to this component to its parent, so it can call
    // exportToCanvas once it is registed to generate a preview image of the ad
    didInsertElement() {
      let registerWithParent = this.get('registerWithParent');
      if (registerWithParent) {
        registerWithParent(this);
      }
    },
    // helpers
    // Used to render a complete ad by the browser into canvas so it can be saved
    // as a preview image and used externally from Soofa applications (ex. emails)
    exportToCanvas(dimension) {
      return new Promise(resolve => {
        let {
          element: element
        } = this;
        let svg = element;
        let xml = new XMLSerializer().serializeToString(svg);
        let svg64 = btoa(unescape(encodeURIComponent(xml)));
        let b64Start = 'data:image/svg+xml;base64,';
        let image64 = b64Start + svg64;
        let img = new Image();
        img.crossOrigin = 'Anonymous';
        img.addEventListener('load', () => {
          let canvas = document.createElement('canvas');
          if (dimension) {
            canvas.height = canvas.width = dimension;
          }
          canvas.getContext('2d').drawImage(img, 0, 0);
          resolve(canvas);
        });
        img.src = image64;
      });
    }
  });
  LayoutRendererComponent.reopenClass({
    positionalParams: ['_layout', 'maxDimension', 'imageSize']
  });
  var _default = _exports.default = LayoutRendererComponent;
});