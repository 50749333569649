define("soofa-client-shared/components/campaign-dates/component", ["exports", "soofa-client-shared/mixins/has-me", "moment", "soofa-client-shared/components/campaign-dates/template"], function (_exports, _hasMe, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // attributes
    endDateComponent: null,
    classNames: ['campaign-dates'],
    campaignTimeLimitInDays: null,
    layout: _template.default,
    // properties
    changeset: null,
    lineItem: null,
    // computed
    minDate: Ember.computed('', function () {
      let min = this.get('min');
      if (min) {
        return new _moment.default(min).startOf('day').toDate();
      } else {
        let today = new _moment.default().startOf('day');
        return today.toDate();
      }
    }),
    maxDate: Ember.computed('', function () {
      let me = this.get('me');
      let max = this.get('max');
      if (me.get('accountType') === 'Free') {
        return this._getDateStartingFromToday(new Date(), 14).toDate();
      } else {
        return new _moment.default(max).toDate();
      }
    }),
    // events
    async didInsertElement() {
      // init
      this._super();
      let changeset = this.get('changeset');

      // get start time
      let min = this.get('min');
      let max = this.get('max');
      let beginsAt = min ? min : changeset.get('beginsAt');

      // Set start date on the UI component if beginsAt exists
      let startDateComponent = this.get('startDateComponent');
      if (max) {
        // set selected, max, min values for startDateComponent
        startDateComponent.setPickerValues(beginsAt, new _moment.default(max).toArray(), new _moment.default(min).toArray());
      }

      // if start time exists, call it with start date
      if (beginsAt) {
        startDateComponent.setPickerValue('select', beginsAt);
        this.send('onStartTimeDateSet', {
          select: beginsAt
        });
      } else {
        // otherwise, call it with null date to correctly set the end date
        this.send('onStartTimeDateSet', {
          select: null
        });
      }
    },
    // actions
    actions: {
      // given a start date, set end date to one week in the future and set max selected date
      onStartTimeDateSet(evt) {
        // init
        let date = evt.select || null;
        let changeset = this.get('changeset');
        changeset.set('beginsAt', date);
        date = date || new Date();
        let endDateComponent = this.get('endDateComponent');

        // setting max end date depending on account type and property sent
        let max = this.get('max');
        let campaignTimeLimitInDays = this.get('campaignTimeLimitInDays');
        let maxDate = max ? new _moment.default(max) : this._getDateStartingFromToday(date, campaignTimeLimitInDays);

        // set selected, max, min values for endDateComponent
        endDateComponent.setPickerValues(maxDate.toArray(), maxDate.toArray(), new _moment.default(date).toArray());

        // update expiresAt on changeset
        changeset.set('expiresAt', maxDate.endOf('day').valueOf());
      },
      // given a start date, set end date to one week in the future and set max selected date
      onEndTimeDateSet(evt) {
        // init
        let date = evt.select || null;
        date = date || new Date();
        let changeset = this.get('changeset');

        // update expiresAt on changeset
        changeset.set('expiresAt', new _moment.default(date).endOf('day').valueOf());
      }
    },
    // helper
    _getDateStartingFromToday(timestamp, days = 7) {
      return new _moment.default(timestamp).add(days, 'days');
    }
  });
});