define("soofa-client-shared/components/soofa-campaign-flight/component", ["exports", "soofa-client-shared/components/soofa-campaign-flight/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Displays campaign flight times
  */

  const SoofaCampaignTimesComponent = Ember.Component.extend({
    // properties
    textOnly: false,
    // attributes
    layout: _template.default,
    classNames: ['soofa-campaign-flight']
  });
  SoofaCampaignTimesComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = _exports.default = SoofaCampaignTimesComponent;
});