define("soofa-client-shared/components/campaign-owner/component", ["exports", "soofa-client-shared/components/campaign-owner/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Displays campaign owner
    Consumed by:
      soofa-client-shared/addon/components/soofa-campaign-card/template.hbs
      admin-client/app/components/ad-review-card/template.hbs
  */

  const CampaignOwnerComponent = Ember.Component.extend({
    // attributes
    layout: _template.default,
    showUserName: false,
    classNames: ['campaign-owner'],
    // services
    routing: Ember.inject.service('-routing'),
    // actions
    actions: {
      showUserProfile(model) {
        this.get('routing').transitionTo('users.user', [model]);
      }
    }
  });
  CampaignOwnerComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = _exports.default = CampaignOwnerComponent;
});