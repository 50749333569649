define("@soofa/soofa-shared-client-data/models/sim", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    // attributes
    apId: attr('string'),
    searchIndex: attr('string'),
    phoneNumber: attr('string'),
    simCarrier: attr('string'),
    status: attr('string'),
    lastSyncPlan: attr('string'),
    lastSyncStatus: attr('string'),
    lastSyncTime: attr('number'),
    lastSyncUsage: attr('string'),
    locationStatus: attr('string', {
      defaultValue: 'Stock'
    }),
    planSize: attr('string'),
    // associations
    device: belongsTo('device')
  });
});