define("soofa-client-shared/components/me-service/component", ["exports", "soofa-client-shared/mixins/has-me", "soofa-client-shared/components/me-service/template"], function (_exports, _hasMe, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // attributes
    layout: _template.default
  });
});