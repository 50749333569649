define("soofa-client-shared/components/layout-thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3Q1/s/1y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"child-scaler\",null,[[\"childModel\"],[[23,[\"model\",\"format\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"layout-renderer\",[[23,[\"model\"]]],[[\"ad\"],[[23,[\"ad\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/layout-thumbnail/template.hbs"
    }
  });
});