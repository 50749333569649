define("soofa-client-shared/validations/nowhitespaces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNoWhiteSpaces;
  function validateNoWhiteSpaces(opts) {
    return (key, newValue) => {
      if (newValue === null) {
        return opts.message;
      } else {
        if (newValue.trim() === '') {
          return opts.message;
        } else {
          return true;
        }
      }
    };
  }
});