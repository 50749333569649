define("@soofa/soofa-location/components/location-acquire/component", ["exports", "soofa-client-shared/mixins/has-me", "@soofa/soofa-location/components/location-acquire/template"], function (_exports, _hasMe, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_hasMe.default, {
    // services
    geolocation: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    scriptr: Ember.inject.service(),
    store: Ember.inject.service(),
    // attributes
    locationAcquired: false,
    classNames: ['location-messages'],
    layout: _template.default,
    // properties
    proximityError: 'You need to be at a Soofa Sign to continue.',
    // default text
    routeError: '',
    routeSuccess: '',
    // events
    didInsertElement() {
      let geolocation = this.get('geolocation');
      geolocation.setupLocation();
      let geoPromise = geolocation.get('promise');
      geoPromise.then(() => {
        // transition if no location is gathered: dont show this message if user is free and campaign was already buzzed
        Ember.run.later(this, () => {
          if (!this.get('locationAcquired') && (!this.isDestroyed || !this.isDestroying)) {
            this.locationError('We are having trouble getting an accurate reading of your location. <strong>Please try again later.</strong>');
          }
        }, 20000);
      }).catch(e => {
        this.locationError(e);
      });
    },
    accuracy: Ember.computed('geolocation.accuracy', function () {
      // make sure to halt transition for accuracy range and user free + model already buzzed
      let accuracy = this.get('geolocation.accuracy');
      if (accuracy > 0 && accuracy <= 150) {
        this.set('locationAcquired', true); // eslint-disable-line ember/no-side-effects
        Ember.run.later(this, () => {
          this.send('getClosestLocation');
        });
      }
      return accuracy;
    }),
    actions: {
      // getClosestLocation: get location based on lat/lng
      async getClosestLocation() {
        // init
        let geolocation = this.get('geolocation');
        let store = this.get('store');
        let scriptr = this.get('scriptr');
        let proximityError = this.get('proximityError');

        // get lat and lng from location service
        let lat = geolocation.get('lat');
        let lng = geolocation.get('lng');
        let closestLocationRequest = await scriptr.request(`closestSign?lat=${lat}&lng=${lng}`);
        let closestLocationId = closestLocationRequest.response.result;
        if (closestLocationId) {
          // await for location
          let location = await store.findRecord('location', closestLocationId);

          // return location to handler
          this.locationSuccess(location);
        } else {
          // return error if location was not found
          this.locationError(proximityError);
        }
      },
      // zoomUser
      zoomUser(lng, lat, map) {
        Ember.run.later(() => {
          map.instance.flyTo({
            center: [lng, lat],
            zoom: 12,
            speed: 4
          });
        }, 200);
      },
      // mapLoaded: triggered when map is loaded
      mapLoaded(model, map) {
        // getting user location
        let {
          lat,
          lng
        } = model;

        // creating a pulsing dot using canvas
        let size = 200;
        let pulsingDot = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),
          // eslint-disable-line no-undef

          onAdd() {
            let canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
          },
          render() {
            let duration = 1000;
            let t = performance.now() % duration / duration;
            let radius = size / 2 * 0.1;
            let outerRadius = size / 2 * 1 * t + radius;
            let {
              context
            } = this;

            // draw outer circle
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
            context.fillStyle = `rgba(150, 190, 250,${1 - t})`;
            context.fill();

            // draw inner circle
            context.beginPath();
            context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
            context.fillStyle = 'rgba(60, 100, 250, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            // update this image's data with data from the canvas
            this.data = context.getImageData(0, 0, this.width, this.height).data;

            // keep the map repainting
            map.triggerRepaint();

            // return `true` to let the map know that the image was updated
            return true;
          }
        };

        // adding dot to map
        map.addImage('pulsing-dot', pulsingDot, {
          pixelRatio: 2
        });
        map.addLayer({
          'id': 'points',
          'type': 'symbol',
          'source': {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [{
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates': [lng, lat]
                }
              }]
            }
          },
          'layout': {
            'icon-image': 'pulsing-dot'
          }
        });
      }
    }
  });
});