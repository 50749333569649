define("soofa-client-shared/validations/campaign", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    ads: [(0, _validators.validatePresence)(true)],
    // TODO: Put neighborhood and location validations back in again
    // neighborhoods: [
    //   validatePresence(true)
    // ],

    times: [(0, _validators.validatePresence)(true)]
  };
});