define("soofa-client-shared/components/go-live-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y943GJkd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-button\",null,[[\"class\",\"label\",\"primary\",\"raised\",\"onClick\",\"disabled\"],[[23,[\"btnClass\"]],[23,[\"goLiveLabel\"]],[23,[\"primary\"]],true,[27,\"action\",[[22,0,[]],\"live\"],null],[23,[\"disableGoLive\"]]]],{\"statements\":[[0,\"  \"],[1,[21,\"goLiveLabel\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"disableGoLive\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"paper-progress-circular\",null,[[\"diameter\",\"class\"],[15,\"progress-red\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"soofa-modal\",[[23,[\"showConfirmModal\"]],[27,\"action\",[[22,0,[]],\"noAction\"],null],\"modal-medium\"],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"h6\"],[9],[1,[21,\"statusMsg\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"center-align\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"tryAgain\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"paper-button\",null,[[\"label\",\"raised\",\"primary\",\"onClick\"],[\"Try again\",true,true,[27,\"action\",[[22,0,[]],\"goLive\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "soofa-client-shared/components/go-live-button/template.hbs"
    }
  });
});