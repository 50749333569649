define("soofa-client-shared/components/keen-query/component", ["exports", "soofa-client-shared/mixins/query-options", "ember-composability-tools"], function (_exports, _queryOptions, _emberComposabilityTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Creates a Keen Query to be consumed by a keen-viz component
    Consumed by:
      soofa-client-shared/addon/components/keen-viz/template.hbs
      atlas-client/app/components/keen-viz-day/template.hbs
      atlas-client/app/components/keen-viz-manyline/template.hbs
      atlas-client/app/components/keen-viz-metric/template.hbs
      atlas-client/app/components/keen-viz-profile/template.hbs
      atlas-client/app/report/template.hbs
      admin-client/app/devices/device/data/template.hbs
      admin-client/app/pipes/data/template.hbs
      admin-client/app/pipes/index/template.hbs
  */
  var _default = _exports.default = Ember.Component.extend(_emberComposabilityTools.ChildMixin, _queryOptions.default);
});