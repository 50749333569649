define("@soofa/soofa-location/components/location-proximity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XVLkOc2m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-center box-white\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-100\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[0,\"\\n      \"],[1,[21,\"proximityQuestion\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-100\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"primary\",\"raised\",\"onClick\"],[\"button-blue\",false,true,[27,\"action\",[[22,0,[]],\"inFrontSign\"],null]]],{\"statements\":[[0,\"        \"],[1,[21,\"proximityYes\"],false],[0,\"\\n      \"]],\"parameters\":[]},null],[0,\" \\n\"],[4,\"paper-button\",null,[[\"primary\",\"raised\",\"onClick\"],[false,true,[27,\"action\",[[22,0,[]],\"inFrontSignNot\"],null]]],{\"statements\":[[0,\"        \"],[1,[21,\"proximityNo\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@soofa/soofa-location/components/location-proximity/template.hbs"
    }
  });
});