define("soofa-client-shared/helpers/index-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.indexOf = indexOf;
  function indexOf(params) {
    return params[0].indexOf(params[1]);
  }
  var _default = _exports.default = Ember.Helper.helper(indexOf);
});