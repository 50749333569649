define("soofa-client-shared/components/layout-thumbnail/component", ["exports", "soofa-client-shared/components/layout-thumbnail/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Renders a layout-renderer independent of an Ad model, which
    requires its format to get size information
    Consumed by:
      ads-client/app/components/campaign-editor/template.hbs
      admin-client/app/ads/format/template.hbs
  */

  const LayoutThumbnailComponent = Ember.Component.extend({
    // attributes
    layout: _template.default,
    classNameBindings: ['selected'],
    classNames: ['layout-thumbnail']
  });
  LayoutThumbnailComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = _exports.default = LayoutThumbnailComponent;
});